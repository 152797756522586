import { configureStore, combineReducers } from "@reduxjs/toolkit";
import personalInformationsReducers from "../shared/slice/reducers/personalInformations.reducers";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
import identificationWellReducers from "../shared/slice/reducers/identificationWell.reducers";
import descriptionGoodReducers from "../shared/slice/reducers/descriptionGood.reducers";
import requestTypesReducers from "../shared/slice/reducers/requestTypes.reducers";
import documentsReducers from "../shared/slice/reducers/documents.reducers";
import stepperReducers from "../shared/slice/reducers/stepper.reducers";

const appReducer = combineReducers({
  personal: personalInformationsReducers,
  tipo_solicitud: requestTypesReducers,
  identificacion_bien: identificationWellReducers,
  desciption_bien:descriptionGoodReducers,
  documents:documentsReducers,
  steps: stepperReducers
});

const persistConfig = {
    key: "main-root",
    storage,
    stateReconciler: autoMergeLevel2,
  };
  const persistedReducer = persistReducer(
    persistConfig,
    appReducer
  );

  const rootReducer = (state, action) => {
    if (action.type === "persist/PURGE") {
      state = undefined;
    }
    return persistedReducer(state, action);
  };

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
