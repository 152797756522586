
export const tipoSolicitudValidaciones = (name, value, errors, setErrors) => {
    switch (name) {
        case "firstName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "firstLastName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "businessName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "requestType":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;

        case "typeBien":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "typeCalidad":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "typePerson":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "actingType":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "address":
            if (value?.tipo_via !== '' && value?.numero_via !== '' && value?.via_generadora !== '' && value.numero_dir !== '') {
                setErrors({...errors, [name]: ""}); 
            } else {
                setErrors({...errors, [name] : "Este campo es requerido."})
            }
            break;
        case "conocePropietario":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "unicoPropietario":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        default:
            break;
    }
}