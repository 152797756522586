// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title-style {
    line-height: 1.75em !important;
}

.home-styles {
    text-align: start;
}

.button-style {
    cursor: pointer;
}

.box-text {
    width: 100%;
}

.form-row {
    justify-content: center;
}
.wiC{
    width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB","sourcesContent":["\n.title-style {\n    line-height: 1.75em !important;\n}\n\n.home-styles {\n    text-align: start;\n}\n\n.button-style {\n    cursor: pointer;\n}\n\n.box-text {\n    width: 100%;\n}\n\n.form-row {\n    justify-content: center;\n}\n.wiC{\n    width: 20rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
