import { useState, useRef } from "react";
import constructAlert from "../../utils/sweetalert/constructAlert";

export default function FileField({
  uploadedFiles,
  setUploadedFiles,
  multiple = false,
  acceptString,
  setEnbaleSaveButton
}) {
  const fileInputRef = useRef(null);

  const size = 5000000;

  const handleDrop = (e) => {
    e.preventDefault();
    const fileSize = e.dataTransfer.files[0].size;
    const typeFile = e.dataTransfer.files[0].type;
    console.log(fileSize <= size)
    if (fileSize <= size && typeFile === "application/pdf") {
      const files = [...e.dataTransfer.files];
      handleFiles(files);
      setEnbaleSaveButton(true);
    } else {
      constructAlert(
        "",
        `${fileSize > size ? "¡El archivo debe pesar menos de 5MB.!" : "!El archivo debe ser tipo PDF¡"}`,
        "error",
        "Aceptar"
      );
    }
  };

  const handleFiles = (files) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.push(...files);
    setUploadedFiles(newUploadedFiles);
  };

  const handleRemove = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
  };

  const handleFileInputChange = (e) => {
    const files = [...e.target.files];
    const fileSize = files[0].size;
    if (fileSize <= size) {
      handleFiles(files);
      e.target.value = null;
      setEnbaleSaveButton(true);
    } else {
      constructAlert(
        "",
        "¡El archivo debe pesar menos de 5MB.!",
        "error",
        "Aceptar"
      );
    }
  };

  return (
    <div className="form-group gov-co-form-group">
      {uploadedFiles.length > 0 ? (
        <div
          style={{
            border: "2px dashed #004884",
            padding: "20px",
            textAlign: "center",
            marginTop: "14px",
            height: "112px",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          {uploadedFiles.map((file, index) => (
            <div key={index}>
              {file.name} -{" "}
              <button onClick={() => handleRemove(index)}>Eliminar</button>
            </div>
          ))}
        </div>
      ) : (
        <div
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e)}
        >
          <input
            type="file"
            style={{ height: "112px" }}
            ref={fileInputRef}
            className="custom-file-input input-file-govco"
            onChange={handleFileInputChange}
            multiple={multiple}
            accept={acceptString}
          ></input>
          <label className="custom-file-label label-file-govco">
            <span className="govco-icon govco-icon-attached-n color-attach"></span>
            Arrastre aquí su(s) archivo(s) o haga click para añadir.
          </label>
        </div>
      )}
    </div>
  );
}
