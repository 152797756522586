import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  onInitCreate: false,
  onInitConsult: false,
  finish:true,
  showUserSurvey: false
};

export const stepperSlice = createSlice({
  name: "tramite",
  initialState,
  reducers: {
    setStep: (state, actions) => {
      state.step = actions.payload;
    },
    getonInitCreate: (state, actions) => {
      state.onInitCreate = actions.payload;
    },
    getonInitConsult: (state, actions) => {
      state.onInitConsult = actions.payload;
    },
    changeView : (state,actions) =>{
      state.finish = actions.payload;
    },
    setShowUserSurvey: (state, actions) => {
      state.showUserSurvey = actions.payload;
    }
  },
});

export const { setStep, getonInitCreate, getonInitConsult,changeView, setShowUserSurvey } = stepperSlice.actions;

export default stepperSlice.reducer;
