import axiosBase from "../../utils/axios/axios";

export const getRequestType = async () => {
  try {
    const response = await axiosBase.get("Tipos_solicitud/tipo_solicitud/");
    return response.data.data;
  } catch (error) {
    
    throw error;
  }
};
export const getTypeGood = async () => {
    try {
        const response = await axiosBase.get("Tipos_solicitud/tipo_bien/");
        return response.data.data;
    } catch (error) {
        throw error 
    }
}

export const getCurrentQuality = async () =>{
    try {
        const response = await axiosBase.get("Tipos_solicitud/calidad_actual/")
        return response.data.data;
    } catch (error) {
        throw error
    }
}
export const getTypePeople = async () =>{
    try {
        const response = await axiosBase.get("Tipos_solicitud/tipo_persona_propietario/")
        return response.data.data;
    } catch (error) {
        throw error
    }
}
export const getActingInterest  = async () =>{
    try {
        const response = await axiosBase.get("Tipos_solicitud/interes/");
        return response.data.data;
    } catch (error) {
        throw error
    }
}

export const postRequestType = async (data) => {
    try {
        const response = await axiosBase.post("Tipos_solicitud/solicitud_tipo/", data);
        return response.data;
    } catch (error) {
        throw error;
    }
}
export const putRequestType = async (id,data) => {
    try {
        const response = await axiosBase.put(`Tipos_solicitud/solicitud_tipo/${id}/`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getRequestTypeById = async (id) => {
    try {
        const response = await axiosBase.get(`Tipos_solicitud/solicitud_tipo/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}