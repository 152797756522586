import Select from 'react-select';

export default function SelectCustom({
  options,
  value,
  name,
  onChange,
  fieldName,
  displayValue,
  displayName,
  multiple = false,
  isDisabled = false,
  isSearchable = true,
  onBlur
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "42px",
      height: "42px",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "42px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "42px",
    }),
  };

  const handleBlur = () => {
    if(onBlur) {
      onBlur(name, value);
    }
  }

  const formatOptions = (options) => {

    const newOptions = options?.map((item) => ({
      value: item[displayValue],
      label: item[displayName],
      name: name,
    }));

    newOptions.unshift({name: name, label: "Seleccione", value: ""});

    return newOptions;
  }

  return (
    <Select
      styles={customStyles}    
      value={value}
      options={formatOptions(options)}
      onChange={onChange}
      isMulti={multiple}
      placeholder={"Seleccione"}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      onBlur={handleBlur}
    />
  );
}