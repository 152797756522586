import "./ConsultarTramite.css";
import { useEffect, useState } from "react";

import { getDataPersonal, getTipoDocumentoNatural } from "../../../../services/datosPersonales/DataPerson";
import SelectAsyncPaginate from "../../../../shared/AsyncSelects/SelectAsyncPaginate";
import { Button, Table } from "rsuite";
import goback from "../../../../assets/icons/go-back-2.png";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../../..";
import constructAlert from "../../../../utils/sweetalert/constructAlert";
import { getConsultaTramite } from "../../../../services/consultaTramites/consultaTramites";
import { consultarValidaciones } from "../../../../utils/helpers/validations/consultarTramiteValidations";
import { status200ok } from "../../../../utils/helpers/validations/statusCode";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestTypes,
  getOneRequestTypes,
  getTypeSolici,
  getTypeBien,
  setFechaSolicitud,
  setHoraSolicitud,
  setIdSolicitudFormated
} from "../../../../shared/slice/reducers/requestTypes.reducers";
import {
  getIdentificationWell,
  getOneIdentificationWell,
} from "../../../../shared/slice/reducers/identificationWell.reducers";
import {
  getOneDescriptionGood,
  getDescriptionGood,
} from "../../../../shared/slice/reducers/descriptionGood.reducers";
import {
  getOneDocs,
  getDocs,
} from "../../../../shared/slice/reducers/documents.reducers";
import { getRequestTypeById } from "../../../../services/requestType/requestType";
import {
  formatDescriptionData,
  formatDocumentsData,
  formatIdentificationData,
  formatRequestTypeData,
} from "../../../../utils/helpers/others/formatData";
import { getIdPerso } from "../../../../shared/slice/reducers/personalInformations.reducers";
import {
  getDescripcionBienById,
  getIdentificacionBienById,
} from "../../../../services/descripcionBien/postDescripcionBien";
import {
  changeView,
  getonInitCreate,
  setStep,
} from "../../../../shared/slice/reducers/stepper.reducers";
import { getDocumentsDataById } from "../../../../services/adjuntarDocumentos/adjuntarDocumentos";
import { getDataInformations } from "../../../../shared/slice/reducers/personalInformations.reducers";

const { Column, HeaderCell, Cell } = Table;

const ActionsCell = ({ rowData, rowIndex, dataKey, handleClick, ...props }) => {
  const handleCellClick = () => {
    handleClick(rowData[dataKey]?.id_estado, rowData);
  };

  return (
    <Cell {...props}>
      {rowData[dataKey]?.id_estado === 0 ||
      rowData[dataKey]?.id_estado === 1 ||
      rowData[dataKey]?.id_estado === 2 ||
      rowData[dataKey]?.id_estado === 3 ||
      rowData[dataKey]?.id_estado === 4 ? (
        <a
          className="link-style"
          style={{ color: "blue" }}
          onClick={handleCellClick}
        >
          Editar
        </a>
      ) : (
        <a
          className="link-style"
          style={{ color: "green" }}
          onClick={handleCellClick}
        >
          Ver
        </a>
      )}
    </Cell>
  );
};

const ConsultarTramite = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const [errors, setErrors] = useState({
    identificationType: "",
    documentNumber: "",
  });

  const [form, setForm] = useState({
    identificationType: "",
    documentNumber: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    getTipoDocumentoNatural().then((reponse) => {
      setDocumentTypes(reponse);
    });
  }, []);

  const formatStateText = (state) => {
    switch (state) {
      case 0:
        return "En Proceso";
      case 1:
        return "En Proceso";
      case 2:
        return "En Proceso";
      case 3:
        return "En Proceso";
      case 4:
        return "En Proceso";
      case 5:
        return "Radicado";
      case 6:
        return "Aceptado";
      case 7:
        return "Rechazado";
      default:
        break;
    }
  };

  const handleEditConsult = async (state, rowData) => {
    Swal.fire({
      title: "Cargando información,por favor esperé.",
      allowOutsideClick: false,
      confirmButtonColor: "#3366CC",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });


    try {
      const personaData = await getDataPersonal(rowData.Estado.id_datos_personales);
      if (personaData.status === status200ok) {
        dispatch(getDataInformations(personaData.data));
      }
    } catch (error) {
      constructAlert("", error.response.data.message, "error", "Aceptar");
    }
    

    if (state === 1) {
      // Solo paso 1
      try {
        const response = await getRequestTypeById(rowData.id_tipo_solicitud);
        if (response.status === status200ok) {
          const data = response.data;
          dispatch(getRequestTypes(formatRequestTypeData(data)));
          dispatch(getOneRequestTypes(data.id));
          dispatch(getTypeSolici(data.tipo_solcitud));
          dispatch(getTypeBien(data.tipo_bien));
          dispatch(getIdPerso(data.id_persona));
          dispatch(getonInitCreate(true));
          dispatch(setFechaSolicitud(data?.fecha));
          dispatch(setHoraSolicitud(data?.hora));
          dispatch(setIdSolicitudFormated(String(data.id).padStart(4, '0')));
          Swal.close();
          navigate("/crearTramite");
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }
    } else if (state === 2) {
      // Paso 2 y 1

      try {
        const typeResponse = await getRequestTypeById(
          rowData.id_tipo_solicitud
        );
        const identificationResponse = await getIdentificacionBienById(
          rowData.Estado.id_identificacion_bien
        );

        if (
          typeResponse.status === status200ok &&
          identificationResponse.status === status200ok
        ) {
          const dataType = typeResponse.data;
          const identificationData = identificationResponse.data;

          dispatch(getRequestTypes(formatRequestTypeData(dataType)));
          dispatch(getOneRequestTypes(dataType.id));
          dispatch(getTypeSolici(dataType.tipo_solcitud));
          dispatch(getTypeBien(dataType.tipo_bien));
          dispatch(getIdPerso(dataType.id_persona));
          dispatch(setFechaSolicitud(dataType?.fecha));
          dispatch(setHoraSolicitud(dataType?.hora));
          dispatch(setIdSolicitudFormated(String(dataType.id).padStart(4, '0')));

          dispatch(
            getIdentificationWell(formatIdentificationData(identificationData))
          );
          dispatch(getOneIdentificationWell(identificationData.id));

          dispatch(setStep(1));
          dispatch(getonInitCreate(true));

          Swal.close();
          navigate("/crearTramite");
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }

      Swal.close();
    } else if (state === 3) {
      try {
        const typeResponse = await getRequestTypeById(
          rowData.id_tipo_solicitud
        );
        const identificationResponse = await getIdentificacionBienById(
          rowData.Estado.id_identificacion_bien
        );
        const descriptionResponse = await getDescripcionBienById(
          rowData.Estado.id_descripcion_bien
        );

        if (
          typeResponse.status === status200ok &&
          identificationResponse.status === status200ok &&
          descriptionResponse.status === status200ok
        ) {
          const dataType = typeResponse.data;
          const identificationData = identificationResponse.data;
          const descriptionData = descriptionResponse.data;

          //console.log(descriptionData);

          dispatch(getRequestTypes(formatRequestTypeData(dataType)));
          dispatch(getOneRequestTypes(dataType.id));
          dispatch(getTypeSolici(dataType.tipo_solcitud));
          dispatch(getTypeBien(dataType.tipo_bien));
          dispatch(getIdPerso(dataType.id_persona));
          dispatch(setFechaSolicitud(dataType?.fecha));
          dispatch(setHoraSolicitud(dataType?.hora));
          dispatch(setIdSolicitudFormated(String(dataType.id).padStart(4, '0')));

          dispatch(
            getIdentificationWell(formatIdentificationData(identificationData))
          );
          dispatch(getOneIdentificationWell(identificationData.id));

          dispatch(getOneDescriptionGood(descriptionData.id));
          dispatch(getDescriptionGood(formatDescriptionData(descriptionData)));

          dispatch(setStep(2));
          dispatch(getonInitCreate(true));

          Swal.close();
          navigate("/crearTramite");
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }

      Swal.close();
    } else if (state === 4) {
      try {
        const typeResponse = await getRequestTypeById(
          rowData.id_tipo_solicitud
        );
        const identificationResponse = await getIdentificacionBienById(
          rowData.Estado.id_identificacion_bien
        );
        const descriptionResponse = await getDescripcionBienById(
          rowData.Estado.id_descripcion_bien
        );
        const documentsResponse = await getDocumentsDataById(
          rowData.Estado.id_documentos
        );

        if (
          typeResponse.status === status200ok &&
          identificationResponse.status === status200ok &&
          descriptionResponse.status === status200ok &&
          documentsResponse.status === status200ok
        ) {
          const dataType = typeResponse.data;
          const identificationData = identificationResponse.data;
          const descriptionData = descriptionResponse.data;
          const documentsData = documentsResponse.data;

          dispatch(getRequestTypes(formatRequestTypeData(dataType)));
          dispatch(getOneRequestTypes(dataType.id));
          dispatch(getTypeSolici(dataType.tipo_solcitud));
          dispatch(getTypeBien(dataType.tipo_bien));
          dispatch(getIdPerso(dataType.id_persona));
          dispatch(setFechaSolicitud(dataType?.fecha));
          dispatch(setHoraSolicitud(dataType?.hora));
          dispatch(setIdSolicitudFormated(String(dataType.id).padStart(4, '0')));

          dispatch(
            getIdentificationWell(formatIdentificationData(identificationData))
          );
          dispatch(getOneIdentificationWell(identificationData.id));

          dispatch(getOneDescriptionGood(descriptionData.id));
          dispatch(getDescriptionGood(formatDescriptionData(descriptionData)));

          dispatch(getOneDocs(rowData.Estado.id_documentos));
          dispatch(getDocs(formatDocumentsData(documentsData)));

          dispatch(setStep(3));
          dispatch(getonInitCreate(true));

          Swal.close();
          navigate("/crearTramite");
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }

      Swal.close();
    } else if (state === 5) {
      try {
        const typeResponse = await getRequestTypeById(
          rowData.id_tipo_solicitud
        );
        const identificationResponse = await getIdentificacionBienById(
          rowData.Estado.id_identificacion_bien
        );
        const descriptionResponse = await getDescripcionBienById(
          rowData.Estado.id_descripcion_bien
        );
        const documentsResponse = await getDocumentsDataById(
          rowData.Estado.id_documentos
        );

        if (
          typeResponse.status === status200ok &&
          identificationResponse.status === status200ok &&
          descriptionResponse.status === status200ok &&
          documentsResponse.status === status200ok
        ) {
          const dataType = typeResponse.data;
          const identificationData = identificationResponse.data;
          const descriptionData = descriptionResponse.data;
          const documentsData = documentsResponse.data;

          dispatch(getRequestTypes(formatRequestTypeData(dataType)));
          dispatch(getOneRequestTypes(dataType.id));
          dispatch(getTypeSolici(dataType.tipo_solcitud));
          dispatch(getTypeBien(dataType.tipo_bien));
          dispatch(getIdPerso(dataType.id_persona));
          dispatch(setFechaSolicitud(dataType?.fecha));
          dispatch(setHoraSolicitud(dataType?.hora));
          dispatch(setIdSolicitudFormated(String(dataType.id).padStart(4, '0')));

          dispatch(
            getIdentificationWell(formatIdentificationData(identificationData))
          );
          dispatch(getOneIdentificationWell(identificationData.id));

          dispatch(getOneDescriptionGood(descriptionData.id));
          dispatch(getDescriptionGood(formatDescriptionData(descriptionData)));

          dispatch(getOneDocs(rowData.Estado.id_documentos));
          dispatch(getDocs(formatDocumentsData(documentsData)));

          dispatch(setStep(3));
          dispatch(getonInitCreate(true));
          dispatch(changeView(false));

          Swal.close();
          navigate("/resumen");
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }

      Swal.close();
    }
  };

  const goBackTo = () => {
    persistor.purge();
    navigate("/");
    window.location.reload();
  };

  const handleOnChange = (event) => {
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (data.length > 0) {
      setData([]);
    }

    if (value === "" || value === null || value === 0) {
      setErrors({ ...errors, [name]: "Este campo es requerido." });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    consultarValidaciones(name, value, form, errors, setErrors);

    setForm({
      ...form,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(form).forEach((key) => {
      if (form[key] === "" || form[key] === null || form[key] === 0) {
        newErrors[key] = "Este campo es requerido.";
      }
    });

    setErrors({ ...errors, ...newErrors });
  };

  const handleSubmit = async (event) => {
    setData([]);
    event.preventDefault();

    const hasErrors = Object.values(errors).some((error) => error !== "");
    console.log("hasErrors",hasErrors)
    if (!hasErrors) {
      try {
        const response = await getConsultaTramite(
          form.documentNumber,
          form.issueDate,
          form.identificationType
        );

        if ((response.status = status200ok)) {
          if (response.data.length > 0) {
            console.log(response);
            setData(response.data);
          } else {
            constructAlert("", response.data.Estado.Estado, "info", "Aceptar");
          }
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="consultar-container">
          <div className="header-style">
            <img
              src={goback}
              style={{ width: "106px" }}
              alt="icon"
              className="goback-style"
              onClick={goBackTo}
            />
            <h3>Consultar</h3>
            <p className="align-start">
              Consulte el estado de sus trámites ingresando la siguiente
              información.
            </p>
          </div>

          <form
            className="align-start row form-group gov-co-form-group"
            onSubmit={handleSubmit}
          >
            <div className="col-md-3 w-100 mt-4">
              <div style={{ height: "45px" }}>
                <label className="form-label" htmlFor="identificationType">
                  Tipo de documento *
                </label>
              </div>

              <SelectAsyncPaginate
                options={documentTypes}
                value={{
                  label:
                    documentTypes.find((e) => e.id === form.identificationType)
                      ?.descripcion || "Seleccione",
                  value: form.identificationType,
                }}
                onChange={(value) => {
                  handleOnChange(value);
                }}
                name={"identificationType"}
                fieldName={"descripcion"}
                displayName={"descripcion"}
                displayValue={"id"}
                isDisabled={false}
                isSearchable={true}
              />
              {errors?.identificationType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.identificationType}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-md-3 w-100 mt-4">
              <div style={{ height: "45px" }}>
                <label className="form-label" htmlFor="documentNumber">
                  Número del documento*
                </label>
              </div>

              <input
                id="documentNumber"
                name="documentNumber"
                value={form.documentNumber}
                onChange={handleOnChange}
                className="form-control"
                placeholder={"Número documento"}
                type="text"
              ></input>
              {errors?.documentNumber ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.documentNumber}
                  </small>
                </>
              ) : null}
            </div>

           

            <div className="col-md-3 mt-4 w-100 d-flex flex-row justify-content-center align-items-end">
              <Button
                style={{
                  backgroundColor: "#3366CC",
                  width: "100px",
                  marginBottom: "3px",
                }}
                appearance="primary"
                onClick={validateForm}
                type="submit"
              >
                Consultar
              </Button>
            </div>
          </form>

          {data?.length > 0 && (
            <Table
              hover={true}
              showHeader={true}
              autoHeight={true}
              bordered={true}
              cellBordered={true}
              data={data}
              className="mt-5"
              style={{ zIndex: 0 }}
            >
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Identificación
                </HeaderCell>
                <Cell dataKey="">{form.documentNumber}</Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Trámite
                </HeaderCell>
                <Cell dataKey="Tipo trámite"></Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Fecha de solicitud
                </HeaderCell>
                <Cell dataKey="fecha"></Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  # Solicitud
                </HeaderCell>
                <Cell
                  dataKey="Estado"
                  renderCell={(value) => {
                    return String(value.id_tipo_solicitud).padStart(4, '0')
                  }}
                ></Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  # Orfeo
                </HeaderCell>
                <Cell
                  dataKey="Estado"
                  renderCell={(value) => value.id_radicado_orfeo}
                ></Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Estado
                </HeaderCell>
                <Cell
                  dataKey="Estado"
                  renderCell={(value) => formatStateText(value.id_estado)}
                ></Cell>
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell
                  style={{
                    color: "#004884",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Acción
                </HeaderCell>
                <ActionsCell dataKey="Estado" handleClick={handleEditConsult} />
              </Column>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultarTramite;
