import React from "react";
function AceptacionTerminos({ handleOnchange, checkName1, checkName2, errors, isFormSubmitted, formValues, editableMode }) {
  return (
    <div>
      <h2 className="h3-tramite" style={{fontSize: "15px", lineHeight: "normal"}}>
        Aviso de privacidad y autorización para el tratamiento de datos
        personales
      </h2>
      <div className="row">
      <div className="mt-3 col-md-6">
        <div style={{height: "50px"}}>
          <a
            style={{height: "50px"}} 
            className="h3-tramite"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.fuga.gov.co/transparencia-y-acceso-a-la-informacion-publica/normativa/politicas-de-seguridad-de-la-informacion-del-sitio"
          >
            Autorizo el tratamiento de mis datos personales</a>
        </div>
        <div className="mt-2">
          <div className="d-flex flex-row justify-content-start align-items-center mt-4">
            <input           
              type="radio"
              id="datos-personales-si"
              name={checkName1}
              value={true}
              onChange={handleOnchange}
              checked={formValues[checkName1] === "true"}
              disabled={editableMode} 
            />
            <label style={{margin: 0}} className="valCheck" htmlFor="datos-personales-si">
                &#160; SI
            </label>
          </div>

          <div className="d-flex flex-row justify-content-start align-items-center mt-4">
            <input           
              type="radio"
              id="datos-personales-no"
              name={checkName1}
              value={false}
              onChange={handleOnchange}
              checked={formValues[checkName1] === "false"}
              disabled={editableMode} 
            />
            <label style={{margin: 0}} className="valCheck" htmlFor="datos-personales-no">
              &#160; NO
            </label>
          </div>

        </div>

        {
          errors && (isFormSubmitted || errors[checkName1] !== "Este campo es requerido")
          ? 
            <>
              <small className="form-text" style={{color: 'red', fontSize: '12px'}}>{errors[checkName1]}</small>            
            </>
          : null
        }
      </div>

      <div className="mt-3 col-md-6">
        <div style={{height: "50px"}}>
          <a 
            className="h3-tramite"
            target="_blank"
            rel="noopener noreferrer"
            href="https://fuga.gov.co/sites/default/files/2022-02/Propuesta_terminos_y_condiciones_web_fuga.pdf"
          >
            Acepto términos y condiciones</a>
        </div>

        <div className="mt-2">
          <div className="d-flex flex-row justify-content-start align-items-center mt-4">
            <input           
              type="radio"
              id="datos-personales-2-si"
              name={checkName2}
              value={true}
              onChange={handleOnchange}
              checked={formValues[checkName2] === "true"}
              disabled={editableMode}  
            />
            <label style={{margin: 0}} className="valCheck" htmlFor="datos-personales-2-si">
                &#160; SI
            </label>
          </div>
          <div className="d-flex flex-row justify-content-start align-items-center mt-4">
            <input           
              type="radio"
              id="datos-personales-2-no"
              name={checkName2}
              value={false}
              onChange={handleOnchange}
              checked={formValues[checkName2] === "false"} 
              disabled={editableMode}
            />
            <label style={{margin: 0}} className="valCheck" htmlFor="datos-personales-2-no">
              &#160; NO
            </label>
          </div>
        </div>
        {
          errors && errors[checkName2]
          ? 
            <>
              <small className="form-text" style={{color: 'red', fontSize: '12px'}}>{errors[checkName2]}</small>            
            </>
          : null
        }
      </div>
      </div>

    </div>
  );
}

export default AceptacionTerminos;
