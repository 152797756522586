// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-style {
    position: relative;
}

.goback-style {
  position: absolute;
  left: 0;
  width: 35px;
  top: 14px;
  filter: invert(15%) sepia(91%) saturate(2341%) hue-rotate(191deg) brightness(98%) contrast(101%);
  cursor: pointer;
}

.message-documents {
  color: #004884;
  font-size: 12px;
  font-weight: 700;
  /* text-align: justify; */
}

.label-style {
  height: 60px;
}

.modal-box {
  border: 1px solid hsla(0,0%,100%,.28);
  position: fixed;
  top: 13%;
  left: 0;
  z-index: 2;
  background-color: #004884;
  color: white;
  border-radius: 0 12px 0 0;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2% 0 1%;
}

.icon-only {
  padding: 0.65%;
  border-radius: 0 12px 12px 0;
}

.information-box {
  position: fixed;
  top: 18.5%;
  left: 0%;
  border-radius: 0 12px 12px 0;
  border: 1px solid hsla(0, 0%, 39%, 0.281);
  background-color: #f6f8f9;
  z-index: 2;
  padding: 2%;
  text-align: start;
  width: 30%;
}

.title-color {
  color: #004884;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/crear/components/Tramite/components/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,SAAS;EACT,gGAAgG;EAChG,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,QAAQ;EACR,OAAO;EACP,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,UAAU;EACV,QAAQ;EACR,4BAA4B;EAC5B,yCAAyC;EACzC,yBAAyB;EACzB,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".header-style {\n    position: relative;\n}\n\n.goback-style {\n  position: absolute;\n  left: 0;\n  width: 35px;\n  top: 14px;\n  filter: invert(15%) sepia(91%) saturate(2341%) hue-rotate(191deg) brightness(98%) contrast(101%);\n  cursor: pointer;\n}\n\n.message-documents {\n  color: #004884;\n  font-size: 12px;\n  font-weight: 700;\n  /* text-align: justify; */\n}\n\n.label-style {\n  height: 60px;\n}\n\n.modal-box {\n  border: 1px solid hsla(0,0%,100%,.28);\n  position: fixed;\n  top: 13%;\n  left: 0;\n  z-index: 2;\n  background-color: #004884;\n  color: white;\n  border-radius: 0 12px 0 0;\n  cursor: pointer;\n  font-weight: 500;\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0 2% 0 1%;\n}\n\n.icon-only {\n  padding: 0.65%;\n  border-radius: 0 12px 12px 0;\n}\n\n.information-box {\n  position: fixed;\n  top: 18.5%;\n  left: 0%;\n  border-radius: 0 12px 12px 0;\n  border: 1px solid hsla(0, 0%, 39%, 0.281);\n  background-color: #f6f8f9;\n  z-index: 2;\n  padding: 2%;\n  text-align: start;\n  width: 30%;\n}\n\n.title-color {\n  color: #004884;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
