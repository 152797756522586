import axiosBase from "../../utils/axios/axios";


export const postAdjuntarDocumentos = async (data) => {
    try {
        const response = await axiosBase.post("Adjuntar_documentos/todos_documentos/", data);
        return response.data;
    } catch (error) {
        throw error
    }
}
export const putAdjuntarDocumentos = async (id,data) => {
    try {
        const response = await axiosBase.put(`Adjuntar_documentos/todos_documentos/${id}/`, data);
        return response.data;
    } catch (error) {
        throw error
    }
}

export const getDocumentsDataById = async (id) => {
    try {
        const response = await axiosBase.get(`Adjuntar_documentos/todos_documentos/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}