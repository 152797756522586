import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_identificationWell: 0,
  identificationWell: {
    nombre_bien: "",
    chip: "",
    matricula_inmobiliaria: "",
    direccion_oficial: "",
    direccion_oficialObj: {
      via: "",
      numeroVia: null,
      letra: "",
      bis: "",
      cuadrante: "",
      viaGeneradora: "",
      letraSecundaria: "",
      bisSecundario: "",
      numeroViaSecundaria: null,
      cuadranteSecundario: "",
      complemento: "",
    },
    direccion_secundaria: "",
    direccion_secundariaObj: {
      via: "",
      numeroVia: null,
      letra: "",
      bis: "",
      cuadrante: "",
      viaGeneradora: "",
      letraSecundaria: "",
      bisSecundario: "",
      numeroViaSecundaria: null,
      cuadranteSecundario: "",
      complemento: "",
    },
    direcciones_anteriores: "",
    direcciones_anterioresObj: {
      via: "",
      numeroVia: null,
      letra: "",
      bis: "",
      cuadrante: "",
      viaGeneradora: "",
      letraSecundaria: "",
      bisSecundario: "",
      numeroViaSecundaria: null,
      cuadranteSecundario: "",
      complemento: "",
    },
    direccion_declaratoria: "",
    direccion_declaratoriaObj: {
      via: "",
      numeroVia: null,
      letra: "",
      bis: "",
      cuadrante: "",
      viaGeneradora: "",
      letraSecundaria: "",
      bisSecundario: "",
      numeroViaSecundaria: null,
      cuadranteSecundario: "",
      complemento: "",
    }, 
    localidad: 0,
    upl: null,
    upz: null,
    barrio: null,
    vereda: "",
    numero_manzana: null,
    numero_lote: null,
    zona: null,
    id_solicitud: 0,
  },
};

export const identificationWellSlide = createSlice({
  name: "Identifiacion_bien",
  initialState,
  reducers: {
    getIdentificationWell: (state, actions) => {
      state.identificationWell = actions.payload;
    },
    getOneIdentificationWell: (state, actions) => {
      state.id_identificationWell = actions.payload;
    },
    changeTexIdent: (state, actions) => {
      const { name, value } = actions.payload;
      if (name === "nombreBien") {
        state.identificationWell.nombre_bien = value;
      } else if (name === "chip") {
        state.identificationWell.chip = value;
      } else if (name === "matriculaInmoviliaria") {
        state.identificationWell.matricula_inmobiliaria = value;
      } else if (name === "localidad") {
        state.identificationWell.localidad = value;
        state.identificationWell.upz = null;
        state.identificationWell.barrio = null;
      } else if (name === "zona") {
        state.identificationWell.zona = value;
      } else if (name === "upl") {
        state.identificationWell.upl = value;
      } else if (name === "upz") {
        state.identificationWell.upz = value;
        state.identificationWell.barrio = null;
      } else if (name === "barrio") {
        state.identificationWell.barrio = value;
      } else if (name === "vereda") {
        state.identificationWell.vereda = value;
      } else if (name === "direccionOficial") {
        state.identificationWell.direccion_oficial = value;
      }else if(name === "direccionSecundaria"){
        state.identificationWell.direccion_secundaria = value;
      }else if(name === "direccionAnterior"){
        state.identificationWell.direcciones_anteriores = value;
      }else if(name === "direccionDeclaratoria"){
        state.identificationWell.direccion_declaratotia = value;
      }else if (name === "numeroManzana"){
        state.identificationWell.numero_manzana = value;
      
      }else if (name === "numeroLote"){
        state.identificationWell.numero_lote = value;
      }
    },
    saveAddress: (state, actions) => {
      const {name, data} = actions.payload;
      if (name === "direccionOficial") {
        state.identificationWell.direccion_oficialObj = {
          ...state.identificationWell.direccion_oficialObj,
          ...data
        }
      } else if ( name === "direccionSecundaria") {
        state.identificationWell.direccion_secundariaObj = {
          ...state.identificationWell.direccion_secundariaObj,
          ...data
        }
      } else if ( name === "direccionAnterior") {
        state.identificationWell.direcciones_anterioresObj = {
          ...state.identificationWell.direcciones_anterioresObj,
          ...data
        }
      } else if ( name === "direccionDeclaratoria") {
        state.identificationWell.direccion_declaratoriaObj = {
          ...state.identificationWell.direccion_declaratoriaObj,
          ...data
        }
      }
    },
    setOficialAddress: (state, actions) => {
      state.identificationWell.direccion_oficial = Object.values(state.identificationWell.direccion_oficialObj).join(" ").replace(/\s+/g, " ");
    },
    setSecondaryAddress: (state, actions) => {
      state.identificationWell.direccion_secundaria = Object.values(state.identificationWell.direccion_secundariaObj).join(" ").replace(/\s+/g, " ");
    },
    setPreviousAddress: (state, actions) => {
      state.identificationWell.direcciones_anteriores = Object.values(state.identificationWell.direcciones_anterioresObj).join(" ").replace(/\s+/g, " ");
    },
    setDeclaratoriaAddress: (state, actions) => {
      state.identificationWell.direccion_declaratoria = Object.values(state.identificationWell.direccion_declaratoriaObj).join(" ").replace(/\s+/g, " ");
    }
  },
});

export const {
  getIdentificationWell,
  getOneIdentificationWell,
  changeTexIdent,
  saveAddress,
  setOficialAddress,
  setSecondaryAddress,
  setPreviousAddress,
  setDeclaratoriaAddress
} = identificationWellSlide.actions;
export default identificationWellSlide.reducer;
