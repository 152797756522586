import React from "react";
import "./tramite.css";
import 'rsuite/styles/index.less'; 
import { Steps, Panel } from 'rsuite';
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "../../../../shared/slice/reducers/stepper.reducers.js";
import TipoSolicitud from "./components/tipoSolicitud.jsx"
import IdentificacionBien from "./components/identificacionBien.jsx";
import DescripcionBien from "./components/descripcionBien.jsx";
import CargaDocumentos from "./components/cargaDocumentos.jsx";

const Tramite = () => {
    const dispatch = useDispatch();
    const { step } = useSelector((state) => state.steps);
    const onChange = nextStep => {
      dispatch(setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep));
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
  return (
    <>
      <div className="form-container">
      <Steps current={step} className="mt-3" style={{paddingTop: "20px"}}>
        <Steps.Item title="Solicitud"  />
        <Steps.Item title="Identificación"  />
        <Steps.Item title="Descripción"  />
        <Steps.Item title="Carga"  />
      </Steps>
      <Panel >
      {step === 0 ? (
        <TipoSolicitud step={step} onNext={onNext} onPrevious={onPrevious}></TipoSolicitud>
      ): 
      step === 1 ? (
        <IdentificacionBien step={step} onNext={onNext} onPrevious={onPrevious}></IdentificacionBien>
      ) : 
      step === 2 ?(
        <DescripcionBien step={step} onNext={onNext} onPrevious={onPrevious}></DescripcionBien>
        ): 
      step === 3 && (
        <CargaDocumentos step={step} onNext={onNext} onPrevious={onPrevious}></CargaDocumentos>
      )
      }
      </Panel>
      </div>
    </>
  );
};

export default Tramite;
