import React from "react";
import "./DatosPersonales.css";
import ReCAPTCHAInput from "../../../../shared/components/ReCAPTCHAInput";
import AceptacionTerminos from "../../../../shared/components/AceptacionTerminos";
import { useEffect, useState } from "react";
import FormPersona from "./FormPersonaNatural/FormPersona";
import { Form } from "react-final-form";
import SelectAsyncPaginate from "../../../../shared/AsyncSelects/SelectAsyncPaginate";
import { useLocation, useNavigate } from "react-router-dom";

import { getTiposPersona } from "../../../../services/datosPersonales/DataPerson";

import { datosPersonlaesValidaciones } from "../../../../utils/helpers/validations/DatosPeronalesValidacion";
import { postDatosPersonales } from "../../../../services/datosPersonales/DataPerson";
import { updateDatosPersonales } from "../../../../services/datosPersonales/DataPerson";
import constructAlert from "../../../../utils/sweetalert/constructAlert";
import { status201ok } from "../../../../utils/helpers/validations/statusCode";

import goback from "../../../../assets/icons/go-back-2.png";
import constructTwoButtonsAlert from "../../../../utils/sweetalert/constructTwoButtonsAlert";
import { useDispatch } from "react-redux";
import {
  getDataInformations,
  getIdPerso,
  postTypePerson,
} from "../../../../shared/slice/reducers/personalInformations.reducers";
import { persistor } from "../../../..";

function DatosPersonales() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [typesPerson, setTypesPerson] = useState([]);
  let navigate = useNavigate();
  const [idPerson, setIdPerson] = useState(null);
  const [typePerson, setTypePerson] = useState(null);
  const [address, setAddress] = useState({
    via: "",
    numeroVia: "",
    letra: "",
    bis: "",
    cuadrante: "",
    viaGeneradora: "",
    letraSecundaria: "",
    bissSecundario: "",
    numeroViaSecundaria: "",
    cuadranteSecundario: "",
    complemento: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorsNatural, setErrorsNatural] = useState({
    identificationType: "",
    identificationNumber: "",
    validateIdentificationNumber: "",
    issueDate: "",
    birthDate: "",
    gender: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNumber1: "",
    address: "",
    checkPrivacy: "",
    checkTerms: "",
    scrdAutorization: "",
    typePerson: "",
  });

  const [errorsJuridica, setErrorsJuridica] = useState({
    legalType: "",
    companyName: "",
    identificationNumber: "",
    firstName: "",
    lastName: "",
    identificationLegalType: "",
    identificationLegalNumber: "",
    email: "",
    contactNumber1: "",
    checkPrivacy: "",
    checkTerms: "",
    scrdAutorization: "",
    typePerson: "",
  });

  const [form, setForm] = useState({
    companyName: "",
    legalType: "",
    identificationType: "",
    identificationNumber: "",
    validateIdentificationNumber: "",
    issueDate: "",
    birthDate: "",
    gender: "",
    firstName: "",
    secondName: "",
    lastName: "",
    secondSurname: "",
    identificationLegalType: "",
    identificationLegalNumber: "",
    email: "",
    contactNumber1: "",
    contactNumber2: "",
    scrdAutorization: "",
    checkPrivacy: "",
    checkTerms: "",
    typePerson: "",
    address: "",
  });

  const [idExistingUser, setIdExistingUser] = useState(null);
  const [editableMode, setEditableMode] = useState(false);
  const [areInputsUpdated, setAreInputsUpdated] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Trámite BIC - Datos solicitante`;
    getTiposPersona().then((response) => {
      setTypesPerson(response);
    });
  }, [idPerson, location.pathname]);

  function goBackTo() {
    persistor.purge();
    navigate("/");
    window.location.reload();
  }

  function handleOnChange(event) {
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (editableMode) {
      setAreInputsUpdated(true);
    }

    if (typePerson && typePerson.value === 1) {
      datosPersonlaesValidaciones(
        name,
        value,
        form,
        errorsNatural,
        setErrorsNatural
      );
    } else {
      datosPersonlaesValidaciones(
        name,
        value,
        form,
        errorsJuridica,
        setErrorsJuridica
      );
    }

    setForm({
      ...form,
      [name]: value,
    });
  }

  function handleAddressChange(event) {
    let name;
    let value;
    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }
    if (name === "bis") {
      value = event.target.checked === true ? "BIS" : "";
    }
    console.log(value);
    if (editableMode) {
      setAreInputsUpdated(true);
    }
    if (typePerson && typePerson.value === 1) {
      datosPersonlaesValidaciones(
        "address",
        { ...address, [name]: value },
        form,
        errorsNatural,
        setErrorsNatural
      );
    }
    setAddress({
      ...address,
      [name]: value,
    });
    setForm({
      ...form,
      address: {
        [name]: value,
      },
    });
  }

  const validateErrors = () => {
    const newErrors = {};
    if (typePerson && typePerson.value === 1) {
      Object.keys(errorsNatural).forEach((key) => {
        console.log(errorsNatural[key])
        if (form[key] === "" && errorsNatural[key] === "") {
          newErrors[key] = "Este campo es requerido.";
        }
      });
      if (Object.keys(newErrors).length > 0) setErrorsNatural(newErrors);
    } else {
      Object.keys(errorsJuridica).forEach((key) => {
        if (form[key] === "" && errorsJuridica[key] === "") {
          newErrors[key] = "Este campo es requerido.";
        }
      });
      if (Object.keys(newErrors).length > 0) setErrorsJuridica(newErrors);
    }
  };

  const resetErrors = () => {
    setErrorsJuridica({
      legalType: "",
      companyName: "",
      identificationNumber: "",
      firstName: "",
      lastName: "",
      identificationLegalType: "",
      identificationLegalNumber: "",
      email: "",
      contactNumber1: "",
      checkPrivacy: "",
      checkTerms: "",
      scrdAutorization: "",
      typePerson: ""
    });

    setErrorsNatural({
      identificationType: "",
      identificationNumber: "",
      validateIdentificationNumber: "",
      issueDate: "",
      birthDate: "",
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      contactNumber1: "",
      address: "",
      checkPrivacy: "",
      checkTerms: "",
      scrdAutorization: "",
      typePerson: ""
    });
  };

  const onSubmit = async () => {
    const hasErrors = Object.values(
      typePerson?.value === 1 ? errorsNatural : errorsJuridica
    ).some((error) => error !== "");
    if (!hasErrors && recaptchaValue !== null) {
      if (
        form.checkPrivacy === "false" ||
        form.checkTerms === "false" ||
        form.checkPrivacy === "" ||
        form.checkTerms === ""
      ) {
        constructAlert(
          "",
          `Para continuar con su solicitud exitosamente, deberá aceptar la política de privacidad y autorización para el tratamiento de datos personales, eligiendo la opción SI para ambos casos`,
          "info",
          "Aceptar"
        ).then(() => {
          navigate("/");
        });
      } else {
        let data = {};
        data.tipo_persona = typePerson.value;
        data.se_autoriza_secretaria =
          form.scrdAutorization === "true" ? true : false;
        data.se_autoriza_tratamiento =
          form.checkPrivacy === "true" ? true : false;
        data.acepto_terminos_condiciones =
          form.checkTerms === "true" ? true : false;

        if (typePerson.value === 1) {
          data.tipo_documento = form.identificationType;
          data.numero = form.identificationNumber;
          data.fecha_expedicion = form.issueDate;
          data.fecha_nacimiento = form.birthDate;
          data.sexo = form.gender;
          data.primer_nombre = form.firstName;
          data.segundo_nombre = form.secondName || null;
          data.primer_apellido = form.lastName;
          data.segundo_apellido = form.secondSurname || null;
          data.correo_electronico = form.email;
          data.numero_contacto = form.contactNumber1;
          data.numero_contacto2 = form.contactNumber2 || null;
          data.direccion =
            typeof address === "object"
              ? Object.values(address).join(" ").replace(/\s+/g, " ")
              : address;
          data.tipo_via = address.via;
          data.numero_via = address.numeroVia;
          data.letra = address.letra || null;
          data.bis = address.bis === "BIS" ? true : false;
          data.cuadrante = address.cuadrante || null;
          data.via_generadora = address.viaGeneradora;
          data.letra2 = address.letraSecundaria || null;
          data.bis2 = address.bis === "BIS" ? true : false;
          data.numero_dir = address.numeroViaSecundaria;
          data.cuadrante2 = address.cuadranteSecundario || null;
          data.complemento = address.complemento || null;
          try {
            let response;

            if (!editableMode) {
              response = await postDatosPersonales(data);
            } else {
              response = await updateDatosPersonales(idExistingUser, data);
            }
            if ((response.data.status = status201ok)) {
              data.bis = address.bis === true ? "BIS" : "";
              dispatch(postTypePerson(data.tipo_persona));
              dispatch(getDataInformations(data));
              if (data.se_autoriza_secretaria === false) {
                constructAlert(
                  "",
                  `¡Los datos personales han sido ${
                    editableMode ? "actualizados" : "guardados"
                  } correctamente,sin embargo no serán notificadas las novedades a tu correo electrónico registrado!`,
                  "info",
                  "Continuar"
                ).then(() => {
                  if (!editableMode) {
                     navigate("/crearTramite");
                  }
                });
              } else {
                constructAlert(
                  "",
                  `¡Los datos personales han sido ${
                    editableMode ? "actualizados" : "guardados"
                  } correctamente!`,
                  "success",
                  "Continuar"
                ).then(() => {
                  setIdPerson(response.data.id);
                  dispatch(getIdPerso(response.data.id));
                  if (!editableMode) {
                     navigate("/crearTramite");
                  }
                  setAreInputsUpdated(false);
                });
              }
            }
          } catch (error) {
            constructAlert("", error.response.data.message, "error", "Aceptar");
          }
        } else {
          data.tipo_documento_empresa = form.legalType;
          data.numero_id_tributaria = form.identificationNumber;
          data.razon_social = form.companyName;
          data.primer_nombre = form.firstName;
          data.segundo_nombre = form.secondName;
          data.primer_apellido = form.lastName;
          data.segundo_apellido = form.secondSurname;
          data.tipo_documento = form.identificationLegalType;
          data.numero = form.identificationLegalNumber;
          data.correo_electronico = form.email;
          data.numero_contacto = form.contactNumber1;

          try {
            let response;

            if (!editableMode) {
              response = await postDatosPersonales(data);
            } else {
              response = await updateDatosPersonales(idExistingUser, data);
            }

            if ((response.data.status = status201ok)) {
              dispatch(postTypePerson(data.tipo_persona));
              dispatch(getDataInformations(data));
              if (data.se_autoriza_secretaria === false) {
                constructAlert(
                  "",
                  `¡Los datos personales han sido ${
                    editableMode ? "actualizados" : "guardados"
                  } correctamente,sin embargo no serán notificadas las novedades a tu correo electrónico registrado`,
                  "info",
                  "Continuar"
                ).then(() => {
                  if (!editableMode) {
                    navigate("/crearTramite");


                  }
                });
              } else {
                constructAlert(
                  "",
                  `¡Los datos personales han sido ${
                    editableMode ? "actualizados" : "guardados"
                  } correctamente!`,
                  "success",
                  "Continuar"
                ).then(() => {
                  setIdPerson(response.data.id);
                  dispatch(getIdPerso(response.data.id));
                  if (!editableMode){
                    navigate("/crearTramite");

                  }
                  setAreInputsUpdated(false);
                });
              }
            }
          } catch (error) {
            constructAlert("", error.response.data.message, "error", "Aceptar");
          }
        }
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  const handleUpdateNavigate = () => {
    const hasErrors = Object.values(
      typePerson?.value === 1 ? errorsNatural : errorsJuridica
    ).some((error) => error !== "");
    if (!hasErrors && recaptchaValue !== null) {
      if (form.scrdAutorization === "false") {
        constructAlert(
          "",
          "¡Recuerde que al no aceptar la autorización de la secretaría, no serán notificadas las novedades a su correo electrónico registrado!",
          "info",
          "Aceptar"
        ).then((res) => {
          if (res.value === true) {
            localStorage.setItem("id_person", idExistingUser);
             navigate("/crearTramite");
          }
        });
      } else {
        constructTwoButtonsAlert(
          "",
          "¡Si realizó cambios en los datos, recuerde guardarlos antes de continuar!",
          "info",
          "Continuar",
          "Cancelar"
        ).then((res) => {
          if (res.value === true) {
            localStorage.setItem("id_person", idExistingUser);

             navigate("/crearTramite");
          }
        });
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  const handleOnBlurValidation = (name, value) => {
    const newErrors = {};

    if (
      form[name] === "" &&
      (errorsNatural.hasOwnProperty(name) ||
        errorsJuridica.hasOwnProperty(name))
    ) {
      newErrors[name] = "Este campo es requerido.";
    }
    if (typePerson?.value === 1) {
      setErrorsNatural({ ...errorsNatural, ...newErrors });
    } else {
      setErrorsJuridica({ ...errorsJuridica, ...newErrors });
    }
  };

  const handleCaptchaChange = (event) => {
    setRecaptchaValue(event);
  };

  return (
    <>
      <div className="form-container">
        <div>
          <nav
            style={{ "--bs-breadcrumb-divider": ">" }}
            aria-label="breadcrumb"
            className="mt-1"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item mt-3" aria-current="page">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item active mt-3" aria-current="page">
                Datos solicitante
              </li>
            </ol>
          </nav>
        </div>
        <hr className="hrC"></hr>
        <h3 className="title-p mt-1 d-flex">Creación de solicitud</h3>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="header-style">
                  <img
                    src={goback}
                    alt="icon"
                    style={{ width: "106px" }}
                    className="goback-style imgB"
                    onClick={goBackTo}
                  />
                  <h3>Datos del solicitante</h3>
                  <p className="align-center">
                    Ingresa tus datos de identificación para iniciar la
                    solicitud en línea del trámite de declaratoria, revocatoria
                    o cambio de nivel de intervención de un bien de interés
                    cultural del ámbito Distrital.
                  </p>
                  <p className="align-start">
                    <b>
                      NOTA: Todos los campos marcados con * son obligatorios
                    </b>
                  </p>
                </div>

                <div className="align-start row form-group gov-co-form-group">
                  <div className="col-md-6 w-100 mt-3">
                    <div className="normal-box">
                      <label className="form-label" htmlFor="typePerson">
                        Tipo de persona *
                      </label>
                      <SelectAsyncPaginate
                        options={typesPerson}
                        value={typePerson}
                        onChange={(option) => {
                          setTypePerson(option);
                          setForm({ ...form, typePerson: option.value });
                          resetErrors();
                        }}
                        name={"typePerson"}
                        fieldName={"tipo"}
                        displayName={"tipo"}
                        displayValue={"id"}
                        isDisabled={editableMode}
                        isSearchable={editableMode ? false : true}
                        onBlur={(name, value) => {
                          handleOnBlurValidation(name, value?.value);
                        }}
                      />
                      {errorsNatural?.typePerson || errorsJuridica?.typePerson ? (
                        <>
                          <small
                            className="form-text"
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {typePerson?.value === 1
                              ? errorsNatural.typePerson
                              : errorsJuridica.typePerson}
                          </small>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <FormPersona
                    typePerson={typePerson && typePerson.value}
                    handleOnChange={handleOnChange}
                    handleAddressChange={handleAddressChange}
                    address={address}
                    setAddress={setAddress}
                    form={form}
                    setForm={setForm}
                    errors={
                      (typePerson && typePerson.value) === 1
                        ? errorsNatural
                        : errorsJuridica
                    }
                    isFormSubmitted={isFormSubmitted}
                    idExistingUser={idExistingUser}
                    setIdExistingUser={setIdExistingUser}
                    resetErrors={resetErrors}
                    editableMode={editableMode}
                    setEditableMode={setEditableMode}
                    setTypePerson={setTypePerson}
                    typesPerson={typesPerson}
                    handleOnBlurValidation={handleOnBlurValidation}
                  />
                </div>
                <div className="align-start mt-5 ">
                  <h3 className="h3-tramite mb-4" style={{fontSize: "15px", lineHeight: "normal"}}>
                    Autoriza a la Secretaría Cultura Recreación y Deporte a
                    notificar de las decisiones adoptadas, a través de correo
                    electrónico
                  </h3>
                  <input
                    type="radio"
                    id="si"
                    name="scrdAutorization"
                    value={"true"}
                    onChange={handleOnChange}
                    checked={form.scrdAutorization === "true"}
                    className="mt-4"
                  />
                  <label htmlFor="si">&#160; SI</label>
                  <br />
                  <input
                    type="radio"
                    id="no"
                    name="scrdAutorization"
                    value={"false"}
                    checked={form.scrdAutorization === "false"}
                    onChange={handleOnChange}
                    className="mt-4"
                  />
                  <label htmlFor="no">&#160; NO</label>
                  <br />
                  {(errorsNatural && errorsNatural.scrdAutorization) ||
                  (errorsJuridica && errorsJuridica.scrdAutorization) ? (
                    <>
                      <small
                        className="form-text"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {typePerson && typePerson.value === 1
                          ? errorsNatural.scrdAutorization
                          : errorsJuridica.scrdAutorization}
                      </small>
                    </>
                  ) : null}
                </div>
                <div className="align-start mt-5">
                  <AceptacionTerminos
                    handleOnchange={handleOnChange}
                    checkName1={"checkPrivacy"}
                    checkName2={"checkTerms"}
                    errors={
                      (typePerson && typePerson.value) === 1
                        ? errorsNatural
                        : errorsJuridica
                    }
                    isFormSubmitted={isFormSubmitted}
                    formValues={form}
                    editableMode={editableMode}
                  />
                </div>
                <div className="mt-5">
                  <ReCAPTCHAInput handleCaptchaChange={handleCaptchaChange} />
                  {recaptchaValue === null ? (
                    <>
                      <small
                        className="form-text"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        Por favor validar este campo
                      </small>
                    </>
                  ) : null}
                </div>
              </div>
              {editableMode ? (
                <div className="row gx-5">
                  <div className="col mt-5">
                    <button
                      type="submit"
                      className="btn btn-round btn-high boton-inicio"
                      disabled={!areInputsUpdated}
                      onClick={validateErrors}
                    >
                      Actualizar datos
                    </button>
                  </div>
                  <div className="col mt-5">
                    <button
                      type="button"
                      className="btn btn-round btn-high boton-inicio"
                      disabled={
                        form.checkPrivacy === "false" ||
                        form.checkTerms === "false"
                      }
                      onMouseDown={validateErrors}
                      onClick={handleUpdateNavigate}
                    >
                      Iniciar trámite
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="btn btn-round btn-high boton-inicio"
                      // disabled={
                      //   form.checkPrivacy === "false" ||
                      //   form.checkTerms === "false" ||
                      //   form.checkPrivacy === "" ||
                      //   form.checkTerms === ""
                      // }
                      onClick={validateErrors}
                    >
                      Iniciar trámite
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        ></Form>
      </div>
    </>
  );
}

export default DatosPersonales;
