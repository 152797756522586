import axiosBase from "../../utils/axios/axios";
import constructAlert from "../../utils/sweetalert/constructAlert";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const postDatosPersonales = async (data) => {
  try {
    const response = await axiosBase.post(
      "/ingreso/identificacion_solicitante/",
      data
    );
    return response.data;
  } catch (error) {
    constructAlert("", error.response.data.message, "error", "Aceptar");
    throw error;
  }
};

// export const postDatosPersonales = createAsyncThunk(
//   "dataPerson/createPerson",
//   async (data, { dispatch }) => {
//     try {
//       const response = await axiosBase.post(
//         "/ingreso/identificacion_solicitante/",
//         data
//       );
//       if(data.response.)
//       return response.data;
//     } catch (error) {}
//   }
// );
export const updateDatosPersonales = async (id, data) => {
  try {
    const response = await axiosBase.put(
      `/ingreso/identificacion_solicitante/${id}/`,
      data
    );
    return response.data;
  } catch (error) {
    constructAlert("", error.response.data.message, "error", "Aceptar");
    throw error;
  }
};
export const getTiposGenero = async () => {
  try {
    const response = await axiosBase.get("api/Sexo/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getTiposPersona = async () => {
  try {
    const response = await axiosBase.get("ingreso/tipo_persona/");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const getTipoDocumentoNatural = async () => {
  try {
    const response = await axiosBase.get("api/TipoDocumento/pesona_natural");
    return response.data.message;
  } catch (error) {
    throw error;
  }
};

export const getTipoDocumentoJuridica = async () => {
  try {
    const response = await axiosBase.get("api/TipoDocumento/pesona_juridica");
    return response.data.message;
  } catch (error) {
    throw error;
  }
};
export const getUsuarioExistente = async (idNumber) => {
  try {
    const response = await axiosBase.get(`ingreso/filtro/${idNumber}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const postDatosJuridica = async (data) => {
  try {
    const response = await axiosBase.post("ingreso/persona_juridica/", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDataPersonal = async (id) => {
  try {
    const response = await axiosBase.get(`ingreso/identificacion_solicitante/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};