import React, { useState, useEffect } from "react";
import SelectAsyncPaginate from "../../../../../shared/AsyncSelects/SelectAsyncPaginate";
import SelectCustom from "../../../../../shared/AsyncSelects/SelectCustom";

import DireccionInput from "../../../../../shared/components/DireccionInput";
import { Form } from "react-final-form";
import {
  getBarrio,
  getLocal,
  getNumberApples,
  getUPL,
  getUPZ,
  getZone,
} from "../../../../../services/typeGood/typeGood";
import {
  postIdentificacionBien,
  putIdentificacionBien,
} from "../../../../../services/descripcionBien/postDescripcionBien";
import { ButtonGroup, Button } from "rsuite";
import constructAlert from "../../../../../utils/sweetalert/constructAlert";
import "./styles.css";
import constructTwoButtonsAlert from "../../../../../utils/sweetalert/constructTwoButtonsAlert";
import { useLocation, useNavigate } from "react-router-dom";
import goback from "../../../../../assets/icons/go-back-2.png";
import {
  preventNegativeValues,
  preventMoreThreeCharacters,
} from "../../../../../utils/helpers/others/others";
import { identificacionBienValidaciones } from "../../../../../utils/helpers/validations/identificacionBienValidacion";
import { status201ok } from "../../../../../utils/helpers/validations/statusCode";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTexIdent,
  getIdentificationWell,
  getOneIdentificationWell,
  saveAddress,
  setOficialAddress,
  setSecondaryAddress,
  setPreviousAddress,
  setDeclaratoriaAddress,
} from "../../../../../shared/slice/reducers/identificationWell.reducers";
import { persistor } from "../../../../..";

export default function IdentificacionBien({ step, onNext, onPrevious }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id_person } = useSelector((state) => state.personal);
  const { id_requestTypes, requestT } = useSelector(
    (state) => state.tipo_solicitud
  );
  const { id_identificationWell, identificationWell } = useSelector(
    (state) => state.identificacion_bien
  );

  // Datos back
  const [zonas, setZonas] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [upls, setUpls] = useState([]);
  const [upzs, setUpzs] = useState([]);
  const [barrios, setBarrios] = useState([]);

  const [tipoBien, setTipoBien] = useState(null);
  const [nombreBien, setNombreBien] = useState("");
  const [chip, setChip] = useState("");
  const [matricula, setMatricula] = useState("");
  const [direccionOficial, setDireccionOficial] = useState("");
  const [direccionSecundaria, setDireccionSecundaria] = useState("");
  const [direccionAnterior, setDireccionAnterior] = useState("");
  const [direccionDeclaratoria, setDireccionDeclaratoria] = useState("");
  const [zona, setZona] = useState(null);
  const [localidad, setLocalidad] = useState(null);
  const [upl, setUpl] = useState(null);
  const [upz, setUpz] = useState(null);
  const [barrio, setBarrio] = useState(null);
  const [vereda, setVereda] = useState(null);
  const [numeroManzana, setNumeroManzana] = useState(null);
  const [numeroLote, setNumeroLote] = useState(null);

  const navigate = useNavigate();

  const [enableNextButton, setEnbaleNextButton] = useState(
    id_identificationWell != 0 ? true : false
  );
  const [enableSaveButton, setEnbaleSaveButton] = useState(false);

  const [errors, setErrors] = useState({
    chip: "",
    matriculaInmoviliaria: "",
    zona: "",
    localidad: "",
    barrio: "",
    direccionOficial: "",
    vereda: "",
  });

  const handleDireccionOficialChange = (event) => {
    setEnbaleSaveButton(true);
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "bis") {
      value = event.target.checked === true ? "BIS" : "";
    }

    const newErrors = identificacionBienValidaciones("direccionOficial", {
      ...identificationWell?.direccion_oficialObj,
      [name]: value,
    });

    setErrors({
      ...errors,
      ...newErrors,
    });

    setDireccionOficial({
      ...direccionOficial,
      [name]: value,
    });

    dispatch(
      saveAddress({ name: "direccionOficial", data: { [name]: value } })
    );
  };

  const handleDireccionSecundariaChange = (event) => {
    setEnbaleSaveButton(true);
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "bis") {
      value = event.target.checked === true ? "BIS" : "";
    }

    setDireccionSecundaria({
      ...direccionSecundaria,
      [name]: value,
    });

    dispatch(
      saveAddress({ name: "direccionSecundaria", data: { [name]: value } })
    );
  };

  const handleDireccionAnteriorChange = (event) => {
    setEnbaleSaveButton(true);
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "bis") {
      value = event.target.checked === true ? "BIS" : "";
    }

    setDireccionAnterior({
      ...direccionAnterior,
      [name]: value,
    });

    dispatch(
      saveAddress({ name: "direccionAnterior", data: { [name]: value } })
    );
  };

  const handleDireccionDeclaratoriaChange = (event) => {
    setEnbaleSaveButton(true);
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "bis") {
      value = event.target.checked === true ? "BIS" : "";
    }

    setDireccionDeclaratoria({
      ...direccionDeclaratoria,
      [name]: value,
    });

    dispatch(
      saveAddress({ name: "direccionDeclaratoria", data: { [name]: value } })
    );
  };

  const onSubmit = async (event) => {
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (!hasErrors) {
      const data = {
        nombre_bien: identificationWell?.nombre_bien,
        chip: identificationWell?.chip,
        matricula_inmobiliaria: identificationWell?.matricula_inmobiliaria,
        direccion_oficial: identificationWell?.direccion_oficial,
        tipo_via_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.via
            : "",
        numero_via_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.numeroVia
            : null,
        letra_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.letra
            : "",
        bis_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.bis === "BIS"
              ? true
              : false
            : false,
        cuadrante_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.cuadrante
            : "",
        via_generadora_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.viaGeneradora
            : "",
        letra2_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.letraSecundaria
            : "",
        bis2_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.bisSecundario === "BIS"
              ? true
              : false
            : false,
        numero_dir_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.numeroViaSecundaria
            : null,
        cuadrante2_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.cuadranteSecundario
            : "",
        complemento_direccion_oficial:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_oficialObj.complemento
            : "",
        direccion_secundaria: identificationWell?.direccion_secundaria,
        tipo_via_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.via
            : "",
        numero_via_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.numeroVia
            : null,
        letra_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.letra
            : "",
        bis_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.bis === "BIS"
              ? true
              : false
            : false,
        cuadrante_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.cuadrante
            : "",
        via_generadora_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.viaGeneradora
            : "",
        letra2_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.letraSecundaria
            : "",
        bis2_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.bisSecundario ===
              "BIS"
              ? true
              : false
            : false,
        numero_dir_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.numeroViaSecundaria
            : null,
        cuadrante2_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.cuadranteSecundario
            : "",
        complemento_direccion_secundaria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_secundariaObj.complemento
            : "",
        direcciones_anteriores: identificationWell.direcciones_anteriores,
        tipo_via_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.via
            : "",
        numero_via_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.numeroVia
            : null,
        letra_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.letra
            : "",
        bis_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.bis === "BIS"
              ? true
              : false
            : false,
        cuadrante_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.cuadrante
            : "",
        via_generadora_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.viaGeneradora
            : "",
        letra2_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.letraSecundaria
            : "",
        bis2_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.bis === "BIS"
              ? true
              : false
            : false,
        numero_dir_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.numeroViaSecundaria
            : null,
        cuadrante2_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.cuadranteSecundario
            : "",
        complemento_direcciones_anteriores:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direcciones_anterioresObj.complemento
            : "",
        direccion_declaratoria: identificationWell?.direccion_declaratoria,
        tipo_via_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.via
            : "",
        numero_via_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.numeroVia
            : null,
        letra_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.letra
            : "",
        bis_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.bis === "BIS"
              ? true
              : false
            : false,
        cuadrante_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.cuadrante
            : "",
        via_generadora_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.viaGeneradora
            : "",
        letra2_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.letraSecundaria
            : "",
        bis2_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.bis === "BIS"
              ? true
              : false
            : false,
        numero_dir_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.numeroViaSecundaria
            : null,
        cuadrante2_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.cuadranteSecundario
            : "",
        complemento_direccion_declaratoria:
          (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
          identificationWell?.zona === 1
            ? identificationWell?.direccion_declaratoriaObj.complemento
            : "",
        zona: identificationWell?.zona,
        localidad: identificationWell?.localidad,
        upl: identificationWell?.upl,
        upz: identificationWell?.upz,
        barrio: identificationWell?.barrio,
        vereda: identificationWell?.vereda,
        numero_manzana: identificationWell?.numero_manzana,
        numero_lote: identificationWell?.numero_lote,
        id_solicitud: id_requestTypes,
      };

      try {
        if (id_identificationWell === 0 || undefined) {
          const response = await postIdentificacionBien(data);
          if ((response.data.status = status201ok)) {
            dispatch(
              getIdentificationWell({
                nombre_bien: identificationWell?.nombre_bien,
                chip: identificationWell?.chip,
                matricula_inmobiliaria:
                  identificationWell?.matricula_inmobiliaria,
                direccion_oficial: identificationWell?.direccion_oficial,
                direccion_oficialObj: identificationWell?.direccion_oficialObj,
                direccion_secundaria: identificationWell?.direccion_secundaria,
                direccion_secundariaObj:
                  identificationWell?.direccion_secundariaObj,
                direcciones_anteriores:
                  identificationWell?.direcciones_anteriores,
                direcciones_anterioresObj:
                  identificationWell?.direcciones_anterioresObj,
                direccion_declaratoria:
                  identificationWell?.direccion_declaratoria,
                direccion_declaratoriaObj:
                  identificationWell?.direccion_declaratoriaObj,
                zona: identificationWell?.zona,
                localidad: identificationWell?.localidad,
                upl: identificationWell?.upl,
                upz: identificationWell?.upz,
                barrio: identificationWell?.barrio,
                vereda: identificationWell?.vereda,
                numero_manzana: identificationWell?.numero_manzana,
                numero_lote: identificationWell?.numero_lote,
                id_solicitud: id_requestTypes,
              })
            );
            dispatch(getOneIdentificationWell(response.data.id_bien));
            constructAlert(
              "",
              "¡La identificación del bien ha sido guardada!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
            });
          }
        } else {
          const response = await putIdentificacionBien(
            id_identificationWell,
            data
          );
          if ((response.data.status = status201ok)) {
            dispatch(
              getIdentificationWell({
                nombre_bien: identificationWell?.nombre_bien,
                chip: identificationWell?.chip,
                matricula_inmobiliaria:
                  identificationWell?.matricula_inmobiliaria,
                direccion_oficial: identificationWell?.direccion_oficial,
                direccion_oficialObj: identificationWell?.direccion_oficialObj,
                direccion_secundaria: identificationWell?.direccion_secundaria,
                direccion_secundariaObj:
                  identificationWell?.direccion_secundariaObj,
                direcciones_anteriores:
                  identificationWell?.direcciones_anteriores,
                direcciones_anterioresObj:
                  identificationWell?.direcciones_anterioresObj,
                direccion_declaratoria:
                  identificationWell?.direccion_declaratoria,
                direccion_declaratoriaObj:
                  identificationWell?.direccion_declaratoriaObj,
                zona: identificationWell?.zona,
                localidad: identificationWell?.localidad,
                upl: identificationWell?.upl,
                upz: identificationWell?.upz,
                barrio: identificationWell?.barrio,
                vereda: identificationWell?.vereda,
                numero_manzana: identificationWell?.numero_manzana,
                numero_lote: identificationWell?.numero_lote,
                id_solicitud: id_requestTypes,
              })
            );
            constructAlert(
              "",
              "¡La identificación del bien ha sido actualizada!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
            });
          }
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Trámite BIC - Identificación del bien`;

    getZone().then((response) => {
      setZonas(response.data);
    });
    getLocal().then((response) => {
      setLocalidades(response.message);
    });
    getUPL().then((response) => {
      setUpls(response.data);
    });

    if (identificationWell?.localidad !== 0) {
      getUPZ(identificationWell?.localidad).then((response) => {
        setUpzs(response.message);
      });

      if (identificationWell?.upz !== null) {
        getBarrio(identificationWell?.upz).then((response) => {
          setBarrios(response.message);
        });
      }
    }

    if (requestT?.tipo_bien) {
      setTipoBien(requestT?.tipo_bien);
      if (requestT?.tipo_bien === 2) {
        setDireccionOficial({
          via: "",
          numeroVia: null,
          letra: "",
          bis: "",
          cuadrante: "",
          viaGeneradora: "",
          letraSecundaria: "",
          bisSecundario: "",
          numeroViaSecundaria: null,
          cuadranteSecundario: "",
          complemento: "",
        });
        setDireccionSecundaria({
          via: "",
          numeroVia: null,
          letra: "",
          bis: "",
          cuadrante: "",
          viaGeneradora: "",
          letraSecundaria: "",
          bisSecundario: "",
          numeroViaSecundaria: null,
          cuadranteSecundario: "",
          complemento: "",
        });
        setDireccionAnterior({
          via: "",
          numeroVia: null,
          letra: "",
          bis: "",
          cuadrante: "",
          viaGeneradora: "",
          letraSecundaria: "",
          bisSecundario: "",
          numeroViaSecundaria: null,
          cuadranteSecundario: "",
          complemento: "",
        });
        setDireccionDeclaratoria({
          via: "",
          numeroVia: null,
          letra: "",
          bis: "",
          cuadrante: "",
          viaGeneradora: "",
          letraSecundaria: "",
          bisSecundario: "",
          numeroViaSecundaria: null,
          cuadranteSecundario: "",
          complemento: "",
        });
      }
    }
  }, [, location.pathname]);

  const goBackTo = () => {
    constructTwoButtonsAlert(
      "",
      "¡Recuerda que si sales de la creación de solicitud sin guardar o sin completar todos los pasos, luego podrás continuar desde la sección “Consultar trámite”!",
      "info",
      "Continuar",
      "Cancelar"
    ).then((res) => {
      if (res.value === true) {
        persistor.purge();
        navigate("/");
        window.location.reload();
      }
    });
  };

  const formatAdresses = () => {
    if (
      (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
      identificationWell?.zona === 1
    ) {
      dispatch(setOficialAddress());
      dispatch(setSecondaryAddress());
      dispatch(setPreviousAddress());
      dispatch(setDeclaratoriaAddress());
    }
  };

  const validateForm = () => {
    const data = {};

    let newErrors = {};

    data.zona = identificationWell?.zona;
    data.localidad = identificationWell?.localidad;
    data.direccionOficial = identificationWell?.direccion_oficial;
    if (requestT?.tipo_bien === 2) data.chip = identificationWell?.chip;
    if (requestT?.tipo_bien === 2)
      data.matriculaInmoviliaria = identificationWell?.matricula_inmobiliaria;
    if (identificationWell?.zona === 1)
      data.barrio = identificationWell?.barrio;
    if (identificationWell?.zona === 2)
      data.vereda = identificationWell?.vereda;

    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] === null) {
        newErrors[key] = "Este campo es requerido.";
      } else if (
        data[key]?.tipo_via === "" &&
        data[key]?.numero_via === "" &&
        data[key]?.via_generadora === "" &&
        data[key]?.numero_dir === ""
      ) {
        newErrors[key] = "Este campo es requerido.";
      } else {
        newErrors[key] = "";
      }
    });

    setErrors(newErrors);
  };

  const resetErrors = () => {
    setErrors({
      chip: "",
      matriculaInmoviliaria: "",
      zona: "",
      localidad: "",
      barrio: "",
      direccionOficial: "",
      vereda: "",
    });
  };
  const handleTextFieldChange = (event) => {
    setEnbaleSaveButton(true);

    const { name, value } = event.target;
    const newErrors = identificacionBienValidaciones(
      name,
      value,
      requestT?.tipo_bien,
      zona
    );

    setErrors({
      ...errors,
      ...newErrors,
    });

    dispatch(changeTexIdent({ name, value }));
  };

  const validateOnchangeErrors = (event) => {
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    const newErrors = identificacionBienValidaciones(
      name,
      value,
      requestT?.tipo_bien,
      zona
    );

    setErrors({
      ...errors,
      ...newErrors,
    });
  };

  const handleOnBlurValidation = (name, value) => {
    setEnbaleSaveButton(true);

    const data = {};

    let newErrors = {};

    data.zona = identificationWell?.zona;
    data.localidad = identificationWell?.localidad;
    data.direccionOficial =
      (requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
      identificationWell?.zona === 1
        ? identificationWell?.direccion_oficialObj
        : identificationWell?.direccion_oficial;
    if (requestT?.tipo_bien === 2) data.chip = identificationWell?.chip;
    if (requestT?.tipo_bien === 2)
      data.matriculaInmoviliaria = identificationWell?.matricula_inmobiliaria;
    if (identificationWell?.zona === 1)
      data.barrio = identificationWell?.barrio;
    if (identificationWell?.zona === 2)
      data.vereda = identificationWell?.vereda;

    if (data[name] === "" || data[name] === null || data[name] === 0) {
      newErrors[name] = "Este campo es requerido.";
    } else if (
      data[name]?.tipo_via === "" &&
      data[name]?.numero_via === "" &&
      data[name]?.via_generadora === "" &&
      data[name]?.numero_dir === ""
    ) {
      newErrors[name] = "Este campo es requerido.";
    } else {
      newErrors[name] = "";
    }

    setErrors({ ...errors, ...newErrors });
  };

  return (
    <>
      <div>
        <nav
          style={{ "--bs-breadcrumb-divider": ">" }}
          aria-label="breadcrumb"
          className="mt-1"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item " aria-current="page">
              <a href="/">Inicio</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Identificación del bien
            </li>
          </ol>
        </nav>
      </div>
      <hr className="hrC"></hr>
      <h3 className="title-p mt-1 d-flex">Creación de solicitud - Paso 2</h3>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="header-style">
              <img
                src={goback}
                style={{ width: "106px" }}
                alt="icon"
                className="goback-style"
                onClick={goBackTo}
              />
              <h3>Identificación del bien</h3>
            </div>
            <p className="align-start">
              Ingrese la información solicitada sobre el bien cultural de la
              solicitud.
            </p>
            <p className="align-start">
              <b>NOTA: Todos los campos marcados con * son obligatorios</b>
            </p>
            <div className="align-start row form-group gov-co-form-group">
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label" htmlFor="nombreBien">
                  Nombre con el que se conoce el bien
                </label>
                <input
                  id="nombreBien"
                  name="nombreBien"
                  value={identificationWell?.nombre_bien}
                  onChange={handleTextFieldChange}
                  className="form-control"
                  placeholder={"Nombre con el que se conoce el bien"}
                  type="text"
                ></input>
              </div>
              {requestT?.tipo_bien === 1 ||
                (requestT?.tipo_bien === 2 && (
                  <>
                    <div className="col-md-6 w-100 mt-4">
                      <label className="form-label" htmlFor="chip">
                        {requestT?.tipo_bien === 2 ? "CHIP*" : "CHIP"}
                      </label>
                      <input
                        id="chip"
                        name="chip"
                        className="form-control"
                        value={identificationWell?.chip}
                        onChange={handleTextFieldChange}
                        placeholder={"CHIP"}
                        type="text"
                        onBlur={(event) =>
                          handleOnBlurValidation(
                            event.target.name,
                            event.target.value
                          )
                        }
                      ></input>
                      {errors && errors.chip ? (
                        <>
                          <small
                            className="form-text"
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.chip}
                          </small>
                        </>
                      ) : null}
                    </div>
                    <div className="col-md-6 w-100 mt-4">
                      <label
                        className="form-label"
                        htmlFor="matriculaInmoviliaria"
                      >
                        {requestT?.tipo_bien === 2
                          ? "Matricula inmobiliaria*"
                          : "Matricula inmobiliaria"}
                      </label>
                      <input
                        id="matriculaInmoviliaria"
                        name="matriculaInmoviliaria"
                        className="form-control"
                        placeholder={"Matricula inmobiliaria"}
                        type="text"
                        value={identificationWell?.matricula_inmobiliaria}
                        onChange={handleTextFieldChange}
                        onBlur={(event) =>
                          handleOnBlurValidation(
                            event.target.name,
                            event.target.value
                          )
                        }
                      ></input>
                      {errors && errors.matriculaInmoviliaria ? (
                        <>
                          <small
                            className="form-text"
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {errors.matriculaInmoviliaria}
                          </small>
                        </>
                      ) : null}
                    </div>
                  </>
                ))}
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label" htmlFor="zona">
                  Zona*
                </label>
                <SelectAsyncPaginate
                  options={zonas}
                  value={{
                    label:
                      identificationWell?.zona === null
                        ? "Seleccione"
                        : zonas.find((e) => e.id === identificationWell?.zona)
                            ?.zona,
                    value:
                      identificationWell?.zona === null
                        ? 0
                        : identificationWell?.zona,
                  }}
                  onChange={(value) => {
                    setZona(value);
                    dispatch(
                      changeTexIdent({ name: value.name, value: value.value })
                    );
                    validateOnchangeErrors(value);
                    setEnbaleSaveButton(true);
                    resetErrors();
                  }}
                  name={"zona"}
                  fieldName={"zona"}
                  displayName={"zona"}
                  displayValue={"id"}
                  onBlur={(name, value) => {
                    handleOnBlurValidation(name, value?.value);
                  }}
                />
                {errors && errors.zona ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {errors.zona}
                    </small>
                  </>
                ) : null}
              </div>
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label" htmlFor="localidad">
                  Localidad*
                </label>
                <SelectAsyncPaginate
                  options={localidades}
                  value={{
                    label:
                      identificationWell?.localidad === 0
                        ? "Seleccione"
                        : localidades.find(
                            (e) =>
                              parseInt(e.id) ===
                              parseInt(identificationWell?.localidad)
                          )?.descripcion,
                    value:
                      identificationWell?.localidad === 0
                        ? 0
                        : identificationWell?.localidad,
                  }}
                  onChange={async (value) => {
                    setLocalidad(value);
                    dispatch(
                      changeTexIdent({ name: value.name, value: value.value })
                    );
                    validateOnchangeErrors(value);
                    setEnbaleSaveButton(true);
                    try {
                      const response = await getUPZ(value.value);
                      if (response?.message) {
                        setUpzs(response.message);
                      }
                    } catch (error) {}
                  }}
                  name={"localidad"}
                  fieldName={"descripcion"}
                  displayName={"descripcion"}
                  displayValue={"id"}
                  onBlur={(name, value) => {
                    handleOnBlurValidation(name, value?.value);
                  }}
                />
                {errors && errors.localidad ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {errors.localidad}
                    </small>
                  </>
                ) : null}
              </div>
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label" htmlFor="upl">
                  UPL
                </label>
                <SelectAsyncPaginate
                  options={upls}
                  value={{
                    label:
                      identificationWell?.upl === null
                        ? "Seleccione"
                        : upls.find((e) => e.id === identificationWell?.upl)
                            ?.upl,
                    value:
                      identificationWell?.upl === null
                        ? 0
                        : identificationWell?.upl,
                  }}
                  onChange={(value) => {
                    setUpl(value);
                    dispatch(
                      changeTexIdent({ name: value.name, value: value.value })
                    );
                    validateOnchangeErrors(value);
                    setEnbaleSaveButton(true);
                  }}
                  name={"upl"}
                  fieldName={"upl"}
                  displayName={"upl"}
                  displayValue={"id"}
                />
              </div>
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label" htmlFor="upz">
                  UPZ
                </label>
                <SelectCustom
                  options={upzs}
                  value={{
                    label:
                      identificationWell?.upz === null
                        ? "Seleccione"
                        : upzs?.find(
                            (e) =>
                              parseInt(e.id) ===
                              parseInt(identificationWell?.upz)
                          )?.descripcion,
                    value:
                      identificationWell?.upz === null
                        ? 0
                        : identificationWell?.upz,
                  }}
                  onChange={async (value) => {
                    setUpz(value);
                    dispatch(
                      changeTexIdent({ name: value.name, value: value.value })
                    );
                    setEnbaleSaveButton(true);
                    validateOnchangeErrors(value);
                    try {
                      const response = await getBarrio(value.value);
                      if (response?.message) {
                        setBarrios(response.message);
                      }
                    } catch (error) {}
                  }}
                  name={"upz"}
                  fieldName={"descripcion"}
                  displayName={"descripcion"}
                  displayValue={"id"}
                  isDisabled={
                    identificationWell?.localidad === null ? true : false
                  }
                />
              </div>

              {identificationWell?.zona === 1 && (
                <>
                  <div className="col-md-6 w-100 mt-4">
                    <label>Barrio*</label>
                    <SelectCustom
                      options={barrios}
                      value={{
                        label:
                          identificationWell?.barrio === null
                            ? "Seleccione"
                            : barrios?.find(
                                (e) =>
                                  e.id.toString() ===
                                  identificationWell?.barrio.toString()
                              )?.descripcion,
                        value:
                          identificationWell?.barrio === null
                            ? 0
                            : identificationWell?.barrio,
                      }}
                      onChange={(value) => {
                        setBarrio(value);
                        dispatch(
                          changeTexIdent({
                            name: value.name,
                            value: value.value,
                          })
                        );
                        setEnbaleSaveButton(true);
                        validateOnchangeErrors(value);
                      }}
                      name={"barrio"}
                      fieldName={"descripcion"}
                      displayName={"descripcion"}
                      displayValue={"id"}
                      isDisabled={
                        identificationWell?.localidad === null ||
                        identificationWell?.upz === null
                          ? true
                          : false
                      }
                      onBlur={(name, value) => {
                        handleOnBlurValidation(name, value?.value);
                      }}
                    />
                    {errors && errors.barrio ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.barrio}
                        </small>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              {identificationWell?.zona === 2 && (
                <>
                  <div className="col-md-6 w-100 mt-4">
                    <label className="form-label" htmlFor="vereda">
                      Vereda*
                    </label>
                    <input
                      id="vereda"
                      name="vereda"
                      className="form-control"
                      placeholder={"Vereda"}
                      type="text"
                      onChange={handleTextFieldChange}
                      value={identificationWell?.vereda}
                      onBlur={(event) =>
                        handleOnBlurValidation(
                          event.target.name,
                          event.target.value
                        )
                      }
                    ></input>
                    {errors && errors.vereda ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.vereda}
                        </small>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              {identificationWell?.zona === 1 &&
                (requestT?.tipo_bien === 1 || requestT?.tipo_bien === 2) && (
                  <>
                    <div className="col-md-6 w-100 mt-4">
                      <label className="form-label" htmlFor="numeroManzana">
                        Número manzana
                      </label>
                      <input
                        id="numeroManzana"
                        name="numeroManzana"
                        className="form-control"
                        placeholder={"Número manzana"}
                        type="number"
                        min={"0"}
                        max={"999"}
                        maxLength={3}
                        onKeyDown={(e) => {
                          preventNegativeValues(e);
                          preventMoreThreeCharacters(e);
                        }}
                        onChange={handleTextFieldChange}
                        value={identificationWell?.numero_manzana}
                      ></input>
                    </div>

                    <div className="col-md-6 w-100 mt-4">
                      <label className="form-label" htmlFor="numeroLote">
                        Número lote
                      </label>
                      <input
                        id="numeroLote"
                        name="numeroLote"
                        className="form-control"
                        placeholder={"Número lote"}
                        type="number"
                        min={"0"}
                        max={"999"}
                        maxLength={3}
                        onKeyDown={(e) => {
                          preventNegativeValues(e);
                          preventMoreThreeCharacters(e);
                        }}
                        onChange={handleTextFieldChange}
                        value={identificationWell?.numero_lote}
                      ></input>
                    </div>
                  </>
                )}

              <div className="col-md-12 w-100 mt-4">
                {(requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
                identificationWell?.zona === 1 ? (
                  <>
                    <DireccionInput
                      fieldLabel={"Dirección oficial (actual) *"}
                      address={identificationWell?.direccion_oficialObj}
                      setAddress={setDireccionOficial}
                      handleAddressChange={handleDireccionOficialChange}
                      defaultValue={
                        (requestT?.tipo_bien === 2 ||
                          requestT?.tipo_bien === 1) &&
                        identificationWell?.zona === 1 &&
                        identificationWell?.direccion_oficialObj
                          ? Object.values(
                              identificationWell?.direccion_oficialObj
                            )
                              .join(" ")
                              .replace(/\s+/g, " ")
                          : identificationWell?.direccion_oficial
                      }
                    />
                    {errors && errors.direccionOficial && (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.direccionOficial}
                        </small>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <label className="form-label" htmlFor="direccionOficial">
                      Dirección oficial (actual) *
                    </label>
                    <input
                      id="direccionOficial"
                      name="direccionOficial"
                      className="form-control"
                      placeholder={"Dirección oficial"}
                      type="text"
                      onChange={handleTextFieldChange}
                      value={identificationWell?.direccion_oficial}
                      onBlur={(event) =>
                        handleOnBlurValidation(
                          event.target.name,
                          event.target.value
                        )
                      }
                    ></input>
                    {errors && errors.direccionOficial ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.direccionOficial}
                        </small>
                      </>
                    ) : null}
                  </>
                )}
              </div>

              <div className="col-md-12 w-100 mt-4">
                {(requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
                identificationWell?.zona === 1 ? (
                  <>
                    <DireccionInput
                      fieldLabel={"Dirección secundaria"}
                      address={identificationWell?.direccion_secundariaObj}
                      setAddress={setDireccionSecundaria}
                      handleAddressChange={handleDireccionSecundariaChange}
                      defaultValue={
                        (requestT?.tipo_bien === 2 ||
                          requestT?.tipo_bien === 1) &&
                        identificationWell?.zona === 1 &&
                        identificationWell?.direccion_secundariaObj
                          ? Object.values(
                              identificationWell?.direccion_secundariaObj
                            )
                              .join(" ")
                              .replace(/\s+/g, " ")
                          : identificationWell?.direccion_secundaria
                      }
                    />
                  </>
                ) : (
                  <>
                    <label className="form-label" htmlFor="direccionSecundaria">
                      Dirección secundaria
                    </label>
                    <input
                      id="direccionSecundaria"
                      name="direccionSecundaria"
                      className="form-control"
                      placeholder={"Dirección secundaria"}
                      type="text"
                      value={identificationWell?.direccion_secundaria}
                      onChange={handleTextFieldChange}
                    ></input>
                  </>
                )}
              </div>

              <div className="col-md-12 w-100 mt-4">
                {(requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
                identificationWell?.zona === 1 ? (
                  <>
                    <DireccionInput
                      fieldLabel={"Direcciones anteriores"}
                      address={identificationWell?.direcciones_anterioresObj}
                      setAddress={setDireccionAnterior}
                      handleAddressChange={handleDireccionAnteriorChange}
                      defaultValue={
                        (requestT?.tipo_bien === 2 ||
                          requestT?.tipo_bien === 1) &&
                        identificationWell?.zona === 1 &&
                        identificationWell?.direcciones_anterioresObj
                          ? Object.values(
                              identificationWell?.direcciones_anterioresObj
                            )
                              .join(" ")
                              .replace(/\s+/g, " ")
                          : identificationWell?.direcciones_anteriores
                      }
                    />
                  </>
                ) : (
                  <>
                    <label className="form-label" htmlFor="direccionAnterior">
                      Direcciones anteriores
                    </label>
                    <input
                      id="direccionAnterior"
                      name="direccionAnterior"
                      className="form-control"
                      placeholder={"Direcciones anteriores"}
                      type="text"
                      value={identificationWell?.direcciones_anteriores}
                      onChange={handleTextFieldChange}
                    ></input>
                  </>
                )}
              </div>

              <div className="col-md-12 w-100 mt-4">
                {(requestT?.tipo_bien === 2 || requestT?.tipo_bien === 1) &&
                identificationWell?.zona === 1 ? (
                  <>
                    <DireccionInput
                      fieldLabel={"Dirección de declaratoria"}
                      address={identificationWell?.direccion_declaratoriaObj}
                      setAddress={setDireccionDeclaratoria}
                      handleAddressChange={handleDireccionDeclaratoriaChange}
                      defaultValue={
                        (requestT?.tipo_bien === 2 ||
                          requestT?.tipo_bien === 1) &&
                        identificationWell?.zona === 1 &&
                        identificationWell?.direccion_declaratoriaObj
                          ? Object.values(
                              identificationWell?.direccion_declaratoriaObj
                            )
                              .join(" ")
                              .replace(/\s+/g, " ")
                          : identificationWell?.direccion_declaratotia
                      }
                    />
                  </>
                ) : (
                  <>
                    <label
                      className="form-label"
                      htmlFor="direccionDeclaratoria"
                    >
                      Dirección de declaratoria
                    </label>
                    <input
                      id="direccionDeclaratoria"
                      name="direccionDeclaratoria"
                      className="form-control"
                      placeholder={"Dirección de declaratoria"}
                      type="text"
                      value={identificationWell?.direccion_declaratoria}
                      onChange={handleTextFieldChange}
                    ></input>
                  </>
                )}
              </div>
            </div>
            <div className="mt-5 w-100 d-flex flex-row justify-content-center align-items-center">
              <ButtonGroup>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  onClick={onPrevious}
                  disabled={step === 0}
                >
                  Atrás
                </Button>
                <Button
                  style={{ backgroundColor: "#3366CC", width: "80px" }}
                  appearance="primary"
                  type="submit"
                  onClick={validateForm}
                  onMouseDown={formatAdresses}
                  disabled={!enableSaveButton}
                >
                  {id_identificationWell != 0 ? "Actualizar" : "Guardar"}
                </Button>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  disabled={!enableNextButton}
                  onClick={onNext}
                >
                  Siguiente
                </Button>
              </ButtonGroup>
            </div>
          </form>
        )}
      ></Form>
    </>
  );
}
