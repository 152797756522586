import { regexOnlyNumbers, regexAlphaNumeric } from "./regex";


export const consultarValidaciones = (name, value, formValues, errors, setErrors) => {

    switch (name) {
        case "documentNumber":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if (!regexAlphaNumeric.test(value)) setErrors({...errors, [name] : "El número de identificación debe ser alfanumérico o numérico"}) 
            else if ((formValues.identificationType === "4" || formValues.identificationType === "1" || formValues.legalType === "12482") && !regexOnlyNumbers.test(value)) {
                setErrors({...errors, [name] : "El número de identificación debe ser numérico."}) 
            }
            else if (formValues.legalType !== "12482" && value.length > 11) setErrors({...errors, [name] : "El número de identificación debe ser menor a 11 caracteres."}) 
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        default:
            break;
    }
}