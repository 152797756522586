import Swal from "sweetalert2";

const constructAlert = (title, text, icon, confirmButtonText) => {
    return Swal.fire({
        title: title,
        text: text,
        type: icon,
        icon: icon,
        confirmButtonText: confirmButtonText,
        confirmButtonColor: "#3366CC"
    });
}

export default constructAlert;