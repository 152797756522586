
export const identificacionBienValidaciones = (name, value, tipoBien, zona) => {
    
    const errors = {};

    switch (name) {
        case "chip":
            if (value === '' && (tipoBien && tipoBien === 2)) errors[name] = "Este campo es requerido.";
            else if (value.length > 15) errors[name] = "Debe tener máximo 15 carácteres.";
            else {
                errors[name] = "";
            }
            break;

        case "matriculaInmoviliaria":
            if (value === '' && (tipoBien && tipoBien === 2)) errors[name] = "Este campo es requerido." 
            else if (value.length > 15) errors[name] = "Debe tener máximo 15 carácteres." 
            else {
                errors[name] = "";
            }
            break;
        case "zona":
            if (value === '') errors[name] = "Este campo es requerido." 
            else {
                errors[name] = "";
            }
            break;
        case "localidad":
            if (value === '') errors[name] = "Este campo es requerido." 
            else {
                errors[name] = "";
            }
            break;
        case "barrio":
            if (value === '') errors[name] = "Este campo es requerido." 
            else {
                errors[name] = "";
            }
            break;
        case "direccionOficial":
            if (typeof value === "string" && value !== "") {
                errors[name] = ""; 
            } else if (value?.tipo_via !== '' && value?.numero_via !== '' && value?.via_generadora !== '' && value.numero_dir !== '') {
                errors[name] = ""; 
            } else {
                errors[name] = "Este campo es requerido."
            }
            break;
        case "vereda":
            if (value === '' && (zona && zona.value === 2)) errors[name] = "Este campo es requerido." 
            else {
                errors[name] = "";
            }
            break;
        default:
            break;

    
    }

    return errors;
}