import { useState, useEffect } from "react";
import { Form } from "react-final-form";
import SelectAsyncPaginate from "../../../../../shared/AsyncSelects/SelectAsyncPaginate";
import { getAtributosBien } from "../../../../../services/descripcionBien/getAtributos";
import { getCriteriosBien } from "../../../../../services/descripcionBien/getCriterios";
import {
  postDescripcionBien,
  putDescripcionBien,
} from "../../../../../services/descripcionBien/postDescripcionBien";
import { ButtonGroup, Button } from "rsuite";
import constructAlert from "../../../../../utils/sweetalert/constructAlert";
import "./styles.css";
import constructTwoButtonsAlert from "../../../../../utils/sweetalert/constructTwoButtonsAlert";
import { useLocation, useNavigate } from "react-router-dom";
import goback from "../../../../../assets/icons/go-back-2.png";
import { status201ok } from "../../../../../utils/helpers/validations/statusCode";
import { preventNegativeValues } from "../../../../../utils/helpers/others/others";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelect,
  changeTextDescription,
  getDescriptionGood,
  getOneDescriptionGood,
} from "../../../../../shared/slice/reducers/descriptionGood.reducers";
import { persistor } from "../../../../..";
import { formatDescriptionData } from "../../../../../utils/helpers/others/formatData";

export default function DescripcionBien({ step, onNext, onPrevious }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id_requestTypes, requestT } = useSelector(
    (state) => state.tipo_solicitud
  );
  const { id_descriptionGood, descriptionGood } = useSelector(
    (state) => state.desciption_bien
  );

  const [criterios, setCriterios] = useState([]);
  const [valores, setValores] = useState([]);

  const navigate = useNavigate();

  const [enableNextButton, setEnbaleNextButton] = useState(
    id_descriptionGood != 0 ? true : false
  );
  const [enableSaveButton, setEnbaleSaveButton] = useState(false);

  const [errors, setErrors] = useState({
    razonSolicitud: "",
    actoAdministrativo: "",
    criteriosValoracion: "",
    numeroPisos: "",
    usoOriginal: "",
    valoresBien: "",
  });

  //useEffect
  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Trámite BIC - Descipción del bien`;

    getAtributosBien().then((reponse) => {
      setValores(reponse.data);
    });

    getCriteriosBien().then((reponse) => {
      setCriterios(reponse.data);
    });
  }, [location.pathname]);

  const goBackTo = () => {
    constructTwoButtonsAlert(
      "",
      "¡Recuerda que si sales de la creación de solicitud sin guardar o sin completar todos los pasos, luego podrás continuar desde la sección “Consultar trámite”!",
      "info",
      "Continuar",
      "Cancelar"
    ).then((res) => {
      if (res.value === true) {
        persistor.purge();
        navigate("/");
        window.location.reload();
      }
    });
  };

  const validateForm = () => {
    setEnbaleSaveButton(true);
    const data = {};

    let newErrors = {};

    data.razonSolicitud = descriptionGood?.razon_principal_solicitud;

    if (requestT?.tipo_solcitud !== 1)
      data.actoAdministrativo = descriptionGood?.acto_adminitrativo_bien;
    if (requestT?.tipo_solcitud !== 2) {
      data.criteriosValoracion = descriptionGood?.criterios_de_valoracion;
      data.valoresBien = descriptionGood?.atributos_del_bien;
    }
    if (requestT?.tipo_bien === 2) {
      data.numeroPisos = descriptionGood?.pisos;
      data.usoOriginal = descriptionGood?.uso_original;
    }

    Object.keys(data).forEach((key) => {
      if (
        data[key] === "" ||
        data[key] === null ||
        data[key] === 0 ||
        data[key].length === 0
      ) {
        newErrors[key] = "Este campo es requerido.";
      } else {
        newErrors[key] = "";
      }
    });

    setErrors(newErrors);
  };

  const onSubmit = async () => {
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (!hasErrors) {
      const data = {};

      data.area_predio = descriptionGood?.area_predio || 0;
      data.area_construida = descriptionGood?.area_construida || 0;
      data.pisos = descriptionGood?.pisos || 0;
      data.uso_original = descriptionGood?.uso_original || "na";
      data.otro_uso = descriptionGood?.otro_uso;
      data.uso_actual = descriptionGood?.uso_actual;
      data.agno_construccion = descriptionGood?.agno_construccion || 0;
      data.nombre_arquitecto = descriptionGood?.nombre_arquitecto;
      data.licencia_contruccion = descriptionGood?.licencia_contruccion;
      data.otras_licencias = descriptionGood?.otras_licencias;
      data.acto_adminitrativo_bien = descriptionGood?.acto_adminitrativo_bien;
      data.razon_principal_solicitud =
        descriptionGood?.razon_principal_solicitud;
      data.criterios_de_valoracion = isArrayNumber(
        descriptionGood?.criterios_de_valoracion
      )
        ? descriptionGood?.criterios_de_valoracion
        : descriptionGood?.criterios_de_valoracion.map((e) =>
            parseInt(e.value)
          );
      data.atributos_del_bien = isArrayNumber(
        descriptionGood?.atributos_del_bien
      )
        ? descriptionGood?.atributos_del_bien
        : descriptionGood?.atributos_del_bien.map((e) => parseInt(e.value));
      data.id_solicitud = id_requestTypes;
      //console.log("Data", data);
      try {
        if (id_descriptionGood === 0 || undefined) {
          const response = await postDescripcionBien(data);
          if (response.data.id) {
            dispatch(getOneDescriptionGood(response.data.id));
            dispatch(getDescriptionGood(data));
            constructAlert(
              "",
              "¡La descripción del bien ha sido guardada!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
            });
          }
        } else {
          const response = await putDescripcionBien(id_descriptionGood, data);
          if (response.data.id) {
            dispatch(getDescriptionGood(data));
            constructAlert(
              "",
              "¡La descripción del bien ha sido actualizada!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
            });
          }
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  const handleTextFieldChange = (event) => {
    setEnbaleSaveButton(true);
    const { name, value } = event.target;
    handleOnChangeValidation(name, value);
    dispatch(changeTextDescription({ name, value }));
  };

  const isArrayNumber = (arr) => {
    return (
      Array.isArray(arr) &&
      arr.every((elemento) => typeof elemento === "number")
    );
  };

  const formatCriterios = (info) => {
    if (isArrayNumber(info) && info.length > 0) {
      const formatedData = info.map((id) => {
        const find = criterios.find(
          (criterio) => parseInt(criterio.id) === parseInt(id)
        );
        return {
          value: find?.id || "",
          label: find?.criterios_valoracion || "",
        };
      });
      return formatedData;
    } else {
      return info;
    }
  };

  const formatValores = (info) => {
    if (isArrayNumber(info) && info.length > 0) {
      return info.map((id) => {
        const find = valores.find((val) => parseInt(val.id) === parseInt(id));

        return {
          value: find?.id || "",
          label: find?.atributos_bien || "",
        };
      });
    } else {
      return info;
    }
  };

  const handleOnBlurValidation = (name, value) => {
    setEnbaleSaveButton(true);
    const data = {};

    let newErrors = {};

    data.razonSolicitud = descriptionGood?.razon_principal_solicitud;

    if (requestT?.tipo_solcitud !== 1)
      data.actoAdministrativo = descriptionGood?.acto_adminitrativo_bien;
    if (requestT?.tipo_solcitud !== 4) {
      data.criteriosValoracion = descriptionGood?.criterios_de_valoracion;
      data.valoresBien = descriptionGood?.atributos_del_bien;
    }
    if (requestT?.tipo_bien === 2) {
      data.numeroPisos = descriptionGood?.pisos;
      data.usoOriginal = descriptionGood?.uso_original;
    }

    if (
      data[name] === "" ||
      data[name] === null ||
      data[name] === 0 ||
      data[name].length === 0
    ) {
      newErrors[name] = "Este campo es requerido.";
    } else {
      newErrors[name] = "";
    }

    setErrors({ ...errors, ...newErrors });
  };

  const handleOnChangeValidation = (name, value) => {
    setEnbaleSaveButton(true);
    const data = {};

    let newErrors = {};

    if (name === "razonSolicitud") data.razonSolicitud = value;

    if (name === "actoAdministrativo" && requestT?.tipo_solcitud !== 1)
      data.actoAdministrativo = value;
    if (requestT?.tipo_solcitud !== 4) {
      if (name === "criteriosValoracion") data.criteriosValoracion = value;
      if (name === "valoresBien") data.valoresBien = value;
    }
    if (requestT?.tipo_bien === 2) {
      if (name === "numeroPisos") data.numeroPisos = value;
      if (name === "usoOriginal") data.usoOriginal = value;
    }

    if (
      data[name] === "" ||
      data[name] === null ||
      data[name] === 0 ||
      data[name]?.length === 0
    ) {
      newErrors[name] = "Este campo es requerido.";
    } else {
      newErrors[name] = "";
    }

    setErrors({ ...errors, ...newErrors });
  };

  return (
    <>
      <div>
        <nav
          style={{ "--bs-breadcrumb-divider": ">" }}
          aria-label="breadcrumb"
          className="mt-1"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item " aria-current="page">
              <a href="/">Inicio</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Descripción del bien
            </li>
          </ol>
        </nav>
      </div>
      <hr className="hrC"></hr>
      <h3 className="title-p mt-1 d-flex">Descripción del bien - Paso 3</h3>
      <div className="header-style">
        <img
          src={goback}
          style={{ width: "106px" }}
          alt="icon"
          className="goback-style"
          onClick={goBackTo}
        />
        <h3>Descripción del bien</h3>
      </div>
      <p className="align-start">Realice la descripción del bien</p>
      <p className="align-start">
        <b>NOTA: Todos los campos marcados con * son obligatorios</b>
      </p>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="align-start row form-group gov-co-form-group">
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="razonSolicitud">
                    Indique aquí la razón principal de la solicitud*
                  </label>
                </div>
                <input
                  id="razonSolicitud"
                  name="razonSolicitud"
                  className="form-control"
                  placeholder={
                    "Indique aquí la razón principal de la solicitud"
                  }
                  type="text"
                  value={descriptionGood?.razon_principal_solicitud}
                  onChange={handleTextFieldChange}
                  onBlur={(event) =>
                    handleOnBlurValidation(
                      event.target.name,
                      event.target.value
                    )
                  }
                ></input>
                {errors && errors.razonSolicitud ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {errors.razonSolicitud}
                    </small>
                  </>
                ) : null}
              </div>
              {requestT?.tipo_solcitud !== 1 && (
                <>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label
                        className="form-label"
                        htmlFor="actoAdministrativo"
                      >
                        Acto administrativo por el cual se declaró el bien*
                      </label>
                    </div>
                    <input
                      id="actoAdministrativo"
                      name="actoAdministrativo"
                      className="form-control"
                      placeholder={"Acto administrativo"}
                      type="text"
                      value={descriptionGood?.acto_adminitrativo_bien}
                      onChange={handleTextFieldChange}
                      onBlur={(event) =>
                        handleOnBlurValidation(
                          event.target.name,
                          event.target.value
                        )
                      }
                    ></input>
                    {errors && errors.actoAdministrativo ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.actoAdministrativo}
                        </small>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              {requestT?.tipo_solcitud !== 2 && (
                <>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="criterios">
                        Seleccione los criterios de valoración que considere
                        permiten dar valor al bien* (Puede elegir varias
                        opciones)
                      </label>
                    </div>
                    <SelectAsyncPaginate
                      options={criterios}
                      value={
                        criterios.length > 0
                          ? formatCriterios(
                              descriptionGood?.criterios_de_valoracion
                            )
                          : []
                      }
                      onChange={(value) => {
                        handleOnChangeValidation("criteriosValoracion", value);
                        dispatch(
                          changeSelect({ name: "criterios", data: value })
                        );
                      }}
                      name={"criteriosValoracion"}
                      fieldName={"criterios_valoracion"}
                      displayName={"criterios_valoracion"}
                      displayValue={"id"}
                      multiple={true}
                      onBlur={(name, value) => {
                        handleOnBlurValidation(name, "");
                      }}
                    />
                    {errors && errors.criteriosValoracion ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.criteriosValoracion}
                        </small>
                      </>
                    ) : null}
                  </div>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="valores">
                        Seleccione los valores que considere son atributos al
                        bien* (Puede elegir varias opciones)
                      </label>
                    </div>
                    <SelectAsyncPaginate
                      options={valores}
                      value={
                        valores.length > 0
                          ? formatValores(descriptionGood?.atributos_del_bien)
                          : []
                      }
                      onChange={(value) => {
                        handleOnChangeValidation("valoresBien", value);
                        dispatch(
                          changeSelect({ name: "valores", data: value })
                        );
                      }}
                      name={"valoresBien"}
                      fieldName={"atributos_bien"}
                      displayName={"atributos_bien"}
                      displayValue={"id"}
                      multiple={true}
                      onBlur={(name, value) => {
                        handleOnBlurValidation(name, "");
                      }}
                    />
                    {errors && errors.valoresBien ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.valoresBien}
                        </small>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              {requestT?.tipo_bien === 2 && (
                <>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="area">
                        Área del predio construida en metros cuadrados
                      </label>
                    </div>
                    <input
                      id="areaPredio"
                      name="areaPredio"
                      className="form-control"
                      placeholder={
                        "Área del predio construida en metros cuadrados"
                      }
                      type="number"
                      min={"0"}
                      value={descriptionGood?.area_predio}
                      onChange={handleTextFieldChange}
                    ></input>
                  </div>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="areaConstruida">
                        Área construida en metros cuadrados
                      </label>
                    </div>
                    <input
                      id="areaConstruida"
                      name="areaConstruida"
                      className="form-control"
                      placeholder={
                        "Área del predio construida en metros cuadrados"
                      }
                      type="number"
                      min={"0"}
                      value={descriptionGood?.area_construida}
                      onChange={handleTextFieldChange}
                    ></input>
                  </div>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="numeroPisos">
                        Número de pisos*
                      </label>
                    </div>
                    <input
                      id="numeroPisos"
                      name="numeroPisos"
                      className="form-control"
                      placeholder={"Número de pisos"}
                      type="number"
                      min={"0"}
                      value={descriptionGood?.pisos}
                      onChange={handleTextFieldChange}
                      onBlur={(event) =>
                        handleOnBlurValidation(
                          event.target.name,
                          event.target.value
                        )
                      }
                    ></input>
                    {errors && errors.numeroPisos ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.numeroPisos}
                        </small>
                      </>
                    ) : null}
                  </div>
                  <div className="col-md-6 w-100 mt-4">
                    <div className="label-style">
                      <label className="form-label" htmlFor="usoOriginal">
                        Uso original*
                      </label>
                    </div>
                    <input
                      id="usoOriginal"
                      name="usoOriginal"
                      className="form-control"
                      placeholder={"Uso original"}
                      type="text"
                      value={descriptionGood?.uso_original}
                      onChange={handleTextFieldChange}
                      onBlur={(event) =>
                        handleOnBlurValidation(
                          event.target.name,
                          event.target.value
                        )
                      }
                    ></input>
                    {errors && errors.usoOriginal ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {errors.usoOriginal}
                        </small>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="usoActual">
                    Uso actual
                  </label>
                </div>
                <input
                  id="usoActual"
                  name="usoActual"
                  className="form-control"
                  placeholder={"Uso actual"}
                  value={descriptionGood?.uso_actual}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="otroUso">
                    Otro uso
                  </label>
                </div>
                <input
                  id="otroUso"
                  name="otroUso"
                  className="form-control"
                  placeholder={"Otro uso"}
                  type="text"
                  value={descriptionGood?.otro_uso}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="añoConstruccion">
                    Año de construcción
                  </label>
                </div>
                <input
                  id="añoConstruccion"
                  name="añoConstruccion"
                  className="form-control"
                  placeholder={"Año de construcción"}
                  type="number"
                  min={"0"}
                  step="1"
                  onKeyDown={(e) => {
                    preventNegativeValues(e);
                  }}
                  value={descriptionGood?.agno_construccion}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="arquitecto">
                    Arquitecto del diseño o construcción original
                  </label>
                </div>
                <input
                  id="arquitecto"
                  name="arquitecto"
                  className="form-control"
                  placeholder={"Arquitecto del diseño o construcción original"}
                  type="text"
                  value={descriptionGood?.nombre_arquitecto}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="licenciaOriginal">
                    Licencia de construcción original
                  </label>
                </div>
                <input
                  id="licenciaOriginal"
                  name="licenciaOriginal"
                  className="form-control"
                  placeholder={"Licencia de construcción original"}
                  type="text"
                  value={descriptionGood?.licencia_contruccion}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
              <div className="col-md-6 w-100 mt-4">
                <div className="label-style">
                  <label className="form-label" htmlFor="otrasLicencias">
                    Otras licencias que haya tenido el inmueble (autorizaciones
                    o permisos para intervención)
                  </label>
                </div>
                <input
                  id="otrasLicencias"
                  name="otrasLicencias"
                  className="form-control"
                  placeholder={"Otras licencias que haya tenido el inmueble"}
                  type="text"
                  value={descriptionGood?.otras_licencias}
                  onChange={handleTextFieldChange}
                ></input>
              </div>
            </div>
            <div className="mt-5 w-100 d-flex flex-row justify-content-center align-items-center">
              <ButtonGroup>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  onClick={onPrevious}
                  disabled={step === 0}
                >
                  Atrás
                </Button>
                <Button
                  style={{ backgroundColor: "#3366CC", width: "80px" }}
                  appearance="primary"
                  type="submit"
                  onClick={validateForm}
                  disabled={!enableSaveButton}
                >
                  {id_descriptionGood !== 0 ? "Actualizar" : "Guardar"}
                </Button>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  disabled={!enableNextButton}
                  onClick={onNext}
                >
                  Siguiente
                </Button>
              </ButtonGroup>
            </div>
          </form>
        )}
      ></Form>
    </>
  );
}
