// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.consultar-container {
    padding: 2% 0;
    width: 100%;
} 

.align-start {
    text-align: start;
}

.link-style {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/consultar/components/ConsultarTramite/ConsultarTramite.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.consultar-container {\n    padding: 2% 0;\n    width: 100%;\n} \n\n.align-start {\n    text-align: start;\n}\n\n.link-style {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
