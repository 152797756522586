import { getYearsDifference, isValidEmail } from "./validatorsHelpers";
import { regexOnlyNumbers, regexAlphaNumeric } from "./regex";

export const datosPersonlaesValidaciones = (name, value, formValues, errors, setErrors) => {

    switch (name) {
        case "typePerson":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido." })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "companyName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "identificationNumber":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if (!regexAlphaNumeric.test(value)) setErrors({...errors, [name] : "El número de identificación debe ser alfanumérico o numérico"}) 
            else if ((formValues.identificationType === "4" || formValues.identificationType === "1" || formValues.legalType === "12482") && !regexOnlyNumbers.test(value)) {
                setErrors({...errors, [name] : "El número de identificación debe ser numérico."}) 
            }
            else if (formValues.legalType !== "12482" && value.length > 11) setErrors({...errors, [name] : "El número de identificación debe ser menor a 11 caracteres."}) 
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "legalType":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "identificationType":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "validateIdentificationNumber":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if (value !== formValues.identificationNumber) setErrors({...errors, [name] : "La validación del número de documento no coincide"})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "issueDate":

            let difference = Math.abs(getYearsDifference(value, formValues.birthDate));
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if (difference < 18) setErrors({...errors, [name] : "La fecha de expedición de la cédula debe ser mayor que la fecha de nacimiento mínino 18 años."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "firstName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "lastName":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "identificationLegalType":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "identificationLegalNumber":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if ((formValues.identificationType === "4" || formValues.identificationType === "1") && !regexOnlyNumbers.test(value)) {
                setErrors({...errors, [name] : "El número de identificación debe ser numérico."}) 
            }
            else if (value.length > 11) setErrors({...errors, [name] : "El número de identificación debe ser menor a 11 caracteres."}) 
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "email":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else if (!isValidEmail(value)) setErrors({...errors, [name] : "Correo electrónico no válido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "contactNumber1":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "gender":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "checkPrivacy":
            if (value === false) setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "checkTerms":
            if (value === false) setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "scrdAutorization":
            if (value === false) setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "birthDate":
            if (value === "") setErrors({...errors, [name] : "Este campo es requerido."})
            else {
                setErrors({...errors, [name]: ""}); 
            }
            break;
        case "address":
            if (value?.via !== '' && value?.numeroVia !== '' && value?.viaGeneradora !== '' && value?.numeroViaSecundaria !== '') {
                setErrors({...errors, [name]: ""}); 
            } else {
                setErrors({...errors, [name] : "Este campo es requerido."})
            }
            break;
        default:
            break;
    }
}