import "./styles.css";
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import { useState } from "react";
import { useSelector } from "react-redux";


const formatDate = (string) => {
    const newDate = new Date(string);
    return newDate.toLocaleDateString('es-CO');
} 

const InfoDialog = () => {

    const { id_formated, fecha_solicitud } = useSelector((state) => state.tipo_solicitud);
    const { data_person } = useSelector((state) => state.personal);
    
    const [isHoverActive, setIsHoverActive] = useState(false);

    return (
        <div>  
            {isHoverActive === false 
                ? 
                    <div className="modal-box icon-only" onMouseEnter={() => setIsHoverActive(true)} >
                        <InfoRoundIcon color="white" style={{width: '24px', height: '24px'}} />
                    </div>
                :   
                    <div onMouseLeave={() => setIsHoverActive(false)}>
                        <div 
                            className="modal-box" 
                            type="button" 

                        >
                            <InfoRoundIcon color="white" style={{width: '24px', height: '24px'}} />
                            <p>Información solicitud</p>
                        </div>
                        
                         
                        <div className="information-box">
                            <div className="row">
                                <span className="col-md-6 title-color">Nombre solicitante: </span>
                                <span className="col-md-6">{data_person?.primer_nombre}</span>
                            </div>
                            <div className="mt-2 row">
                                <span className="col-md-6 title-color">Número de identificación: </span>
                                <span className="col-md-6">{data_person?.numero}</span>
                            </div>
                            <div className="mt-2 row">
                                <span className="col-md-6 title-color"># Tipo de Solicitud: </span>
                                <span className="col-md-6">{id_formated}</span>
                            </div>
                            <div className="mt-2 row">
                                <span className="col-md-6 title-color">Fecha de la Solicitud: </span>
                                <span className="col-md-6">{formatDate(fecha_solicitud)}</span>
                            </div>
                        </div>
                        
                    </div>
            }


        </div>
    )
}

export default InfoDialog;