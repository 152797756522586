import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import DireccionInput from "../../../../../shared/components/DireccionInput";
import {
  getActingInterest,
  getCurrentQuality,
  getRequestType,
  getTypeGood,
  getTypePeople,
  postRequestType,
  putRequestType,
} from "../../../../../services/requestType/requestType";
import SelectAsyncPaginate from "../../../../../shared/AsyncSelects/SelectAsyncPaginate";
import { ButtonGroup, Button } from "rsuite";
import constructAlert from "../../../../../utils/sweetalert/constructAlert";
import { status201ok } from "../../../../../utils/helpers/validations/statusCode";
import { tipoSolicitudValidaciones } from "../../../../../utils/helpers/validations/tipoSolicitudValidacion";
import goback from "../../../../../assets/icons/go-back-2.png";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
import constructTwoButtonsAlert from "../../../../../utils/sweetalert/constructTwoButtonsAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  changeText,
  getOneRequestTypes,
  getRequestTypes,
  getTypeBien,
  getTypeSolici,
  setLastNamePro,
  setLastPro,
  setNameLastPro,
  setNamePro,
  setRazonSocialPro,
  setTypeNumPro,
  changeMultipleNatural,
  addMultipleNatural,
  removeMultipleNatural,
  changeMultipleJuridica,
  addMultipleJuridica,
  removeMultipleJuridica,
  setDirecPro,
  addOneMultipleNatural,
  addOneMultipleJuridico,
  setUnicoPropi,
  setConocePro,
  setFechaSolicitud,
  setHoraSolicitud,
  setIdSolicitudFormated
} from "../../../../../shared/slice/reducers/requestTypes.reducers";
import { persistor } from "../../../../..";
/***Import */
import SelectCustom from "../../../../../shared/AsyncSelects/SelectCustom";
import { Table, Input, SelectPicker, IconButton } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PlusIcon from "@rsuite/icons/Plus";
import {
  formatMultiJuridico,
  formatMultiNatural,
} from "../../../../../utils/helpers/others/formatData";
const { Column, HeaderCell, Cell } = Table;

const EditableCell = ({
  rowData,
  dataKey,
  onChange,
  value,
  placeholder,
  ...props
}) => {
  return (
    <Cell {...props}>
      <Input
        placeholder={placeholder}
        value={rowData[dataKey]}
        onChange={(e) => {
          onChange(rowData, e, dataKey);
        }}
      />
    </Cell>
  );
};

const SelectCell = ({ rowData, dataKey, onChange, value, ...props }) => (
  <Cell {...props}>
    <SelectPicker
      data={[
        { label: "Si", value: 1 },
        { label: "No", value: 2 },
      ]}
      style={{ width: 100 }}
      placeholder={"Seleccione"}
      value={rowData[dataKey]}
      onChange={(e) => {
        onChange(rowData, e, dataKey);
      }}
      searchable={false}
    />
  </Cell>
);

const TipoSolicitud = ({ step, onNext, onPrevious }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id_person, data_person } = useSelector((state) => state.personal);
  const { requestT, id_requestTypes } = useSelector(
    (state) => state.tipo_solicitud
  );
  const [requestTypes, setRequestTypes] = useState([]);
  const [typesGood, setTypesGood] = useState([]);
  const [currentQualities, setCurrentQualities] = useState([]);
  const [typesPeople, setTypesPeople] = useState([]);
  const [actingInteOptions, setActingInteOptions] = useState([]);
  const [direccionOficial, setDireccionOficial] = useState({
    via: "",
    numeroVia: "",
    letra: "",
    bis: "",
    cuadrante: "",
    viaGeneradora: "",
    letraSecundaria: "",
    bissSecundario: "",
    numeroViaSecundaria: "",
    cuadranteSecundario: "",
    complemento: "",
  });
  const direcc = {
    via: data_person?.tipo_via || "",
    numeroVia: data_person?.numero_via || "",
    letra: data_person?.letra || "",
    bis: data_person?.bis ? "BIS" : "",
    cuadrante: data_person?.cuadrante || "",
    viaGeneradora: data_person?.via_generadora || "",
    letraSecundaria: data_person?.letra2 || "",
    bissSecundario: data_person?.bis2 ? "BIS" : "",
    numeroViaSecundaria: data_person?.numero_dir || "",
    cuadranteSecundario: data_person?.cuadrante2 || "",
    complemento: data_person?.complemento || "",
  };
  const [requestType, setRequestType] = useState("");
  const [generalErrors, setGeneralErrors] = useState({
    requestType: "",
    typeBien: "",
    typeCalidad: "",
    actingType: "",
    typePerson: "",
    firstName: "",
    firstLastName: "",
    businessName: "",
  });

  const [unicoPropietarioError, setUnicoPropietarioError] = useState({
    unicoPropietario: "",
  });
  const [conocePropietarioError, setConocePropietarioError] = useState({
    conocePropietario: "",
  });
  const [disableInput, setDisabletInput] = useState(false);
  const [enableNextButton, setEnbaleNextButton] = useState(
    id_requestTypes != 0 ? true : false
  );
  const [enableSaveButton, setEnbaleSaveButton] = useState(false);
  const navigate = useNavigate();

  const handleSaveMultiNatural = (rowData, value, dataKey) => {
    dispatch(changeMultipleNatural({ rowData, value, dataKey }));
  };

  const handleAddMultiNatural = () => {
    dispatch(addMultipleNatural());
  };

  const handleRemoveMultiNatural = (rowData) => {
    dispatch(removeMultipleNatural({ rowData }));
  };

  const handleSaveMultiJuridica = (rowData, value, dataKey) => {
    dispatch(changeMultipleJuridica({ rowData, value, dataKey }));
  };

  const handleAddMultiJuridica = () => {
    dispatch(addMultipleJuridica());
  };

  const handleRemoveMultiJuridica = (rowData) => {
    dispatch(removeMultipleJuridica({ rowData }));
  };

  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Trámite BIC - Tipo Solicitud`;

    getRequestType().then((response) => {
      setRequestTypes(response);
    });
    getTypeGood().then((response) => {
      setTypesGood(response);
    });
    getCurrentQuality().then((response) => {
      setCurrentQualities(response);
    });
    getTypePeople().then((response) => {
      setTypesPeople(response);
    });
    getActingInterest().then((response) => {
      setActingInteOptions(response);
    });
    dispatch(getTypeSolici(requestType?.tipo_solcitud));
  }, [requestType, location.pathname]);

  function handleOnChange(event) {
    setEnbaleSaveButton(true);

    console.log(event.name)
    if(event.name === "typeCalidad" || event.name === "typeBien"){
      console.log("Calidad que actua")
      dispatch(setUnicoPropi(0));
      dispatch(setConocePro(0));

    }
    let name;
    let value;
    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (
      (name === "typeCalidad" && value === 1) ||
      (name === "unicoPropietario" && value === "1" ? (requestT?.calidad_actual === 1, dispatch(setDirecPro(direcc), setDisabletInput(true))): setDisabletInput(false))
    ) {
      if (data_person?.tipo_persona === 1) {
        const newData = [
          {
            id: 1,
            nombres_natural: data_person?.primer_nombre,
            apellidos_natural: data_person?.primer_apellido,
            direccion_natural: data_person?.direccion,
          },
        ];

        dispatch(addOneMultipleNatural(newData));
        dispatch(setNamePro(data_person?.primer_nombre));
        dispatch(setNameLastPro(data_person?.segundo_nombre));
        dispatch(setLastPro(data_person?.primer_apellido));
        dispatch(setLastNamePro(data_person?.segundo_apellido));
        dispatch(setTypeNumPro(data_person?.tipo_documento));
      } else if (data_person?.tipo_persona === 2) {
        const newData2 = [
          {
            id: 1,
            razon_social_juridico: data_person?.razon_social,
            direccion_juridico: "",
            conoce_propietario_juridico: "",
          },
        ];
        dispatch(addOneMultipleJuridico(newData2));
        dispatch(setRazonSocialPro(data_person?.razon_social));
        dispatch(setTypeNumPro(2));
      }
    }
    if(name === "typeCalidad" && value !== 1){
      dispatch(setNamePro(""));
      dispatch(setNameLastPro(""));
      dispatch(setLastPro(""));
      dispatch(setLastNamePro(""));
      dispatch(setRazonSocialPro(""));
      dispatch(setDirecPro(direccionOficial))
    }

    tipoSolicitudValidaciones(name, value, generalErrors, setGeneralErrors);

    if (requestT?.calidad_actual !== 1) {
      tipoSolicitudValidaciones(
        name,
        value,
        conocePropietarioError,
        setConocePropietarioError
      );
    } else {
      tipoSolicitudValidaciones(
        name,
        value,
        unicoPropietarioError,
        setUnicoPropietarioError
      );
    }
  }

  const handleAddressChange = (event) => {
    setEnbaleSaveButton(true);
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    if (name === "bis") {
      value = event.target.checked;
    }
    tipoSolicitudValidaciones(
      "address",
      { ...requestT?.direccion_propietario, [name]: value },
      generalErrors,
      setGeneralErrors
    );

    dispatch(
      changeText({ name: "address", value: { name: name, value: value } })
    );
  };
  const onSubmit = async (values) => {
    const hasGeneralErrors = Object.values(generalErrors).some(
      (error) => error !== ""
    );

    let hasUnicoPropietarioError = false;
    let hasConocePropietarioError = false;

    if (
      (requestT?.tipo_bien === 1 || requestT?.tipo_bien === 2) &&
      requestT?.calidad_actual === 1
    ) {
      hasUnicoPropietarioError = Object.values(unicoPropietarioError).some(
        (error) => error !== ""
      );
    } else if (
      conocePropietarioError &&
      conocePropietarioError.conocePropietario &&
      requestT?.calidad_actual !== 1
    ) {
      hasConocePropietarioError = Object.values(unicoPropietarioError).some(
        (error) => error !== ""
      );
    }
    if (
      !hasGeneralErrors &&
      !hasUnicoPropietarioError &&
      !hasConocePropietarioError
    ) {
      let data = {
        nombre_propietario: requestT?.nombre_propietario || "",
        segundo_nombre_propietario: requestT?.segundo_nombre_propietario || "",
        apellido_propietario: requestT?.apellido_propietario || "",
        segundo_apellido_propietario:
          requestT?.segundo_apellido_propietario || "",
        direccion_propietario: Object.values(requestT?.direccion_propietario)
          .join(" ")
          .replace(/\s+/g, " "),
        tipo_via: requestT?.direccion_propietario.tipo_via || direcc?.via,
        numero_via:
          requestT?.direccion_propietario.numero_via || direcc?.numeroVia,
        letra: requestT?.direccion_propietario.letra || direcc?.letra,
        bis: requestT?.direccion_propietario.bis === "BIS" ? true : false,
        cuadrante:
          requestT?.direccion_propietario.cuadrante || direcc?.cuadrante,
        via_generadora:
          requestT?.direccion_propietario.via_generadora ||
          direcc?.viaGeneradora,
        letra2:
          requestT?.direccion_propietario.letra2 || direcc?.letraSecundaria,
        numero_dir:
          requestT?.direccion_propietario.numero_dir || direcc?.numeroVia,
        cuadrante2:
          requestT?.direccion_propietario.cuadrante2 ||
          direcc?.cuadranteSecundario,
        tipo_solcitud: requestT?.tipo_solcitud,
        tipo_bien: requestT?.tipo_bien,
        calidad_actual: requestT?.calidad_actual,
        unico_propietario: requestT?.unico_propietario || 2,
        tipo_persona_propietario: requestT?.tipo_persona_propietario,
        razon_social:
          requestT?.tipo_persona_propietario === 1
            ? "NA"
            : requestT?.razon_social,
        interes: requestT?.interes,
        conoce_el_propietario:
          requestT?.calidad_actual !== 1
            ? requestT?.conoce_el_propietario !== 0
              ? requestT?.conoce_el_propietario
              : 2
            : 2,
        id_persona: id_person,
        otros_propietarios_natural:
          requestT?.unico_propietario === 2 &&
          requestT?.tipo_persona_propietario === 1
            ? id_requestTypes !== 0
              ? requestT?.otros_propietarios_natural
              : formatMultiNatural(requestT?.otros_propietarios_natural)
            : null,
        otros_propietarios_juridico:
          requestT?.unico_propietario === 2 &&
          requestT?.tipo_persona_propietario === 2
            ? id_requestTypes !== 0
              ? requestT?.otros_propietarios_juridico
              : formatMultiJuridico(requestT?.otros_propietarios_juridico)
            : null,
      };
      try {
        if (id_requestTypes === 0 || undefined) {
          console.log(data);
          console.log(direcc);
          const response = await postRequestType(data);
          if ((response.data.status = status201ok)) {
            dispatch(
              getRequestTypes({
                nombre_propietario: requestT?.nombre_propietario || "",
                segundo_nombre_propietario:
                  requestT?.segundo_nombre_propietario || "",
                apellido_propietario: requestT?.apellido_propietario || "",
                segundo_apellido_propietario:
                  requestT?.segundo_apellido_propietario,
                direccion_propietario: {
                  tipo_via:
                    requestT?.direccion_propietario.tipo_via || direcc?.via,
                  numero_via:
                    requestT?.direccion_propietario.numero_via ||
                    direcc?.numeroVia,
                  letra: requestT?.direccion_propietario.letra || direcc?.letra,
                  bis:
                    requestT?.direccion_propietario.bis === true ? "BIS" : "",
                  cuadrante:
                    requestT?.direccion_propietario.cuadrante ||
                    direcc?.cuadrante,
                  via_generadora:
                    requestT?.direccion_propietario.via_generadora ||
                    direcc?.viaGeneradora,
                  letra2:
                    requestT?.direccion_propietario.letra2 ||
                    direcc?.letraSecundaria,
                  numero_dir:
                    requestT?.direccion_propietario.numero_dir ||
                    direcc?.numeroVia,
                  cuadrante2:
                    requestT?.direccion_propietario.cuadrante2 ||
                    direcc?.cuadranteSecundario,
                },
                tipo_solcitud: requestT?.tipo_solcitud,
                tipo_bien: requestT?.tipo_bien,
                calidad_actual: requestT?.calidad_actual,
                unico_propietario: requestT?.unico_propietario,
                tipo_persona_propietario: requestT?.tipo_persona_propietario,
                razon_social:
                  requestT?.tipo_persona_propietario === 1
                    ? "NA"
                    : requestT?.razon_social,
                interes: requestT?.interes,
                conoce_el_propietario:
                  requestT?.calidad_actual !== 1
                    ? requestT?.conoce_el_propietario
                    : 2,
                id_persona: id_person,
                otros_propietarios_natural:
                  response.data.otros_propietarios_natural,
                otros_propietarios_juridico:
                  response.data.otros_propietarios_juridico,
              })
            );
            dispatch(getOneRequestTypes(response.data.id));
            dispatch(setFechaSolicitud(response.data?.fecha_solicitud));
            dispatch(setFechaSolicitud(response.data?.fecha));
            dispatch(setHoraSolicitud(response.data?.hora));
            dispatch(setIdSolicitudFormated(String(response.data.id).padStart(4, '0')));
            constructAlert(
              "",
              "¡El tipo de solicitud ha sido guardado!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
              localStorage.setItem(
                "tipoSolicitud",
                JSON.stringify(requestT?.tipo_solcitud)
              );
              localStorage.setItem(
                "tipoBien",
                JSON.stringify(requestT?.tipo_bien)
              );
            });
          }
        } else {
          const response = await putRequestType(id_requestTypes, data);
          if ((response.data.status = status201ok)) {
            constructAlert(
              "",
              "¡El tipo de solicitud ha sido actualizado!",
              "success",
              "Continuar"
            ).then(() => {
              setEnbaleNextButton(true);
              setEnbaleSaveButton(false);
              localStorage.setItem(
                "tipoSolicitud",
                JSON.stringify(requestT?.tipo_solcitud)
              );
              localStorage.setItem(
                "tipoBien",
                JSON.stringify(requestT?.tipo_bien)
              );
            });
          }
        }
      } catch (error) {
        constructAlert("", error.response.data.message, "error", "Aceptar");
      }
    } else {
      constructAlert(
        "",
        "Algunos datos diligenciados no son válidos.",
        "error",
        "Aceptar"
      );
    }
  };

  const goBackTo = () => {
    constructTwoButtonsAlert(
      "",
      "¡Recuerde, si sale de la creación de solicitud sin guardar o sin completar todos los pasos, luego podrá continuar desde la sección “Consultar trámite”!",
      "info",
      "Continuar",
      "Cancelar"
    ).then((res) => {
      if (res.value === true) {
        persistor.purge();
        navigate("/");
        window.location.reload();
      }
    });
  };
  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    tipoSolicitudValidaciones(name, value, generalErrors, setGeneralErrors);

    if (requestT?.calidad_actual !== 1) {
      tipoSolicitudValidaciones(
        name,
        value,
        conocePropietarioError,
        setConocePropietarioError
      );
    } else {
      tipoSolicitudValidaciones(
        name,
        value,
        unicoPropietarioError,
        setUnicoPropietarioError
      );
    }

    dispatch(changeText({ name, value }));
  };

  const validateForm = () => {
    let newErrors = {};
    const data = {};

    data.requestType = requestT?.tipo_solcitud;
    data.typeBien = requestT?.tipo_bien;
    data.typeCalidad = requestT?.calidad_actual;
    data.actingType = requestT?.interes;
    data.typePerson = requestT?.tipo_persona_propietario;
    if (requestT?.calidad_actual === 1)
      data.address = requestT?.direccion_propietario;

    if (
      requestT?.unico_propietario === 1 &&
      requestT?.tipo_persona_propietario === 1
    ) {
      data.firstName = requestT?.nombre_propietario;
      data.firstLastName = requestT?.apellido_propietario;
    } else if (
      requestT?.unico_propietario === 1 &&
      requestT?.tipo_persona_propietario === 2
    ) {
      data.businessName = requestT?.razon_social;
    }

    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] === null || data[key] === 0) {
        newErrors[key] = "Este campo es requerido.";
      } else if (
        data[key]?.tipo_via === "" &&
        data[key]?.numero_via === "" &&
        data[key]?.via_generadora === "" &&
        data[key]?.numero_dir === ""
      ) {
        newErrors[key] = "Este campo es requerido.";
      }
    });

    setGeneralErrors({ ...generalErrors, ...newErrors });
  };

  const handleOnBlurValidation = (name, value) => {
    let newErrors = {};
    const data = {};

    data.requestType = requestT?.tipo_solcitud;
    data.typeBien = requestT?.tipo_bien;
    data.typeCalidad = requestT?.calidad_actual;
    data.actingType = requestT?.interes;
    data.typePerson = requestT?.tipo_persona_propietario;
    if (requestT?.calidad_actual === 1)
      data.address = requestT?.direccion_propietario;

    if (
      requestT?.unico_propietario === 1 &&
      requestT?.tipo_persona_propietario === 1
    ) {
      data.firstName = requestT?.nombre_propietario;
      data.firstLastName = requestT?.apellido_propietario;
    } else if (
      requestT?.unico_propietario === 1 &&
      requestT?.tipo_persona_propietario === 2
    ) {
      data.businessName = requestT?.razon_social;
    }

    if (data[name] === "" || data[name] === null || data[name] === 0) {
      newErrors[name] = "Este campo es requerido.";
    } else if (
      data[name]?.tipo_via === "" &&
      data[name]?.numero_via === "" &&
      data[name]?.via_generadora === "" &&
      data[name]?.numero_dir === ""
    ) {
      newErrors[name] = "Este campo es requerido.";
    } else {
      newErrors[name] = "";
    }

    setGeneralErrors({ ...generalErrors, ...newErrors });
  };

  return (
    <>
      <div>
        <nav
          style={{ "--bs-breadcrumb-divider": ">" }}
          aria-label="breadcrumb"
          className="mt-1"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item " aria-current="page">
              <a href="/">Inicio</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tipo de Solicitud
            </li>
          </ol>
        </nav>
      </div>
      <hr className="hrC"></hr>
      <h3 className="title-p mt-1 d-flex">Creación de solicitud - Paso 1</h3>
      <div className="header-style">
        <img
          src={goback}
          alt="icon"
          style={{ width: "106px" }}
          className="goback-style"
          onClick={goBackTo}
        />
        <h3>Tipo de solicitud</h3>
      </div>
      <p className="align-start">
        Para continuar con el registro en línea del trámite de declaratoria,
        revocatoria o cambio de nivel de intervención de un bien de interés
        cultural del ámbito Distrital, ingrese los datos de tipo de solicitud
        que va realizar.
      </p>
      <p className="align-start">
        <b>NOTA: Todos los campos marcados con * son obligatorios</b>
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="align-start row form-group gov-co-form-group"
          >
            <div className="col-md-6 w-100 mt-4">
              <label className="form-label" htmlFor="requestType">
                Tipo de solicitud *
              </label>
              <SelectAsyncPaginate
                options={requestTypes}
                value={{
                  label:
                    requestT?.tipo_solcitud === 0
                      ? "Seleccione"
                      : requestTypes.find(
                          (e) => e.id === requestT?.tipo_solcitud
                        )?.tipo_solicitud,
                  value:
                    requestT?.tipo_solcitud === 0 ? 0 : requestT?.tipo_solcitud,
                }}
                onChange={(option) => {
                  handleOnChange(option);
                  dispatch(
                    changeText({ name: option.name, value: option.value })
                  );
                }}
                name={"requestType"}
                fieldName={"tipo_solicitud"}
                displayName={"tipo_solicitud"}
                displayValue={"id"}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {generalErrors && generalErrors.requestType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {generalErrors.requestType}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-md-6 w-100 mt-4">
              <label className="form-label" htmlFor="typeBien">
                Tipo de bien *
              </label>
              <SelectAsyncPaginate
                options={typesGood}
                value={{
                  label:
                    requestT?.tipo_bien === 0
                      ? "Seleccione"
                      : typesGood.find((e) => e.id === requestT?.tipo_bien)
                          ?.tipo_bien,
                  value: requestT?.tipo_bien === 0 ? 0 : requestT?.tipo_bien,
                }}
                onChange={(option) => {
                  handleOnChange(option);
                  dispatch(
                    changeText({ name: option.name, value: option.value })
                  );
                }}
                name={"typeBien"}
                fieldName={"tipo_bien"}
                displayName={"tipo_bien"}
                displayValue={"id"}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {generalErrors && generalErrors.typeBien ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {generalErrors.typeBien}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-md-6 w-100 mt-4">
              <label className="form-label" htmlFor="typeCalidad">
                Calidad en la que actúa *
              </label>
              <SelectCustom
                options={
                  requestT?.tipo_bien === 3 || requestT?.tipo_bien === 4
                    ? currentQualities.slice().filter((e) => e.id !== 1)
                    : currentQualities
                }
                value={{
                  label:
                    requestT?.calidad_actual === 0
                      ? "Seleccione"
                      : currentQualities.find(
                          (e) => e.id === requestT?.calidad_actual
                        )?.calidad_actual,
                  value:
                    requestT?.calidad_actual === 0
                      ? 0
                      : requestT?.calidad_actual,
                }}
                onChange={(option) => {
                  handleOnChange(option);
                  dispatch(
                    changeText({ name: option.name, value: option.value })
                  );
                }}
                name={"typeCalidad"}
                fieldName={"calidad_actual"}
                displayName={"calidad_actual"}
                displayValue={"id"}
                isDisabled={requestT?.tipo_bien === 0 ? true : false}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {generalErrors && generalErrors.typeCalidad ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {generalErrors.typeCalidad}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-md-6 w-100 mt-4">
              <label className="form-label">Interés en el que actúa *</label>
              <SelectAsyncPaginate
                options={actingInteOptions}
                value={{
                  label:
                    requestT?.interes === 0
                      ? "Seleccione"
                      : actingInteOptions.find(
                          (e) => e.id === requestT?.interes
                        )?.tipo_interes,
                  value: requestT?.interes === 0 ? 0 : requestT?.interes,
                }}
                onChange={(option) => {
                  handleOnChange(option);
                  dispatch(
                    changeText({ name: option.name, value: option.value })
                  );
                }}
                name={"actingType"}
                fieldName={"tipo_interes"}
                displayName={"tipo_interes"}
                displayValue={"id"}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {generalErrors && generalErrors.actingType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {generalErrors.actingType}
                  </small>
                </>
              ) : null}
            </div>

            {requestT?.calidad_actual === 1 && (
              <>
                <div className="col-md-6 w-100 mt-4">
                  <h3 className="h3-tramite mb-4" style={{fontSize: "15px", lineHeight: "normal"}}>
                    {requestT?.calidad_actual === 1
                      ? "¿Es usted único propietario? *"
                      : "¿Es usted único propietario?"}
                  </h3>
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                      <input
                        type="radio"
                        id="si"
                        name="unicoPropietario"
                        value={1}
                        checked={requestT.unico_propietario === 1}
                        onChange={(event) => {
                          handleTextFieldChange(event);
                          handleOnChange(event);
                        }}
                      />
                      <label style={{ margin: "0" }} htmlFor="si">
                        &#160; SI
                      </label>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-start ml-5">
                      <input
                        type="radio"
                        id="no"
                        name="unicoPropietario"
                        value={2}
                        checked={requestT.unico_propietario === 2}
                        onChange={(event) => {
                          handleTextFieldChange(event);
                          handleOnChange(event);
                        }}
                      />
                      <label style={{ margin: "0" }} htmlFor="no">
                        &#160; NO
                      </label>
                    </div>
                  </div>
                  {unicoPropietarioError &&
                    unicoPropietarioError.unicoPropietario &&
                    requestT?.calidad_actual === 1 && (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {unicoPropietarioError.unicoPropietario}
                        </small>
                      </>
                    )}
                </div>
              </>
            )}
            {requestT?.calidad_actual !==0  && (
              requestT?.calidad_actual !== 1 && (
                <div className="col-md-6 w-100 mt-4">
                <h3 className="h3-tramite mb-4" style={{fontSize: "15px", lineHeight: "normal"}}>¿Conoce el propietario?</h3>
                <div className="d-flex flex-row align-items-center justify-content-start">
                  <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                    <input
                      type="radio"
                      id="si1"
                      name="conocePropietario"
                      value={1}
                      checked={requestT?.conoce_el_propietario === 1}
                      onChange={(event) => {
                        handleOnChange(event);
                        handleTextFieldChange(event);
                      }}
                    />
                    <label style={{ margin: "0" }} htmlFor="si1">
                      &#160; SI
                    </label>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-start ml-5">
                    <input
                      type="radio"
                      id="no1"
                      name="conocePropietario"
                      value={2}
                      checked={requestT?.conoce_el_propietario === 2}
                      onChange={(event) => {
                        handleOnChange(event);
                        handleTextFieldChange(event);
                      }}
                    />
                    <label style={{ margin: "0" }} htmlFor="no1">
                      &#160; NO
                    </label>
                  </div>
                </div>
                {conocePropietarioError &&
                conocePropietarioError.conocePropietario &&
                requestT?.calidad_actual !== 1 && (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {conocePropietarioError.conocePropietario}
                    </small>
                  </>
                )}
              </div>
              )
            
            )}

            {requestT?.calidad_actual !== 0 && requestT?.calidad_actual === 1 && (
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label">
                  {requestT?.calidad_actual === 1
                    ? "Tipo de persona - propietario*"
                    : "Tipo de persona*"}
                </label>
                <SelectAsyncPaginate
                  isDisabled={disableInput}
                  options={typesPeople}
                  value={{
                    label:
                      requestT?.tipo_persona_propietario === 0
                        ? "Seleccione"
                        : typesPeople.find(
                            (e) => e.id === requestT?.tipo_persona_propietario
                          )?.persona_propietario,
                    value:
                      requestT?.tipo_persona_propietario === 0
                        ? 0
                        : requestT?.tipo_persona_propietario,
                  }}
                  onChange={(option) => {
                    handleOnChange(option);
                    dispatch(
                      changeText({ name: option.name, value: option.value })
                    );
                  }}
                  name={"typePerson"}
                  fieldName={"persona_propietario"}
                  displayName={"persona_propietario"}
                  displayValue={"id"}
                  onBlur={(name, value) => {
                    handleOnBlurValidation(name, value?.value);
                  }}
                />
                {generalErrors && generalErrors.typePerson ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {generalErrors.typePerson}
                    </small>
                  </>
                ) : null}
              </div>
            )}
            {requestT?.calidad_actual !== 0 && requestT?.calidad_actual !== 1 && requestT?.conoce_el_propietario === 1 && (
              <div className="col-md-6 w-100 mt-4">
                <label className="form-label">
                Tipo de persona*
                </label>
                <SelectAsyncPaginate
                  isDisabled={disableInput}
                  options={typesPeople}
                  value={{
                    label:
                      requestT?.tipo_persona_propietario === 0
                        ? "Seleccione"
                        : typesPeople.find(
                            (e) => e.id === requestT?.tipo_persona_propietario
                          )?.persona_propietario,
                    value:
                      requestT?.tipo_persona_propietario === 0
                        ? 0
                        : requestT?.tipo_persona_propietario,
                  }}
                  onChange={(option) => {
                    handleOnChange(option);
                    dispatch(
                      changeText({ name: option.name, value: option.value })
                    );
                  }}
                  name={"typePerson"}
                  fieldName={"persona_propietario"}
                  displayName={"persona_propietario"}
                  displayValue={"id"}
                  onBlur={(name, value) => {
                    handleOnBlurValidation(name, value?.value);
                  }}
                />
                {generalErrors && generalErrors.typePerson ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {generalErrors.typePerson}
                    </small>
                  </>
                ) : null}
              </div>
            )}

            {/* {(requestT?.calidad_actual !== 1 && requestT?.calidad_actual !== 0) && (
                <>
                  <div
                    className={`col-md-${
                      requestT.unico_propietario === 1 ? "3" : "6"
                    } w-100 mt-4`}
                  >
                    <h3 className="h3-tramite mb-4">¿Es único propietario?</h3>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                      <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                        <input
                          type="radio"
                          id="si"
                          name="unicoPropietario"
                          value={1}
                          checked={requestT.unico_propietario === 1}
                          onChange={(event) => {
                            handleTextFieldChange(event);
                            handleOnChange(event);
                          }}
                        />
                        <label style={{ margin: "0" }} htmlFor="si">
                          &#160; SI
                        </label>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-start ml-5">
                        <input
                          type="radio"
                          id="no"
                          name="unicoPropietario"
                          value={2}
                          checked={requestT.unico_propietario === 2}
                          onChange={(event) => {
                            handleTextFieldChange(event);
                            handleOnChange(event);
                          }}
                        />
                        <label style={{ margin: "0" }} htmlFor="no">
                          &#160; NO
                        </label>
                      </div>
                    </div>
                    {unicoPropietarioError &&
                    unicoPropietarioError.unicoPropietario &&
                    requestT?.calidad_actual === 1 ? (
                      <>
                        <small
                          className="form-text"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {unicoPropietarioError.unicoPropietario}
                        </small>
                      </>
                    ) : null}
                  </div>

                
                </>
              )} */}

            {requestT?.unico_propietario === 1 &&
            requestT?.tipo_persona_propietario === 1 ? (
              <>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="firstName">
                          {requestT?.calidad_actual === 1
                            ? "Primer nombre del propietario*"
                            : "Primer nombre*"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="firstName"
                          value={requestT?.nombre_propietario}
                          onChange={handleTextFieldChange}
                          id="firstName"
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Primer nombre"
                          onBlur={(event) =>
                            handleOnBlurValidation(
                              event.target.name,
                              event.target.value
                            )
                          }
                        ></input>
                        {generalErrors && generalErrors.firstName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.firstName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="secondName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="secondName">
                          {requestT?.calidad_actual === 1
                            ? "Segundo nombre del propietario"
                            : "Segundo nombre"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="secondName"
                          id="secondName"
                          onChange={handleTextFieldChange}
                          value={requestT?.segundo_nombre_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Segundo nombre"
                        ></input>
                        <small className="error-message form-text">
                          {" "}
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </small>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="firstLastName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="firstLastName">
                          {requestT?.calidad_actual === 1
                            ? "Primer apellido del propietario *"
                            : "Primer apellido*"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="firstLastName"
                          id="firstLastName"
                          onChange={handleTextFieldChange}
                          value={requestT?.apellido_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Primer apellido"
                          onBlur={(event) =>
                            handleOnBlurValidation(
                              event.target.name,
                              event.target.value
                            )
                          }
                        ></input>
                        {generalErrors && generalErrors.firstLastName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.firstLastName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="secondLastName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="secondLastName">
                          {requestT?.calidad_actual === 1
                            ? "Segundo apellido del propietario"
                            : "Segundo apellido"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="secondLastName"
                          id="secondLastName"
                          onChange={handleTextFieldChange}
                          value={requestT?.segundo_apellido_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Segundo apellido"
                        ></input>
                        <small className="error-message form-text">
                          {" "}
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </small>
                      </div>
                    )}
                  </Field>
                </div>
              </>
            ) : (
              requestT?.unico_propietario === 1 &&
              requestT?.tipo_persona_propietario === 2 && (
                <div className="col-md-6 w-100 mt-4">
                  <Field name="businessName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="businessName">Razón social:*</label>
                        <input
                          {...input}
                          type="text"
                          name="businessName"
                          id="businessName"
                          onChange={handleTextFieldChange}
                          value={requestT?.razon_social}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Razón social"
                        ></input>
                        {generalErrors && generalErrors.businessName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.businessName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
              )
            )}
            {/* 
            Logica cuando conoce al propietario 
            */}
  {requestT?.calidad_actual !== 0 && requestT?.calidad_actual !== 1 && requestT?.conoce_el_propietario === 1  &&
            requestT?.tipo_persona_propietario === 1 ? (
              <>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="firstName">
                          {requestT?.calidad_actual === 1
                            ? "Primer nombre del propietario*"
                            : "Primer nombre*"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="firstName"
                          value={requestT?.nombre_propietario}
                          onChange={handleTextFieldChange}
                          id="firstName"
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Primer nombre"
                          onBlur={(event) =>
                            handleOnBlurValidation(
                              event.target.name,
                              event.target.value
                            )
                          }
                        ></input>
                        {generalErrors && generalErrors.firstName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.firstName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="secondName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="secondName">
                          {requestT?.calidad_actual === 1
                            ? "Segundo nombre del propietario"
                            : "Segundo nombre"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="secondName"
                          id="secondName"
                          onChange={handleTextFieldChange}
                          value={requestT?.segundo_nombre_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Segundo nombre"
                        ></input>
                        <small className="error-message form-text">
                          {" "}
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </small>
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="firstLastName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="firstLastName">
                          {requestT?.calidad_actual === 1
                            ? "Primer apellido del propietario *"
                            : "Primer apellido*"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="firstLastName"
                          id="firstLastName"
                          onChange={handleTextFieldChange}
                          value={requestT?.apellido_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Primer apellido"
                          onBlur={(event) =>
                            handleOnBlurValidation(
                              event.target.name,
                              event.target.value
                            )
                          }
                        ></input>
                        {generalErrors && generalErrors.firstLastName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.firstLastName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="col-md-6 w-100 mt-4">
                  <Field name="secondLastName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="secondLastName">
                          {requestT?.calidad_actual === 1
                            ? "Segundo apellido del propietario"
                            : "Segundo apellido"}
                        </label>
                        <input
                          {...input}
                          type="text"
                          name="secondLastName"
                          id="secondLastName"
                          onChange={handleTextFieldChange}
                          value={requestT?.segundo_apellido_propietario}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Segundo apellido"
                        ></input>
                        <small className="error-message form-text">
                          {" "}
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </small>
                      </div>
                    )}
                  </Field>
                </div>
              </>
            ) : (
              requestT?.calidad_actual !== 0 && requestT?.calidad_actual !== 1 && requestT?.conoce_el_propietario === 1 &&
              requestT?.tipo_persona_propietario === 2 && (
                <div className="col-md-6 w-100 mt-4">
                  <Field name="businessName">
                    {({ input, meta }) => (
                      <div className="form-group gov-co-form-group w-100">
                        <label htmlFor="businessName">Razón social:*</label>
                        <input
                          {...input}
                          type="text"
                          name="businessName"
                          id="businessName"
                          onChange={handleTextFieldChange}
                          value={requestT?.razon_social}
                          className={
                            meta.error && meta.touched
                              ? "w-100 invalid"
                              : "w-100"
                          }
                          placeholder="Razón social"
                        ></input>
                        {generalErrors && generalErrors.businessName ? (
                          <>
                            <small
                              className="form-text"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {generalErrors.businessName}
                            </small>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Field>
                </div>
              )
            )}

              {/** 
               * TABLA DE MULTIPLES
               * 
               * 
              */}
            {requestT?.unico_propietario === 2 &&
            requestT?.tipo_persona_propietario === 1 ? (
              <div className="col-md-12 w-100 mt-4">
                <Table
                  showHeader={true}
                  bordered={true}
                  autoHeight={true}
                  cellBordered={true}
                  data={requestT?.otros_propietarios_natural}
                  rowHeight={65}
                  style={{ zIndex: 0 }}
                >
                  <Column flexGrow={1} align="center">
                    <HeaderCell
                      style={{
                        color: "#004884",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Nombre
                    </HeaderCell>
                    <EditableCell
                      dataKey="nombres_natural"
                      placeholder="Nombre"
                      onChange={(rowData, value, dataKey) => {
                        handleSaveMultiNatural(rowData, value, dataKey);
                      }}
                    />
                  </Column>
                  <Column flexGrow={1} align="center">
                    <HeaderCell
                      style={{
                        color: "#004884",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Apellido
                    </HeaderCell>
                    <EditableCell
                      dataKey="apellidos_natural"
                      placeholder="Apellido"
                      onChange={(rowData, value, dataKey) => {
                        handleSaveMultiNatural(rowData, value, dataKey);
                      }}
                    />
                  </Column>
                  <Column flexGrow={1} align="center">
                    <HeaderCell
                      style={{
                        color: "#004884",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Dirección
                    </HeaderCell>
                    <EditableCell
                      dataKey="direccion_natural"
                      placeholder="Dirección"
                      onChange={(rowData, value, dataKey) => {
                        handleSaveMultiNatural(rowData, value, dataKey);
                      }}
                    />
                  </Column>
                  <Column flexGrow={1.2} align="center">
                    <HeaderCell
                      style={{
                        color: "#004884",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      ¿Conoce el propietario?
                    </HeaderCell>
                    <SelectCell
                      dataKey="conoce_propietario_natural"
                      onChange={(rowData, value, dataKey) => {
                        handleSaveMultiNatural(rowData, value, dataKey);
                      }}
                    />
                  </Column>
                  <Column align="center" flexGrow={1}>
                    <HeaderCell
                      style={{
                        color: "#004884",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Acción
                    </HeaderCell>
                    <Cell>
                      {(rowData, rowIndex) => {
                        return (
                          <span>
                            <IconButton
                              icon={<TrashIcon />}
                              color="red"
                              appearance="primary"
                              onClick={() => handleRemoveMultiNatural(rowData)}
                            />
                          </span>
                        );
                      }}
                    </Cell>
                  </Column>
                </Table>
                <div className="w-100 d-flex justify-content-end align-items-end">
                  <IconButton
                    icon={<PlusIcon />}
                    color="blue"
                    appearance="primary"
                    className="mt-4"
                    placement="right"
                    onClick={() => handleAddMultiNatural()}
                  >
                    Añadir propietario
                  </IconButton>
                </div>
              </div>
            ) : (
              requestT?.unico_propietario === 2 &&
              requestT?.tipo_persona_propietario === 2 && (
                <div className="col-md-12 w-100 mt-4">
                  <Table
                    showHeader={true}
                    bordered={true}
                    autoHeight={true}
                    cellBordered={true}
                    data={requestT?.otros_propietarios_juridico}
                    rowHeight={65}
                    style={{ zIndex: 0 }}
                  >
                    <Column flexGrow={1} align="center">
                      <HeaderCell
                        style={{
                          color: "#004884",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Razón social
                      </HeaderCell>
                      <EditableCell
                        dataKey="razon_social_juridico"
                        placeholder="Razón social"
                        onChange={(rowData, value, dataKey) => {
                          handleSaveMultiJuridica(rowData, value, dataKey);
                        }}
                      />
                    </Column>
                    <Column flexGrow={1} align="center">
                      <HeaderCell
                        style={{
                          color: "#004884",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Dirección
                      </HeaderCell>
                      <EditableCell
                        dataKey="direccion_juridico"
                        placeholder="Dirección"
                        onChange={(rowData, value, dataKey) => {
                          handleSaveMultiJuridica(rowData, value, dataKey);
                        }}
                      />
                    </Column>
                    <Column flexGrow={1.2} align="center">
                      <HeaderCell
                        style={{
                          color: "#004884",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        ¿Conoce el propietario?
                      </HeaderCell>
                      <SelectCell
                        dataKey="conoce_propietario_juridico"
                        onChange={(rowData, value, dataKey) => {
                          handleSaveMultiJuridica(rowData, value, dataKey);
                        }}
                      />
                    </Column>
                    <Column align="center" flexGrow={1}>
                      <HeaderCell
                        style={{
                          color: "#004884",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Acción
                      </HeaderCell>
                      <Cell>
                        {(rowData, rowIndex) => {
                          return (
                            <span>
                              <IconButton
                                icon={<TrashIcon />}
                                color="red"
                                appearance="primary"
                                onClick={() =>
                                  handleRemoveMultiJuridica(rowData)
                                }
                              />
                            </span>
                          );
                        }}
                      </Cell>
                    </Column>
                  </Table>
                  <div className="w-100 d-flex justify-content-end align-items-end">
                    <IconButton
                      icon={<PlusIcon />}
                      color="blue"
                      appearance="primary"
                      className="mt-4"
                      placement="right"
                      onClick={() => handleAddMultiJuridica()}
                    >
                      Añadir propietario
                    </IconButton>
                  </div>
                </div>
              )
            )}
          

            {requestT?.calidad_actual === 1 && requestT?.unico_propietario === 1 && (
              <div className="col-md-12 w-100 mt-4">
                <DireccionInput
                  fieldLabel={
                    "Dirección del propietario (si actúa en calidad diferente a este)"
                  }
                  address={
                    requestT?.unico_propietario === 1 &&
                    requestT?.tipo_persona_propietario === 1
                      ? ""
                      : requestT?.direccion_propietario
                  }
                  setAddress={setDireccionOficial}
                  handleAddressChange={handleAddressChange}
                  defaultValue={
                    typeof requestT?.direccion_propietario === "object"
                      ? Object.values(requestT?.direccion_propietario)
                          .join(" ")
                          .replace(/\s+/g, " ")
                      : requestT?.direccion_propietario
                  }
                />
                {generalErrors && generalErrors.address ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {generalErrors.address}
                    </small>
                  </>
                ) : null}
              </div>
            )}
            {requestT?.calidad_actual !== 0 && requestT?.calidad_actual !== 1 && requestT?.conoce_el_propietario === 1  && (requestT?.tipo_persona_propietario === 2 || requestT?.tipo_persona_propietario === 1) && (
              <div className="col-md-12 w-100 mt-4">
                <DireccionInput
                  fieldLabel={
                    "Dirección del propietario (si actúa en calidad diferente a este)"
                  }
                  address={
                    requestT?.unico_propietario === 1 &&
                    requestT?.tipo_persona_propietario === 1
                      ? ""
                      : requestT?.direccion_propietario
                  }
                  setAddress={setDireccionOficial}
                  handleAddressChange={handleAddressChange}
                  defaultValue={
                    typeof requestT?.direccion_propietario === "object"
                      ? Object.values(requestT?.direccion_propietario)
                          .join(" ")
                          .replace(/\s+/g, " ")
                      : requestT?.direccion_propietario
                  }
                />
                {generalErrors && generalErrors.address ? (
                  <>
                    <small
                      className="form-text"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {generalErrors.address}
                    </small>
                  </>
                ) : null}
              </div>
            )}


            <div
              className="w-100 d-flex flex-row justify-content-center align-items-center"
              style={{ marginTop: "100px" }}
            >
              <ButtonGroup>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  onClick={onPrevious}
                  disabled={step === 0}
                >
                  Atrás
                </Button>
                <Button
                  style={{ backgroundColor: "#3366CC", width: "80px" }}
                  appearance="primary"
                  type="submit"
                  onClick={validateForm}
                  disabled={!enableSaveButton}
                >
                  {id_requestTypes != 0 ? "Actualizar" : "Guardar"}
                </Button>
                <Button
                  style={{ width: "80px" }}
                  appearance="ghost"
                  disabled={!enableNextButton}
                  onClick={onNext}
                >
                  Siguiente
                </Button>
              </ButtonGroup>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default TipoSolicitud;
