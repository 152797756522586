import ReCAPTCHA from "react-google-recaptcha";
import { getEnvVaribles } from "../../utils/helpers/getEnvVaribles";

function ReCAPTCHAInput({handleCaptchaChange}) {
  const { RECAPTCHA_SITE_KEY } = getEnvVaribles();

  return (
  // <recaptcha-scrd onChange={handleCaptchaChange} data-sitekey={SITE_KEY}></recaptcha-scrd>
    <ReCAPTCHA         
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleCaptchaChange}
    >
    </ReCAPTCHA>
  );
}

export default ReCAPTCHAInput;
