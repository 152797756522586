import React from "react";
import axiosBase from "../../utils/axios/axios";

export const getZone = async () =>{
    try {
        const response = await axiosBase.get("Identificacion_bien/zona/");
        return response.data;
    } catch (error) {
            throw error
    }
}
export const getLocal = async () =>{
    try {
        const response = await axiosBase.get("api/localidades/");
        return response.data;
    } catch (error) {
            throw error
    }
}
export const getUPL = async () =>{
    try {
        const response = await axiosBase.get("Identificacion_bien/upl/");
        return response.data;
    } catch (error) {
            throw error
    }
}
export const getUPZ = async (idLocalidad) =>{
    try {
        const response = await axiosBase.get(`api/UPZ/?test=${idLocalidad}`);
        return response.data;
    } catch (error) {
            throw error
    }
}
export const getBarrio = async (idUpz) =>{
    try {
        const response = await axiosBase.get(`api/Barrios/?test=${idUpz}`);
        return response.data;
    } catch (error) {
            throw error
    }
}
export const getNumberApples = async () =>{
    try {
        const response = await axiosBase.get("Identificacion_bien/manzana/");
        return response.data;
    } catch (error) {
            throw error
    }
}