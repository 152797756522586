// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.resumen-container {
    text-align: start;
}

.info-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: start;
    align-items: center;
    gap: 3rem;
}

.text-style {
    text-align: justify;
    font-weight: 700;
}

.text-title {
    font-weight: 700;
    height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/crear/Resumen/Resumen.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":["\n.resumen-container {\n    text-align: start;\n}\n\n.info-box {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    justify-content: start;\n    align-items: center;\n    gap: 3rem;\n}\n\n.text-style {\n    text-align: justify;\n    font-weight: 700;\n}\n\n.text-title {\n    font-weight: 700;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
