import DireccionInput from "../../../../../shared/components/DireccionInput";
import SelectAsyncPaginate from "../../../../../shared/AsyncSelects/SelectAsyncPaginate";
import { useState, useEffect } from "react";
import {
  getTipoDocumentoNatural,
  getTipoDocumentoJuridica,
} from "../../../../../services/datosPersonales/DataPerson";
import { getTiposGenero } from "../../../../../services/datosPersonales/DataPerson";
import { preventNegativeValues } from "../../../../../utils/helpers/others/others";
import { getUsuarioExistente } from "../../../../../services/datosPersonales/DataPerson";
import constructAlert from "../../../../../utils/sweetalert/constructAlert";
import { useDispatch } from "react-redux";
import {
  getDataInformations,
  getIdPerso,
  postTypePerson,
} from "../../../../../shared/slice/reducers/personalInformations.reducers";
import "../DatosPersonales.css";

function FormPersona({
  typePerson,
  handleOnChange,
  handleAddressChange,
  address,
  setAddress,
  errors,
  isFormSubmitted,
  form,
  setForm,
  idExistingUser,
  setIdExistingUser,
  resetErrors,
  editableMode,
  setEditableMode,
  setTypePerson,
  typesPerson,
  handleOnBlurValidation,
}) {
  const dispatch = useDispatch();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [legalDocumentTypes, setLegalDocumentTypes] = useState([]);
  const [genderList, setGenderList] = useState([]);

  const restrictCopyPaste = (e) => {
    e.preventDefault();
  };

  const [documentType, setDocumentType] = useState("");
  const [legalDocumentType, setLegalDocumentType] = useState("");
  const [gender, setGender] = useState("");

  const handleVerifyUser = (event) => {
    if (event.target.value !== "") {
      getUsuarioExistente(event.target.value)
        .then((response) => {
          if (response.status === "200") {
            constructAlert(
              "",
              `¡El usuario con número de identificación ${event.target.value} ya existe, se cargarán los datos previamente guardados!`,
              "info",
              "Continuar"
            ).then(() => {
              let serverData = response.data[0];
              dispatch(postTypePerson(serverData.tipo_persona));
              dispatch(getIdPerso(serverData.id));
              dispatch(getDataInformations(serverData));

              let patchData = {
                companyName: serverData.razon_social || "",
                legalType: serverData.tipo_documento_empresa || "",
                identificationType: serverData.tipo_documento || "",
                validateIdentificationNumber: serverData.numero || "",
                issueDate: serverData.fecha_expedicion || "",
                birthDate: serverData.fecha_nacimiento || "",
                gender: serverData.sexo || "",
                firstName: serverData.primer_nombre || "",
                secondName: serverData.segundo_nombre || "",
                lastName: serverData.primer_apellido || "",
                secondSurname: serverData.segundo_apellido || "",
                identificationLegalType: serverData.tipo_documento || "",
                email: serverData.correo_electronico || "",
                contactNumber1: serverData.numero_contacto || "",
                contactNumber2: serverData.numero_contacto2 || "",
                scrdAutorization: serverData.se_autoriza_secretaria
                  ? "true"
                  : "false",
                checkPrivacy: serverData.se_autoriza_tratamiento
                  ? "true"
                  : "false",
                checkTerms: serverData.acepto_terminos_condiciones
                  ? "true"
                  : "false",
                // address: serverData.direccion || "",
                identificationNumber:
                  typePerson === 1
                    ? serverData.numero
                    : serverData.numero_id_tributaria,
                address: {
                  via: serverData.tipo_via || "",
                  numeroVia: serverData.numero_via || "",
                  letra: serverData.letra || "",
                  bis: serverData.bis ? "BIS" : "",
                  cuadrante: serverData.cuadrante || "",
                  viaGeneradora: serverData.via_generadora || "",
                  letraSecundaria: serverData.letra2 || "",
                  bissSecundario: serverData.bis2 ? "BIS" : "",
                  numeroViaSecundaria: serverData.numero_dir || "",
                  cuadranteSecundario: serverData.cuadrante2 || "",
                  complemento: serverData.complemento || "",
                },
              };

              if (typePerson === 1) {
                const findGender = genderList.find(
                  (gender) => parseInt(gender.id) === patchData.gender
                );
                const findIdentificationType = documentTypes.find(
                  (type) => parseInt(type.id) === patchData.identificationType
                );

                setIdExistingUser(serverData.id);
                setForm(patchData);
                setAddress(patchData.address);
                setEditableMode(true);

                if (findGender !== undefined) {
                  setGender({
                    label: findGender && findGender.descripcion,
                    value: findGender && findGender.id,
                  });
                }

                if (findIdentificationType !== undefined) {
                  setDocumentType({
                    label:
                      findIdentificationType &&
                      findIdentificationType.descripcion,
                    value: findIdentificationType && findIdentificationType.id,
                  });
                }
              } else {
                if (patchData.companyName !== "") {
                  setIdExistingUser(serverData.id);
                  setForm(patchData);
                  setAddress(patchData.address);
                  setEditableMode(true);

                  const findIdentificationType = documentTypes.find(
                    (type) =>
                      parseInt(type.id) === patchData.identificationLegalType
                  );
                  const findIdentificationLegalType = legalDocumentTypes.find(
                    (type) => parseInt(type.id) === patchData.legalType
                  );

                  if (findIdentificationType !== undefined) {
                    setLegalDocumentType({
                      label: findIdentificationType?.descripcion,
                      value: findIdentificationType?.id,
                    });
                  }
                  if (findIdentificationLegalType !== undefined) {
                    setDocumentType({
                      label: findIdentificationLegalType?.descripcion,
                      value: findIdentificationLegalType?.id,
                    });
                  }
                } else {
                  constructAlert(
                    "",
                    `¡Esta persona con número de identificación ${event.target.value} no es representante legal de una empresa!`,
                    "error",
                    "Continuar"
                  );
                }
              }
            });
            resetErrors();
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    getTipoDocumentoNatural()
      .then((reponse) => {
        setDocumentTypes(reponse);
      })
      .catch((err) => {});
    getTipoDocumentoJuridica()
      .then((reponse) => {
        setLegalDocumentTypes(reponse);
      })
      .catch((err) => {});

    getTiposGenero()
      .then((reponse) => {
        setGenderList(reponse.message);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      {typePerson === 2 && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="companyName">
                Razón social *
              </label>
              <input
                id="companyName"
                name="companyName"
                className="form-control"
                placeholder="Razón social"
                type="text"
                onChange={handleOnChange}
                readOnly={editableMode}
                value={form.companyName}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.companyName ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.companyName}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="legalType">
                Tipo de documento de identificación *
              </label>
              <SelectAsyncPaginate
                options={legalDocumentTypes}
                value={documentType}
                onChange={(value) => {
                  setDocumentType(value);
                  handleOnChange(value);
                }}
                name={"legalType"}
                fieldName={"descripcion"}
                displayName={"descripcion"}
                displayValue={"id"}
                isDisabled={editableMode}
                isSearchable={editableMode ? false : true}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {errors && errors.legalType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.legalType}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      {typePerson === 1 && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              {/* Datos provenientes de back */}
              <label className="form-label" htmlFor="identificationType">
                Tipo de documento *
              </label>

              <SelectAsyncPaginate
                options={documentTypes}
                value={documentType}
                onChange={(value) => {
                  setDocumentType(value);
                  handleOnChange(value);
                }}
                name={"identificationType"}
                fieldName={"descripcion"}
                displayName={"descripcion"}
                displayValue={"id"}
                isDisabled={editableMode}
                isSearchable={editableMode ? false : true}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {errors && errors.identificationType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.identificationType}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      {(typePerson === 1 || typePerson === 2) && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div
              className={`${typePerson === 1 ? "highlight-box" : "normal-box"}`}
            >
              <label className="form-label" htmlFor="identificationNumber">
                {typePerson === 1
                  ? "Número de documento *"
                  : "Número de identificación tributaria *"}
              </label>

              {typePerson === 1 ? (
                <input
                  id="identificationNumber"
                  name="identificationNumber"
                  className="form-control"
                  placeholder={"Número de documento"}
                  onChange={handleOnChange}
                  onBlur={
                    !editableMode && typePerson === 1 ? handleVerifyUser : null
                  }
                  type={"text"}
                  readOnly={editableMode}
                  value={form.identificationNumber}
                ></input>
              ) : (
                <input
                  id="identificationNumber"
                  name="identificationNumber"
                  className="form-control"
                  placeholder={"Número de identificación tributaria"}
                  onKeyDown={preventNegativeValues}
                  min={"0"}
                  onChange={handleOnChange}
                  onBlur={
                    !editableMode && typePerson === 1 ? handleVerifyUser : null
                  }
                  type={"number"}
                  readOnly={editableMode}
                  value={form.identificationNumber}
                ></input>
              )}
              {errors && errors.identificationNumber ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.identificationNumber}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      {typePerson === 2 && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="identificationLegalType">
                Tipo de documento del representante legal*
              </label>
              <SelectAsyncPaginate
                options={documentTypes}
                value={legalDocumentType}
                onChange={(value) => {
                  setLegalDocumentType(value);
                  handleOnChange(value);
                }}
                name={"identificationLegalType"}
                fieldName={"descripcion"}
                displayName={"descripcion"}
                displayValue={"id"}
                isDisabled={editableMode}
                isSearchable={editableMode ? false : true}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {errors && errors.identificationLegalType ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.identificationLegalType}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="identificationLegalNumber">
                Número de documento del representante legal *
              </label>
              <input
                id="identificationLegalNumber"
                name="identificationLegalNumber"
                maxLength="11"
                className="form-control"
                placeholder="Número de documento del representante legal"
                onBlur={
                  !editableMode && typePerson === 2 ? handleVerifyUser : null
                }
                type="text"
                onChange={handleOnChange}
                readOnly={editableMode}
              ></input>
              {errors && errors.identificationLegalNumber ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.identificationLegalNumber}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
      {typePerson === 1 && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="highlight-box">
              <label
                className="form-label"
                htmlFor="validateIdentificationNumber"
              >
                Repetir número de documento *
              </label>
              <input
                id="validateIdentificationNumber"
                name="validateIdentificationNumber"
                className="form-control"
                placeholder="Repetir número de documento"
                onCopy={restrictCopyPaste}
                onPaste={restrictCopyPaste}
                onCut={restrictCopyPaste}
                value={form.validateIdentificationNumber}
                type="text"
                onChange={handleOnChange}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.validateIdentificationNumber ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.validateIdentificationNumber}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="highlight-box">
              <label className="form-label" htmlFor="birthDate">
                Fecha de nacimiento *
              </label>
              <input
                id="birthDate"
                name="birthDate"
                type="date"
                min="1900-01-01"
                max="3000-12-31"
                className="form-control"
                onChange={handleOnChange}
                value={form.birthDate}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.birthDate ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.birthDate}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="highlight-box">
              <label className="form-label" htmlFor="issueDate">
                Fecha de expedición *
              </label>
              <input
                id="issueDate"
                name="issueDate"
                type="date"
                min="1900-01-01"
                max="3000-12-31"
                className="form-control"
                onChange={handleOnChange}
                value={form.issueDate}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.issueDate ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.issueDate}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              {/* Datos provenientes de back */}
              <label className="form-label" htmlFor="gender">
                Sexo *
              </label>
              <SelectAsyncPaginate
                options={genderList}
                value={gender}
                onChange={(value) => {
                  setGender(value);
                  handleOnChange(value);
                }}
                name={"gender"}
                fieldName={"descripcion"}
                displayName={"descripcion"}
                displayValue={"id"}
                onBlur={(name, value) => {
                  handleOnBlurValidation(name, value?.value);
                }}
              />
              {errors && errors.gender ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.gender}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      {(typePerson === 1 || typePerson === 2) && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="firstName">
                {typePerson === 1
                  ? "Primer nombre *"
                  : "Primer nombre del representante legal*"}
              </label>
              <input
                id="firstName"
                name="firstName"
                className="form-control"
                placeholder={
                  typePerson === 1
                    ? "Primer nombre"
                    : "Primer nombre del representante legal"
                }
                type="text"
                onChange={handleOnChange}
                value={form.firstName}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.firstName ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.firstName}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="secondName">
                {typePerson === 1
                  ? "Segundo nombre"
                  : "Segundo nombre del representante legal"}
              </label>
              <input
                id="secondName"
                name="secondName"
                className="form-control"
                placeholder={
                  typePerson === 1
                    ? "Segundo nombre"
                    : "Segundo nombre del representante legal"
                }
                type="text"
                onChange={handleOnChange}
                value={form.secondName}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="lastName">
                {typePerson === 1
                  ? "Primer apellido *"
                  : "Primer apellido del representante legal*"}
              </label>
              <input
                id="lastName"
                name="lastName"
                className="form-control"
                placeholder={
                  typePerson === 1
                    ? "Primer apellido"
                    : "Primer apellido del representante legal*"
                }
                type="text"
                onChange={handleOnChange}
                value={form.lastName}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.lastName ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.lastName}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="secondSurname">
                {typePerson === 1
                  ? "Segundo apellido"
                  : "Segundo apellido del representante legal"}
              </label>
              <input
                id="secondSurname"
                name="secondSurname"
                className="form-control"
                placeholder={
                  typePerson === 1
                    ? "Segundo apellido"
                    : "Segundo apellido del representante legal"
                }
                type="text"
                onChange={handleOnChange}
                value={form.secondSurname}
                readOnly={editableMode}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="email">
                Correo electrónico *
              </label>
              <input
                id="email"
                name="email"
                className="form-control"
                placeholder="Correo electrónico"
                type="email"
                value={form.email}
                onChange={handleOnChange}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.email ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.email}
                  </small>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="contactNumber1">
                {typePerson === 1
                  ? "Número de contacto 1 *"
                  : "Número de télefono o celular de contacto *"}
              </label>
              <input
                id="contactNumber1"
                name="contactNumber1"
                className="form-control"
                placeholder={
                  typePerson === 1
                    ? "Número de contacto 1"
                    : "Número de télefono o celular de contacto"
                }
                type="number"
                min={"0"}
                value={form.contactNumber1}
                onKeyDown={preventNegativeValues}
                onChange={handleOnChange}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
              {errors && errors.contactNumber1 ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.contactNumber1}
                  </small>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}

      {typePerson === 1 && (
        <>
          <div className="col-md-6 w-100 mt-3">
            <div className="normal-box">
              <label className="form-label" htmlFor="contactNumber2">
                Número de contacto 2
              </label>
              <input
                id="contactNumber2"
                name="contactNumber2"
                className="form-control"
                placeholder="Número de contacto 2"
                type="number"
                value={form.contactNumber2}
                onKeyDown={preventNegativeValues}
                min={"0"}
                onChange={handleOnChange}
                onBlur={(event) =>
                  handleOnBlurValidation(event.target.name, event.target.value)
                }
              ></input>
            </div>
          </div>
        </>
      )}
      {(typePerson === 1 || typePerson === 2) && (
        <div className="col-md-12 w-100 mt-3">
          <DireccionInput
            fieldLabel={"Dirección de contacto / notificación"}
            setAddress={setAddress}
            address={address}
            handleAddressChange={handleAddressChange}
            defaultValue={
              typeof address === "object"
                ? Object.values(address).join(" ").replace(/\s+/g, " ")
                : address
            }
          />
          {errors && errors.address ? (
            <>
              <small
                className="form-text"
                style={{ color: "red", fontSize: "12px" }}
              >
                {errors.address}
              </small>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default FormPersona;
