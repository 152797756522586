import axiosBase from "../../utils/axios/axios";


export const getCriteriosBien = async () => {
    try {
        const response = await axiosBase.get("Descripcion_bien/criterios_valoracion/");
        return response.data;
    } catch (error) {
        throw error
    }
}