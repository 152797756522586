// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%;
  border-radius: 1rem;
  padding: 0 9% 5% 9%;
  background-color: #f6f8f9;
}

.align-start {
  text-align: start;
}

.gov-co-form-group input:focus {
  border: 0px !important;
}

.header-style {
  position: relative;
}

.goback-style {
  position: absolute;
  left: 0;
  width: 35px;
  top: 14px;
  filter: invert(15%) sepia(91%) saturate(2341%) hue-rotate(191deg) brightness(98%) contrast(101%);
  cursor: pointer;
}

.highlight-box {
  background-color: rgba(51, 102, 204, 0.1);
  border-radius: 10px;
  padding: 3%;
}

.normal-box {
  padding: 3%;
}`, "",{"version":3,"sources":["webpack://./src/pages/crear/components/DatosPersonales/DatosPersonales.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;EACX,SAAS;EACT,gGAAgG;EAChG,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".form-container {\n  width: 100%;\n  border-radius: 1rem;\n  padding: 0 9% 5% 9%;\n  background-color: #f6f8f9;\n}\n\n.align-start {\n  text-align: start;\n}\n\n.gov-co-form-group input:focus {\n  border: 0px !important;\n}\n\n.header-style {\n  position: relative;\n}\n\n.goback-style {\n  position: absolute;\n  left: 0;\n  width: 35px;\n  top: 14px;\n  filter: invert(15%) sepia(91%) saturate(2341%) hue-rotate(191deg) brightness(98%) contrast(101%);\n  cursor: pointer;\n}\n\n.highlight-box {\n  background-color: rgba(51, 102, 204, 0.1);\n  border-radius: 10px;\n  padding: 3%;\n}\n\n.normal-box {\n  padding: 3%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
