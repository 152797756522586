// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h2 {
  font-family: Montserrat, sans-serif !important;
  font-weight: 600 !important;
  color: #004884 !important;
}
h3 {
  font-family: Montserrat, sans-serif !important;
  font-weight: 600 !important;
  color: #004884 !important;
}
p {
  display: block;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}
h5 {
  font-family: Montserrat, sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  color: #004884 !important;
  line-height: 1.6875em !important;
}
label {
  font-family: Work Sans, sans-serif !important;
  color: #4b4b4b !important;
}

.gov-co-form-group>div>select, .gov-co-form-group>select, .gov-co-form-group input {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,8CAA8C;EAC9C,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,8CAA8C;EAC9C,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,kCAAkC;EAClC,gCAAgC;EAChC,mCAAmC;EACnC,iCAAiC;AACnC;AACA;EACE,8CAA8C;EAC9C,8BAA8B;EAC9B,2BAA2B;EAC3B,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\nh2 {\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 600 !important;\n  color: #004884 !important;\n}\nh3 {\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 600 !important;\n  color: #004884 !important;\n}\np {\n  display: block;\n  margin-block-start: 1em !important;\n  margin-block-end: 1em !important;\n  margin-inline-start: 0px !important;\n  margin-inline-end: 0px !important;\n}\nh5 {\n  font-family: Montserrat, sans-serif !important;\n  font-size: 1.125rem !important;\n  font-weight: 600 !important;\n  color: #004884 !important;\n  line-height: 1.6875em !important;\n}\nlabel {\n  font-family: Work Sans, sans-serif !important;\n  color: #4b4b4b !important;\n}\n\n.gov-co-form-group>div>select, .gov-co-form-group>select, .gov-co-form-group input {\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
