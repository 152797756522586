import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_document: 0,
  documents: {
    acepto_terminos_condiciones: false,
    autorizo_tratamiento_datos: false,
    id_solicitud : 0 ,
    observaciones:"",
    nombre_carta_solicitud: "",
    nombre_documento_identidad: "",
    nombre_tradicion_libertad: "",
    nombre_cartificacion_catastral: "",
    nombre_manzana_catastral: "",
    nombre_estudio_valoracion: "",
    nombre_planos_originales: "",
    nombre_licencia_construccion_original: "",
    nombre_licencia_construccion_posterio: "",
    nombre_levantamiento_arquitectonico: "",
    nombre_registro_fotografico: "",
  },
};

export const DocumentsSlide = createSlice({
  name: "documentos",
  initialState,
  reducers: {
    getDocs: (state, actions) => {
      state.documents = actions.payload;
    },
    getOneDocs: (state, actions) => {
      state.id_document = actions.payload;
    },
    changeTextDocts: (state, actions) => {
      const { name, value } = actions.payload;

      if (name === "observaciones") {
        state.documents.observaciones = value;
      } else if (name === "checkTerms") {
        state.documents.acepto_terminos_condiciones = value;
      } else if (name === "checkPrivacy") {
        state.documents.autorizo_tratamiento_datos = value;
      }
    },
 
  },
});

export const {
    getDocs,
    getOneDocs,
    changeTextDocts
} = DocumentsSlide.actions;
export default DocumentsSlide.reducer;
