import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type_person:0,
    id_person:0,
    data_person: {},
}
export const personalSlide = createSlice({
  name: "personal",
  initialState,
  reducers: {
    getDataInformations: (state, actions) => {
      state.data_person = actions.payload;
    },
    postTypePerson: (state,actions) =>{
        state.type_person = actions.payload;
    },
    getIdPerso: (state,actions) =>{
      state.id_person = actions.payload;
    }
  },
});

export const { getDataInformations, postTypePerson,getIdPerso} = personalSlide.actions;

export default personalSlide.reducer;
