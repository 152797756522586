import axiosBase from "../../utils/axios/axios";


export const getConsultaTramite = async (cedula, fechaExp, tipo) => {
    try {
      const response = await axiosBase.get(
        `Solicitud_creada/Consulta_tramite/${tipo}|${cedula}|${fechaExp}`,
      );
      return response.data;
    } catch (error) {
        throw error;
    }
};