import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_requestTypes: 0,
  id_solicitud: 0,
  id_tipoBien: 0,
  id_formated: 0,
  fecha_solicitud: "",
  hora_solicitud: "",
  requestT: {
    nombre_propietario: "",
    segundo_nombre_propietario: "",
    apellido_propietario: "",
    segundo_apellido_propietario: "",
    direccion_propietario: {
      tipo_via: "",
      numero_via: "",
      letra: "",
      bis: "",
      cuadrante: "",
      via_generadora: "",
      letra2: "",
      numero_dir: "",
      cuadrante2: "",
      complemento: "",
    },
    tipo_solcitud: 0,
    tipo_bien: 0,
    calidad_actual: 0,
    unico_propietario: 0,
    tipo_persona_propietario: 0,
    razon_social: "",
    interes: 0,
    conoce_el_propietario: 0,
    otros_propietarios_natural: [
      {
        id: 1,
        nombres_natural: "",
        apellidos_natural: "",
        direccion_natural: "",
        conoce_propietario_natural: "",
      }
    ],
    otros_propietarios_juridico: [
      {
        id: 1,
        razon_social_juridico: "",
        direccion_juridico: "",
        conoce_propietario_juridico: "",
      }
    ]
  },
};

export const requestTypesSlide = createSlice({
  name: "tipo_solicitud",
  initialState,
  reducers: {
    getRequestTypes: (state, actions) => {
      state.requestT = actions.payload;
    },
    getOneRequestTypes: (state, actions) => {
      state.id_requestTypes = actions.payload;
    },
    getTypeSolici: (state, actions) => {
      state.id_solicitud = actions.payload;
    },
    getTypeBien: (state, actions) => {
      state.id_tipoBien = actions.payload;
    },
    changeText: (state, actions) => {
      const { name, value } = actions.payload;
      if (name === "firstName") {
        state.requestT.nombre_propietario = value;
      } else if (name === "secondName") {
        state.requestT.segundo_nombre_propietario = value;
      } else if (name === "firstLastName") {
        state.requestT.apellido_propietario = value;
      } else if (name === "secondLastName") {
        state.requestT.segundo_apellido_propietario = value;
      } else if (name === "businessName") {
        state.requestT.razon_social = value;
      } else if (name === "requestType") {
        state.requestT.tipo_solcitud = value;
      } else if (name === "typeBien") {
        state.requestT.tipo_bien = value;
        state.requestT.calidad_actual = 0;
      } else if (name === "typeCalidad") {
        state.requestT.calidad_actual = value;
      } else if (name === "actingType") {
        state.requestT.interes = value;
      } else if (name === "typePerson") {
        state.requestT.tipo_persona_propietario = value;
      } else if (name === "unicoPropietario") {
        state.requestT.unico_propietario = parseInt(value); 
      } else if (name === "conocePropietario") {
        state.requestT.conoce_el_propietario = parseInt(value); 
      } else if (name === "address") {
        if (value.name === "via") {
          state.requestT.direccion_propietario.tipo_via = value.value;
        } else if (value.name === "numeroVia") {
          state.requestT.direccion_propietario.numero_via = value.value;
        } else if (value.name === "letra") {
          state.requestT.direccion_propietario.letra = value.value;
        } else if (value.name === "bis") {
          state.requestT.direccion_propietario.bis = value.value === false ? "" : "BIS";
        } else if (value.name === "cuadrante") {
          state.requestT.direccion_propietario.cuadrante = value.value;
        } else if (value.name === "viaGeneradora") {
          state.requestT.direccion_propietario.via_generadora = value.value;
        } else if (value.name === "letraSecundaria") {
          state.requestT.direccion_propietario.letra2 = value.value;
        } else if (value.name === "numeroViaSecundaria") {
          state.requestT.direccion_propietario.numero_dir = value.value;
        } else if (value.name === "cuadranteSecundario") {
          state.requestT.direccion_propietario.cuadrante2 = value.value;
        } else if (value.name === "complemento") {
          state.requestT.direccion_propietario.complemento = value.value;
        }
      }
    },
    setUnicoPropi: (state, actions) => {
      state.requestT.unico_propietario = parseInt(actions.payload);
    },
    setConocePro: (state, actions) => {
      state.requestT.conoce_el_propietario = parseInt(actions.payload);
    },
    setNamePro:(state,actions) =>{
      state.requestT.nombre_propietario = actions.payload;
    },
    setDirecPro:(state,actions) =>{
      state.requestT.direccion_propietario = actions.payload;
    },
    setNameLastPro:(state,actions) =>{
      state.requestT.segundo_nombre_propietario = actions.payload;
    },
    setLastPro:(state,actions) =>{
      state.requestT.apellido_propietario = actions.payload;
    },
    setLastNamePro:(state,actions) =>{
      state.requestT.segundo_apellido_propietario = actions.payload;
    },
    setTypeNumPro:(state,actions) =>{
      state.requestT.tipo_persona_propietario = actions.payload;
    },
    setRazonSocialPro:(state,actions) =>{
      state.requestT.razon_social = actions.payload;
    },
    changeMultipleNatural: (state,actions) => {
      const {rowData, value, dataKey} = actions.payload;
      const newData = [...state.requestT.otros_propietarios_natural];
      const index = newData.findIndex((item) => item.id === rowData.id);
      newData[index][dataKey] = value;
      state.requestT.otros_propietarios_natural = newData;
    },
    addMultipleNatural: (state,actions) => {
      const newData = [...state.requestT.otros_propietarios_natural];
      const id = state.requestT.otros_propietarios_natural.length > 0 ? state.requestT.otros_propietarios_natural[state.requestT.otros_propietarios_natural.length - 1].id + 1 : 1;
      newData.push(
        { 
          id, 
          nombres_natural: "",
          apellidos_natural: "",
          direccion_natural: "",
          conoce_propietario_natural: ""
        }
      );
      state.requestT.otros_propietarios_natural = newData;
    },
    addOneMultipleNatural: (state,actions) => {
      state.requestT.otros_propietarios_natural = actions.payload;
    },
    addOneMultipleJuridico: (state,actions) => {
      state.requestT.otros_propietarios_juridico = actions.payload;
    },
    removeMultipleNatural: (state,actions) => {
      const { rowData } = actions.payload;
      const newData = state.requestT.otros_propietarios_natural.filter((item) => item.id !== rowData.id);
      state.requestT.otros_propietarios_natural = newData;
    },
    changeMultipleJuridica: (state,actions) => {
      const {rowData, value, dataKey} = actions.payload;
      const newData = [...state.requestT.otros_propietarios_juridico];
      const index = newData.findIndex((item) => item.id === rowData.id);
      newData[index][dataKey] = value;
      state.requestT.otros_propietarios_juridico = newData;
    },
    addMultipleJuridica: (state,actions) => {
      const newData = [...state.requestT.otros_propietarios_juridico];
      const id = state.requestT.otros_propietarios_juridico.length > 0 ? state.requestT.otros_propietarios_juridico[state.requestT.otros_propietarios_juridico.length - 1].id + 1 : 1;
      newData.push(
        { 
          id, 
          razon_social_juridico: "",
          direccion_juridico: "",
          conoce_propietario_juridico: ""
        }
      );
      state.requestT.otros_propietarios_juridico = newData;
    },
    removeMultipleJuridica: (state,actions) => {
      const { rowData } = actions.payload;
      const newData = state.requestT.otros_propietarios_juridico.filter((item) => item.id !== rowData.id);
      state.requestT.otros_propietarios_juridico = newData;
    },
    setFechaSolicitud: (state, actions) => {
      state.fecha_solicitud = actions.payload;
    },
    setHoraSolicitud: (state, actions) => {
      state.hora_solicitud = actions.payload;
    },
    setIdSolicitudFormated: (state, actions) => {
      state.id_formated = actions.payload;
    }
  },
});

export const {
  getRequestTypes,
  getOneRequestTypes,
  getTypeSolici,
  getTypeBien,
  changeText,
  setUnicoPropi,
  setNamePro,
  setDirecPro,
  setNameLastPro,
  setLastPro,
  setLastNamePro,
  setTypeNumPro,
  setRazonSocialPro,
  changeMultipleNatural,
  addOneMultipleJuridico,
  addOneMultipleNatural,
  setConocePro,
  addMultipleNatural,
  removeMultipleNatural,
  changeMultipleJuridica,
  addMultipleJuridica,
  removeMultipleJuridica,
  setFechaSolicitud,
  setHoraSolicitud,
  setIdSolicitudFormated
} = requestTypesSlide.actions;
export default requestTypesSlide.reducer;
