import "./Home.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getonInitConsult,
  getonInitCreate,
} from "../../shared/slice/reducers/stepper.reducers";
import { persistor } from "../..";

function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const goInitCreate = () => {
    persistor.purge();
    
    dispatch(getonInitCreate(true));
    dispatch(getonInitConsult(false));
    navigate("/datosPersonales");
  };
  const goInitConsult = () => {
    navigate("/consultarTramite");
    dispatch(getonInitCreate(false));
    dispatch(getonInitConsult(true));
    
  };
  useEffect(() => {
    document.title = `Trámite BIC - Inicio`;
    dispatch(getonInitCreate(false));
    dispatch(getonInitConsult(false));
  }, [location.pathname]);

  return (
    <div className="home-styles form-container">
      <h3 className="title-style mt-2">
        Declaratoria, revocatoria o cambio de nivel de intervención de un bien
        de interés cultural del ámbito Distrital
      </h3>
      <nav style={{ "--bs-breadcrumb-divider": ">" }} aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Inicio
          </li>
        </ol>
      </nav>
      <hr className="hrC"></hr>
      <h3 className="title-p mt-1">Hago mi solicitud</h3>
      <div className="box-text">
        <p className="mt-4">
          <strong>
            La Secretaría de Cultura, Recreación y Deporte invita a la
            ciudadanía en general a participar en el proceso de declaratoria,
            revocatoria y/o cambio de nivel de intervención de bienes de interés
            cultural del ámbito distrital, como herramienta para la
            identificación y actualización permanente del inventario del
            patrimonio cultural construido en Bogotá.
          </strong>
        </p>
        <p className="mt-3">
          <em>
            En este sentido, es importante señalar lo indicado por el artículo
            2.4.1.10. del Decreto 1080 de 2015, respecto de los bienes de
            interés cultural: “Son bienes de interés cultural BIC, aquellos que
            por sus valores y criterios representan la identidad nacional,
            declarados mediante acto administrativo por la entidad competente,
            quedando sometidos al régimen especial de protección definido en la
            ley; estos pueden ser de naturaleza mueble, inmueble o paisajes
            culturales.
          </em>
        </p>
        <p className="mt-1">
          <em>
            Los bienes del patrimonio arqueológico se consideran bienes de
            interés cultural de la nación de conformidad con lo estipulado en la
            Ley 397 de 1997 modificada por la Ley 1185 de 2008.”
          </em>
        </p>
        <p className="mt-2">
          Esta gestión se adelanta a partir de la competencia asignada por el
          artículo 4 del Decreto 522 de 2023
          <em>
            “Por medio del cual se reglamenta el Sistema Distrital de Patrimonio
            Cultural y se dictan otras disposiciones”, en el literal c que
            establece “Efectuar la declaratoria o revocatoria de los bienes de
            interés cultural del ámbito distrital o modificación del nivel de
            intervención de los mismos, previo concepto del Consejo Distrital de
            Patrimonio Cultural.”
          </em>
        </p>
      </div>

      <div className="form-row">
        <h5 className="mt-4">Acciones del trámite</h5>
      </div>
      <div className="form-row mt-4">
        
          <div className="card gov-co-tramite-card  wiC" onClick={goInitCreate}>
            <div className="card-header text-center">Crear solicitud</div>
            <div className="card-body">
              <p className="card-text">
                Inicie el trámite de declaratoria, revocatoria o cambio de nivel
                de intervención de un bien de interés cultural del ámbito
                Distrital.
              </p>
            </div>
          </div>
          <div
            className="card gov-co-tramite-card wiC ml-3"
            onClick={goInitConsult}
          >
            <div className="card-header text-center">Consultar trámite</div>
            <div className="card-body">
              <p className="card-text">
                Consultar el trámite de declaratoria, revocatoria o cambio de
                nivel de intervención de un bien de interés cultural del ámbito
                Distrital.
              </p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Home;
