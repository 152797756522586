import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_descriptionGood: 0,
  descriptionGood: {
    id: 0,
    atributos_del_bien: [],
    criterios_de_valoracion: [],
    area_predio: 0,
    area_construida: 0,
    pisos: 0,
    uso_original: "",
    otro_uso: "",
    uso_actual: "",
    agno_construccion: 0,
    nombre_arquitecto: "",
    licencia_contruccion: "",
    otras_licencias: "",
    acto_adminitrativo_bien: "",
    razon_principal_solicitud: "",
    id_solicitud: 0,
  },
};

export const descriptionGoodSlide = createSlice({
  name: "descripcion_solicitud",
  initialState,
  reducers: {
    getDescriptionGood: (state, actions) => {
      state.descriptionGood = actions.payload;
    },
    getOneDescriptionGood: (state, actions) => {
      state.id_descriptionGood = actions.payload;
    },
    changeTextDescription: (state, actions) => {
      const { name, value } = actions.payload;
      if (name === "razonSolicitud") {
        state.descriptionGood.razon_principal_solicitud = value;
      }
      else if (name === "usoActual") {
        state.descriptionGood.uso_actual = value;
      }
      else if (name === "otroUso") {
        state.descriptionGood.otro_uso = value;
      }
      else if (name === "añoConstruccion") {
        state.descriptionGood.agno_construccion = value;
      }
      else if (name === "arquitecto") {
        state.descriptionGood.nombre_arquitecto = value;
      }
      else if (name === "licenciaOriginal") {
        state.descriptionGood.licencia_contruccion = value;
      }
      else if (name === "otrasLicencias") {
        state.descriptionGood.otras_licencias = value;
      } else if (name === "actoAdministrativo"){
        state.descriptionGood.acto_adminitrativo_bien = value;
      } else if (name === "areaPredio"){
        state.descriptionGood.area_predio = value;
      }
       else if (name === "areaConstruida"){
        state.descriptionGood.area_construida = value;
      }
       else if (name === "numeroPisos"){
        state.descriptionGood.pisos = value;
      }
       else if (name === "usoOriginal"){
        state.descriptionGood.uso_original = value;
      }
    
    },
    changeSelect:(state,actions) =>{
      const { name, data } = actions.payload;
      //state.descriptionGood.criterios_de_valoracion = [...state.descriptionGood.criterios_de_valoracion, actions.payload[0]];
      if (name === "criterios") {
        state.descriptionGood.criterios_de_valoracion = data;
      } else if (name === "valores") {
        state.descriptionGood.atributos_del_bien = data;
      }
      
    }
  },
});

export const {
  getDescriptionGood,
  getOneDescriptionGood,
  changeTextDescription,
  changeSelect
} = descriptionGoodSlide.actions;
export default descriptionGoodSlide.reducer;
