import { Field, Form } from "react-final-form";
import SelectAsyncPaginate from "../AsyncSelects/SelectAsyncPaginate";
import { useEffect, useRef, useState } from "react";
import compass from "../../assets/icons/compass.png";
import { addressInputValidations } from "../../utils/helpers/validations/AddressInputValidations";
import { preventNegativeValues } from "../../utils/helpers/others/others";

const DireccionInput = ({
  fieldLabel,
  address,
  setAddress,
  handleAddressChange,
  defaultValue,
}) => {
  const nomeclaturaVias = [
    { id: 1, value: "CL", name: "Calle" },
    { id: 2, value: "KR", name: "Carrera" },
    { id: 3, value: "AV", name: "Avenida" },
    { id: 4, value: "AK", name: "Avenida Carrera" },
    { id: 5, value: "AC", name: "Avenida Calle" },
    { id: 6, value: "AU", name: "Autopista" },
    { id: 7, value: "DG", name: "Diagonal" },
    { id: 8, value: "TV", name: "Transversal" },
    { id: 9, value: "AVIAL", name: "Anillo Vial" },
    { id: 10, value: "CIR", name: "Circular" },
    { id: 11, value: "CLJ", name: "Callejón" },
  ];

  const letras = [
    { id: 1, name: "A", value: "A" },
    { id: 2, name: "B", value: "B" },
    { id: 3, name: "C", value: "C" },
    { id: 4, name: "D", value: "D" },
    { id: 5, name: "E", value: "E" },
    { id: 6, name: "F", value: "F" },
    { id: 7, name: "G", value: "G" },
    { id: 8, name: "H", value: "H" },
    { id: 9, name: "I", value: "I" },
    { id: 10, name: "J", value: "J" },
    { id: 11, name: "K", value: "K" },
    { id: 12, name: "L", value: "L" },
    { id: 13, name: "M", value: "M" },
    { id: 14, name: "N", value: "N" },
    { id: 15, name: "O", value: "O" },
    { id: 16, name: "P", value: "P" },
    { id: 17, name: "Q", value: "Q" },
    { id: 18, name: "R", value: "R" },
    { id: 19, name: "S", value: "S" },
    { id: 20, name: "T", value: "T" },
    { id: 21, name: "U", value: "U" },
    { id: 22, name: "V", value: "V" },
    { id: 23, name: "W", value: "W" },
    { id: 24, name: "X", value: "X" },
    { id: 25, name: "Y", value: "Y" },
    { id: 26, name: "Z", value: "Z" },
  ];

  const cuadrantes = [
    { id: 0, name: "Seleccione", value: "" },
    { id: 1, name: "Norte", value: "Norte" },
    { id: 2, name: "Sur", value: "Sur" },
    { id: 3, name: "Este", value: "Este" },
    { id: 4, name: "Oeste", value: "Oeste" },
  ];
  const [areFieldsActivated, setAreFieldsActivated] = useState(false);

  const [via, setVia] = useState("");

  const [cuadrante, setCuadrante] = useState("");
  const [cuadranteSecundario, setCuadranteSecundario] = useState("");
  const [letra, setLetra] = useState("");
  const [letraSecundaria, setLetraSecundaria] = useState("");

  const [errors, setErrors] = useState({
    via: address?.via === "" ? "Este campo es requerido." : "",
    numeroVia:
      address?.numeroVia === "" ? "Este campo es requerido." : "",
    viaGeneradora:
      address?.viaGeneradora === "" ? "Este campo es requerido." : "",
    numeroViaSecundaria:
      address?.numeroViaSecundaria === ""
        ? "Este campo es requerido."
        : "",
  });

  function activateFields() {
    setErrors({
      via: address?.via === "" || address?.via === null || address?.tipo_via === "" || address?.tipo_via === null ? "Este campo es requerido." : "",
      numeroVia:
        address?.numeroVia === "" || address?.numeroVia === null  || address?.numero_via === "" || address?.numero_via === null ? "Este campo es requerido." : "",
      viaGeneradora:
        address?.viaGeneradora === "" || address?.viaGeneradora === null || address?.via_generadora === "" || address?.via_generadora === null ? "Este campo es requerido." : "",
      numeroViaSecundaria:
        address?.numeroViaSecundaria === "" || address?.numeroViaSecundaria === null || address?.numero_dir === "" || address?.numero_dir === null
          ? "Este campo es requerido."
          : "",
    });

    const hasValues = Object.values(address).some((error) => error !== "");
    if (hasValues) {
      const findVia = nomeclaturaVias.find((e) => e.value === address?.via || e.value === address?.tipo_via);
      const findCuadrante = cuadrantes.find((e) => e.value === address?.cuadrante);
      const findLetra = letras.find((e) => e.value === address?.letra);
      const findCuadranteSecundario = cuadrantes.find((e) => e.value === address?.cuadranteSecundario || e.value === address?.cuadrante2);
      const findLetraSecundaria = letras.find((e) => e.value === address?.letraSecundaria || e.value === address?.letra2);

      if (findVia) {
        setVia({
          label: findVia.name,
          value: findVia.value,
        });
      }
      if (findCuadrante) {
        setCuadrante({
          label: findCuadrante.name,
          value: findCuadrante.value,
        });
      }
      if (findLetra) {
        setLetra({
          label: findLetra.name,
          value: findLetra.value,
        });
      }
      if (findCuadranteSecundario) {
        setCuadranteSecundario({
          label: findCuadranteSecundario.name,
          value: findCuadranteSecundario.value,
        });
      }
      if (findLetraSecundaria) {
        setLetraSecundaria({
          label: findLetraSecundaria.name,
          value: findLetraSecundaria.value,
        });
      }
    }

    setAreFieldsActivated(!areFieldsActivated);
  }

  function handleBisChange(event) {
    const { name } = event.target;
    const value = event.target.checked;
    setAddress({
      ...address,
      [name]: value ? "BIS" : "",
    });
  }

  function handleErrorsChange(event) {
    let name;
    let value;

    if (!event.target) {
      name = event.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }
    addressInputValidations(name, value, errors, setErrors);
  }

  return (
    <>
      <div
        className="form-group gov-co-form-group w-100"
        style={{ cursor: "pointer" }}
        onClick={activateFields}
      > 
        <div style={{height: "40px"}}>
          <label className="form-label" htmlFor="address">
            {fieldLabel}
          </label>
        </div>
        <div className="input-group">
          <input
            id="address"
            name="address"
            className="form-control"
            value={defaultValue}
            type="text"
            readOnly
            style={{ cursor: "pointer" }}
          ></input>
          <span
            className="input-group-text"
            style={{ backgroundColor: "blue" }}
          >
            <img
              alt="icon"
              src={compass}
              style={{
                width: "25px",
                filter:
                  "invert(100%) sepia(98%) saturate(8%) hue-rotate(177deg) brightness(102%) contrast(105%)",
              }}
            />
          </span>
        </div>
      </div>

      {areFieldsActivated ? (
        <>
          <div className="row form-group gov-co-form-group w-100">
            <div className="col-lg-3 mt-3">
              <div style={{height: "40px"}}>
                <label>Tipo vía principal *</label>
              </div>
              <SelectAsyncPaginate
                options={nomeclaturaVias}
                value={via}
                onChange={(value) => {
                  setVia(value);
                  handleAddressChange(value);
                  handleErrorsChange(value);
                }}
                name={"via"}
                fieldName={"name"}
                displayName={"name"}
                displayValue={"value"}
              />
              {errors && errors.via ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.via}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-lg-2 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="numeroVia">
                  Número vía *
                </label>
              </div>
              <input
                id="numeroVia"
                name="numeroVia"
                className="form-control"
                placeholder={"Número"}
                type="number"
                min={"0"}
                onKeyDown={preventNegativeValues}
                value={address?.numeroVia || address?.numero_via}
                onChange={(event) => {
                  handleAddressChange(event);
                  handleErrorsChange(event);
                }}
              ></input>
              {errors && errors.numeroVia ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.numeroVia}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-lg-2 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="letra">
                  Letra (Opcional)
                </label>
              </div>
              <SelectAsyncPaginate
                options={letras}
                onChange={(value) => {
                  setLetra(value);
                  handleAddressChange(value);
                }}
                name={"letra"}
                value={letra}
                fieldName={"name"}
                displayName={"name"}
                displayValue={"value"}
              />
            </div>
            <div className="col-lg-2 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="bis">
                  Bis (Opcional)
                </label>
              </div>

              <input
                id="bis"
                name="bis"
                className="form-control"
                type="checkbox"
                defaultChecked={address?.bis}
                onChange={(event) => {
                  handleBisChange(event);
                  handleAddressChange(event);
                }}
              ></input>
            </div>
            <div className="col-lg-3 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="cuadrante">
                  Cuadrante (Opcional)
                </label>
              </div>
              <SelectAsyncPaginate
                options={cuadrantes}
                onChange={(value) => {
                  setCuadrante(value);
                  handleAddressChange(value);
                }}
                name={"cuadrante"}
                fieldName={"name"}
                displayName={"name"}
                displayValue={"value"}
                value={cuadrante}
              />
            </div>

            <div className="col-lg-3 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="viaGeneradora">
                  Vía generadora *
                </label>
              </div>

              <input
                id="viaGeneradora"
                name="viaGeneradora"
                className="form-control"
                placeholder={"Vía generadora"}
                type="number"
                min={"0"}
                onKeyDown={preventNegativeValues}
                value={address?.viaGeneradora || address?.via_generadora}
                onChange={(event) => {
                  handleAddressChange(event);
                  handleErrorsChange(event);
                }}
              ></input>
              {errors && errors.viaGeneradora ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.viaGeneradora}
                  </small>
                </>
              ) : null}
            </div>

            <div className="col-lg-2 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="letraSecundaria">
                  Letra (Opcional)
                </label> 
              </div>

              <SelectAsyncPaginate
                options={letras}
                onChange={(value) => {
                  setLetraSecundaria(value);
                  handleAddressChange(value);
                }}
                name={"letraSecundaria"}
                fieldName={"name"}
                displayName={"name"}
                displayValue={"value"}
                value={letraSecundaria}
              />
            </div>

            <div className="col-lg-3 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="numeroViaSecundaria">
                  Número *
                </label>
              </div>

              <input
                id="numeroViaSecundaria"
                name="numeroViaSecundaria"
                className="form-control"
                placeholder={"Número vía secundaria"}
                type="number"
                min={"0"}
                onKeyDown={preventNegativeValues}
                value={address?.numeroViaSecundaria || address?.numero_dir}
                onChange={(event) => {
                  handleAddressChange(event);
                  handleErrorsChange(event);
                }}
              ></input>
              {errors && errors.numeroViaSecundaria ? (
                <>
                  <small
                    className="form-text"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    {errors.numeroViaSecundaria}
                  </small>
                </>
              ) : null}
            </div>
            <div className="col-lg-3 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="cuadranteSecundario">
                  Cuadrante (Opcional)
                </label>
              </div>

              <SelectAsyncPaginate
                options={cuadrantes}
                onChange={(value) => {
                  setCuadranteSecundario(value);
                  handleAddressChange(value);
                }}
                name={"cuadranteSecundario"}
                fieldName={"name"}
                displayName={"name"}
                displayValue={"value"}
                value={cuadranteSecundario}
              />
            </div>

            <div className="col-lg-12 mt-3">
              <div style={{height: "40px"}}>
                <label className="form-label" htmlFor="complemento">
                  Complemento (Opcional)
                </label> 
              </div>

              <input
                id="complemento"
                name="complemento"
                className="form-control"
                placeholder={"Casa, apto, torre, bloque, etc"}
                type="text"
                value={address?.complemento}
                onChange={(event) => {
                  handleAddressChange(event);
                }}
              ></input>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DireccionInput;
