import axiosBase from "../../utils/axios/axios";


export const postDescripcionBien = async (data) => {
    try {
        const response = await axiosBase.post("Descripcion_bien/descripcion_bien/", data);
        return response.data;
    } catch (error) {
        throw error
    }
}
export const putDescripcionBien = async (id,data) => {
    try {
        const response = await axiosBase.put(`Descripcion_bien/descripcion_bien/${id}/`, data);
        return response.data;
    } catch (error) {
        throw error
    }
}

export const getDescripcionBienById = async (id) => {
    try {
        const response = await axiosBase.get(`Descripcion_bien/descripcion_bien/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const postIdentificacionBien = async (data) => {
    try {
        const response = await axiosBase.post("Identificacion_bien/identificacion_bien/", data);
        return response.data;
    } catch (error) {
        throw error
    }
}
export const putIdentificacionBien = async (id,data) => {
    try {
        const response = await axiosBase.put(`Identificacion_bien/identificacion_bien/${id}/`, data);
        return response.data;
    } catch (error) {
        throw error
    }
}

export const getIdentificacionBienById = async (id) => {
    try {
        const response = await axiosBase.get(`Identificacion_bien/identificacion_bien/${id}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}