export const getYearsDifference = (dateToCheck1, dateToCheck2) => {
  let years =
    new Date(dateToCheck1).getFullYear() - new Date(dateToCheck2).getFullYear();
  let month =
    new Date(dateToCheck1).getMonth() - new Date(dateToCheck2).getMonth();

  const numerMonth = Math.sign(month);

  if (numerMonth !== 1 && numerMonth !== 0) {
    years -= 1;
  } else if (numerMonth === 0) {
    const day1 = parseInt(dateToCheck1.slice(-2));
    const day2 = parseInt(dateToCheck2.slice(-2));
    let dateDiff = day1 - day2;
    const numerDiff = Math.sign(dateDiff);
    if (numerDiff !== 1 && numerDiff !== 0) {
      years -= 1;
    }
  }
  return years;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
