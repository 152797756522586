import { getAtributosBien } from "../../../services/descripcionBien/getAtributos";
import { getCriteriosBien } from "../../../services/descripcionBien/getCriterios";


export const formatRequestTypeData = (data) => {
    return {
        nombre_propietario: data.nombre_propietario,
        segundo_nombre_propietario: data.segundo_nombre_propietario,
        apellido_propietario: data.apellido_propietario,
        segundo_apellido_propietario: data.segundo_apellido_propietario,
        direccion_propietario: {
            tipo_via: data.tipo_via,
            numero_via: data.numero_via,
            letra: data.letra,
            bis: data.bis === false ? "" : "BIS",
            cuadrante: data.cuadrante,
            via_generadora: data.via_generadora,
            letra2: data.letra2,
            numero_dir: data.numero_dir,
            cuadrante2: data.cuadrante2,
        },
        tipo_solcitud: data.tipo_solcitud,
        tipo_bien: data.tipo_bien,
        calidad_actual: data.calidad_actual,
        unico_propietario: data.unico_propietario,
        tipo_persona_propietario: data.tipo_persona_propietario,
        razon_social: data.tipo_persona_propietario === 1 ? "NA" : data.razon_social,
        interes: data.interes,
        conoce_el_propietario: data.calidad_actual !== 1 ? data.conoce_el_propietario : 2,
        id_persona: data.id_persona,
        otros_propietarios_natural: data.otros_propietarios_natural,
        otros_propietarios_juridico: data.otros_propietarios_juridico,
    };
}

export const formatMultiNatural = (data) => {
    return data.map(e => {
        return {
            nombres_natural: e.nombres_natural,
            apellidos_natural: e.apellidos_natural,
            direccion_natural: e.direccion_natural,
            conoce_propietario_natural: e.conoce_propietario_natural
        }
    });
}

export const formatMultiJuridico = (data) => {
    return data.map(e => {
        return {
            razon_social_juridico: e.razon_social_juridico,
            direccion_juridico: e.direccion_juridico,
            conoce_propietario_juridico: e.conoce_propietario_juridico
        }
    });
}

export const formatIdentificationData = (data) => {
    return {
        nombre_bien: data.nombre_bien,
        chip: data.chip,
        matricula_inmobiliaria: data.matricula_inmobiliaria,
        direccion_oficial: data.direccion_oficial,
        direccion_oficialObj: {
            via: data.tipo_via_direccion_oficial,
            numeroVia: data.numero_via_direccion_oficial,
            letra: data.letra_direccion_oficial,
            bis: data.bis_direccion_oficial === false ? "" : "BIS",
            cuadrante: data.cuadrante_direccion_oficial,
            viaGeneradora: data.via_generadora_direccion_oficial,
            letraSecundaria: data.letra2_direccion_oficial,
            bisSecundario: data.bis_direccion_oficial === false ? "" : "BIS",
            numeroViaSecundaria: data.numero_dir_direccion_oficial,
            cuadranteSecundario: data.cuadrante2_direccion_oficial,
            complemento: data.complemento_direccion_oficial,
        },
        direccion_secundaria: data.direccion_secundaria,
        direccion_secundariaObj: {
            via: data.tipo_via_direccion_secundaria,
            numeroVia: data.numero_via_direccion_secundaria,
            letra: data.letra_direccion_secundaria,
            bis: data.bis_direccion_secundaria === false ? "" : "BIS",
            cuadrante: data.cuadrante_direccion_secundaria,
            viaGeneradora: data.via_generadora_direccion_secundaria,
            letraSecundaria: data.letra2_direccion_secundaria,
            bisSecundario: data.bis2_direccion_secundaria === false ? "" : "BIS",
            numeroViaSecundaria: data.numero_dir_direccion_secundaria,
            cuadranteSecundario: data.cuadrante2_direccion_secundaria,
            complemento: data.complemento_direccion_secundaria,
        },
        direcciones_anteriores: data.direcciones_anteriores,
        direcciones_anterioresObj: {
            via: data.tipo_via_direcciones_anteriores,
            numeroVia: data.numero_via_direcciones_anteriores,
            letra: data.letra_direcciones_anteriores,
            bis: data.bis_direcciones_anteriores === false ? "" : "BIS",
            cuadrante: data.cuadrante_direcciones_anteriores,
            viaGeneradora: data.via_generadora_direcciones_anteriores,
            letraSecundaria: data.letra2_direcciones_anteriores,
            bisSecundario: data.bis2_direcciones_anteriores === false ? "" : "BIS",
            numeroViaSecundaria: data.numero_dir_direcciones_anteriores,
            cuadranteSecundario: data.cuadrante2_direcciones_anteriores,
            complemento: data.complemento_direcciones_anteriores,
        },
        direccion_declaratoria: data.direccion_declaratoria,
        direccion_declaratoriaObj: {
            via: data.tipo_via_direccion_declaratoria,
            numeroVia: data.numero_via_direccion_declaratoria,
            letra: data.letra_direccion_declaratoria,
            bis: data.bis_direccion_declaratoria === false ? "" : "BIS",
            cuadrante: data.cuadrante_direccion_declaratoria,
            viaGeneradora: data.via_generadora_direccion_declaratoria,
            letraSecundaria: data.letra2_direccion_declaratoria,
            bisSecundario: data.bis2_direccion_declaratoria === false ? "" : "BIS",
            numeroViaSecundaria: data.numero_dir_direccion_declaratoria,
            cuadranteSecundario: data.cuadrante2_direccion_declaratoria,
            complemento: data.complemento_direccion_declaratoria,
        }, 
        zona: data.zona,
        localidad: data.localidad,
        upl: data.upl,
        upz: data.upz,
        barrio: data.barrio,
        vereda: data.vereda,
        numero_manzana: data.numero_manzana,
        numero_lote: data.numero_lote,
        id_solicitud: data.id_solicitud,
    };
}

export const formatDescriptionData = (data) => {

    return {
        area_predio : data.area_predio,
        area_construida : data.area_construida,
        pisos : data.pisos,
        uso_original : data.uso_original,
        otro_uso : data.otro_uso,
        uso_actual : data.uso_actual,
        agno_construccion : data.agno_construccion,
        nombre_arquitecto : data.nombre_arquitecto,
        licencia_contruccion : data.licencia_contruccion,
        otras_licencias : data.otras_licencias,
        acto_adminitrativo_bien : data.acto_adminitrativo_bien,
        razon_principal_solicitud : data.razon_principal_solicitud,
        criterios_de_valoracion : data.criterios_de_valoracion,
        atributos_del_bien : data.atributos_del_bien,
        id_solicitud : data.id_solicitud,
    }
}

export const formatDocumentsData = (data) => {
    return {
        acepto_terminos_condiciones: data.acepto_terminos_condiciones,
        autorizo_tratamiento_datos: data.autorizo_tratamiento_datos,
        observaciones: data.observaciones,
        id_solicitud : data.id_solicitud,
        nombre_carta_solicitud: data.nombre_carta_solicitud,
        nombre_documento_identidad: data.nombre_documento_identidad,
        nombre_tradicion_libertad: data.nombre_tradicion_libertad,
        nombre_cartificacion_catastral: data.nombre_cartificacion_catastral,
        nombre_manzana_catastral: data.nombre_manzana_catastral,
        nombre_estudio_valoracion: data.nombre_estudio_valoracion,
        nombre_planos_originales: data.nombre_planos_originales,
        nombre_licencia_construccion_original: data.nombre_licencia_construccion_original,
        nombre_licencia_construccion_posterio: data.nombre_licencia_construccion_posterio,
        nombre_levantamiento_arquitectonico: data.nombre_levantamiento_arquitectonico,
        nombre_registro_fotografico: data.nombre_registro_fotografico,
    }
}