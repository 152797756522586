import { Routes, Route } from "react-router-dom";
import "./App.css";
import DatosPersonales from "./pages/crear/components/DatosPersonales/DatosPersonales";
import Home from "./pages/home/Home";

import Tramite from "./pages/crear/components/Tramite/tramite";
import Resumen from "./pages/crear/Resumen/Resumen";
import ConsultarTramite from "./pages/consultar/components/ConsultarTramite/ConsultarTramite";
import {useSelector } from "react-redux";
import InfoDialog from "./pages/crear/components/Tramite/components/infoDialog";

import { getEnvVaribles } from "./utils/helpers/getEnvVaribles";
const {API_URL} = getEnvVaribles();

function App() {

  const { onInitCreate, onInitConsult, step, showUserSurvey } = useSelector((state) => state.steps);
  const { id_person } = useSelector((state) => state.personal);

  const { id_requestTypes } = useSelector((state) => state.tipo_solicitud);

  return (
    <div className={`App ${showUserSurvey ? 'mt-0' : 'mt-5'}`}>
      <govco-header
        ishome="true"
        isbeta="false"
        usuario=""
        fixed="false"
        auth_active="false"
        search_active="false"
      ></govco-header>
      <govco-accesibility-bar></govco-accesibility-bar>
      <govco-back-to-top></govco-back-to-top>
      <div className="content">
        <div className="row">
          <div className="col-12">
            <h3 className="title-style mt-2">
              {onInitCreate
                ? "Creación tramite de declaratoria, revocatoria o cambio de nivel de intervención de un bien de interés cultural del ámbito Distrital"
                : onInitConsult &&
                  "Consulta tramite de declaratoria, revocatoria o cambio de nivel de intervención de un bien de interés cultural del ámbito Distrital"}
            </h3>
            {(onInitCreate && step > 0) && <InfoDialog></InfoDialog>}
          </div>
          <div className="col-lg-9 mt-3 ">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route
                path="datosPersonales"
                element={<DatosPersonales />}
              ></Route>
              <Route path="crearTramite" element={<Tramite />}></Route>
              <Route
                path="consultarTramite"
                element={<ConsultarTramite />}
              ></Route>
              <Route path="resumen" element={<Resumen />}></Route>
            </Routes>
          </div>
          <div className="col-lg-3 mt-4">
            <div className="gov-co-servi">
              <component-doubts-contact
                email="ayuda@scrd.gov.co"
                phone="(601) 327 48 50"
                faq="test"
                adicionales='[
            {
                "title": "Atención Presencial",
                "value": "Carrera 8 No 9-83 de lunes a viernes en Jornada continua de 7:30 a.m. a 4;30 p.m"
            }
        ]'
                className="d-flex justify-content-end"
              ></component-doubts-contact>
              {showUserSurvey && 
                <component-qualify-procedure
                  className="d-flex justify-content-end"
                  fullscreen="false"
                  endpoint={`${API_URL}Experiencia_proceso/experiencia_proceso/`}
                  id_tramite={id_requestTypes}
                  usuario={id_person}
                ></component-qualify-procedure>
              }
            </div>
          </div>
        </div>
      </div>
      <govco-footer
        color_fondo="#5d4293"
        titulo_entidad="Secretaría de Cultura, Recreación y Deporte"
        url_logo="https://www.culturarecreacionydeporte.gov.co/sites/default/files/2022-08/Logo-secretaria-cultura-recreacion-deporte-morado.svg"
        url_redirect_logo="https://www.culturarecreacionydeporte.gov.co/"
        alt_url_logo="Logo de la Secretaría de Cultura, Recreación y Deporte"
        description_Logo="Logo de la Secretaría de Cultura, Recreación y Deporte"
        direccion="Cra 8 # 9 - 83, Bogotá - Colombia"
        codigo_postal="111711"
        conmutador="(601) 327 48 50"
        horario_atencion="Lunes a viernes 7:30 a.m. a 4:30 p.m."
        correo_entidad="correspondencia.externa@scrd.gov.co"
        cuenta_twiter="CulturaenBta"
        cuenta_instagram="culturaenbta"
        cuenta_facebook="CulturaenBogota"
        politicas="https://www.culturarecreacionydeporte.gov.co/es/politica-de-privacidad"
        mapa_sitio="https://www.culturarecreacionydeporte.gov.co/sitemap"
        terminos_condiciones="#"
      ></govco-footer>
    </div>
  );
}

export default App;
