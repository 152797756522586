export const addressInputValidations = (name, value, errors, setErrors) => {
    switch (name) {
        case "via":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido" })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;

        case "numeroVia":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido" })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "viaGeneradora":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido" })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        case "numeroViaSecundaria":
            if (value === '') setErrors({...errors, [name] : "Este campo es requerido" })
            else {
                setErrors({...errors, [name]: ""});
            }
            break;
        default:
            break;
    }
}