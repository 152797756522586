import { AsyncPaginate } from "react-select-async-paginate";

export default function SelectAsyncPaginate({
  options,
  value,
  name,
  onChange,
  fieldName,
  displayValue,
  displayName,
  multiple = false,
  isDisabled = false,
  isSearchable = true,
  onBlur
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "42px",
      height: "42px",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "42px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "42px",
    }),
  };

  const loadOptions = async (search, prevOptions) => {
    let filteredOptions = [];

    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = options.filter((option) => {
        return option[fieldName].toLowerCase().includes(searchLower);
      });
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;

    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: formatOptions(slicedOptions),
      hasMore,
    };
  };

  const formatOptions = (options) => {

    const newOptions = options?.map((item) => ({
      value: item[displayValue],
      label: item[displayName],
      name: name,
    }));

    newOptions.unshift({name: name, label: "Seleccione", value: ""});

    return newOptions;
  }

  const handleBlur = () => {
    if(onBlur) {
      onBlur(name, value);
    }
  }

  return (
    <AsyncPaginate
      styles={customStyles}
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      isMulti={multiple}
      placeholder={"Seleccione"}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      onBlur={handleBlur}
    />
  );
}
