import { useSelector } from "react-redux";
import "./Resumen.css";
import { status200ok } from "../../../utils/helpers/validations/statusCode";
import constructAlert from "../../../utils/sweetalert/constructAlert";
import { persistor } from "../../..";
import { useLocation, useNavigate } from "react-router-dom";
import { postResumen } from "../../../services/resumen/resumen";
import { useEffect } from "react";
import Swal from "sweetalert2";
import constructTwoButtonsAlert from "../../../utils/sweetalert/constructTwoButtonsAlert";
import goback from "../../../assets/icons/go-back-2.png";
import { setShowUserSurvey } from "../../../shared/slice/reducers/stepper.reducers";
import { useDispatch } from "react-redux";

const Resumen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { id_person, data_person } = useSelector((state) => state.personal);
  const { finish } = useSelector((state) => state.steps);
  const { id_requestTypes, id_solicitud, requestT, id_tipoBien } = useSelector(
    (state) => state.tipo_solicitud
  );
  const { id_identificationWell, identificationWell } = useSelector(
    (state) => state.identificacion_bien
  );
  const { id_descriptionGood, descriptionGood } = useSelector(
    (state) => state.desciption_bien
  );
  const { id_document, documents } = useSelector((state) => state.documents);
  const onSubmit = async () => {
    const data = {
      id_usuario: id_person,
      id_tipo_solicitud: id_requestTypes,
      id_identificacion_bien: id_identificationWell,
      id_descripcion_bien: id_descriptionGood,
      id_documentos: id_document,
      no_radicado_orfeo: "N/A",
      estado_solicitud: "Creada",
    };

    Swal.fire({
      title: "Enviando información, por favor esperé.",
      allowOutsideClick: false,
      width: "512px",
      confirmButtonColor: "#3366CC",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await postResumen(data);
      if ((response.status = status200ok)) {
        Swal.close();
        constructAlert(
          "",
          `¡¡Su solicitud fue radicado con éxito con el número ${response.data.Radicado_Orfeo}!`,
          "success",
          "Continuar"
        ).then(() => {
          dispatch(setShowUserSurvey(true));
          navigate("/");
          //persistor.purge();
          // window.location.reload();
        });
      }
    } catch (error) {
      constructAlert("", error.response.data.message, "error", "Aceptar");
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
    document.title = `Trámite BIC - Resumen`;
  }, [location.pathname]);

  const goBackTo = () => {
    if (finish) {
      constructTwoButtonsAlert(
        "",
        "¡Recuerda que si sales de la creación de solicitud sin guardar o sin completar todos los pasos, luego podrás continuar desde la sección “Consultar trámite”!",
        "info",
        "Continuar",
        "Cancelar"
      ).then((res) => {
        if (res.value === true) {
          persistor.purge();
          navigate("/");
          window.location.reload();
        }
      });
    } else {
      persistor.purge();
      navigate("/");
      window.location.reload();
    }
  };

  return (
    <>
      {" "}
      <div className="form-container">
        <div className="resumen-container">
          <div className="header-style">
            <img
              src={goback}
              style={{ width: "106px" }}
              alt="icon"
              className="goback-style"
              onClick={goBackTo}
            />
            <h3 className="text-center">Resumen</h3>
          </div>
          {finish && (
            <>
              <h5 className="mt-4">Datos solicitante</h5>
              <div className="container ">
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex flex-row align-items-baseline ">
                      <p className="text-title">Tipo de identificación: </p>
                      <p className="ml-1">{data_person?.numero}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-row align-items-baseline ">
                      <p className="text-title">Primer nombre: </p>
                      <p className="ml-1">
                        {""}
                        {data_person?.primer_nombre}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-row align-items-baseline ">
                      <p className="text-title">Primer apellido:</p>
                      <p className="ml-1">{data_person?.primer_apellido}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    {" "}
                    <div className="d-flex flex-row align-items-baseline ">
                      <p className="text-title">Correo electrónico:</p>
                      <p className="ml-1">{data_person?.correo_electronico}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <h5 className="mt-4">Tipo de solicitud</h5>
          <div className="container ">
            <div className="row">
              <div className="col-6">
                {" "}
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">Tipo solicitud: </p>
                  <p className="ml-1">
                    {requestT?.tipo_solcitud === 1
                      ? "Declaratoria"
                      : requestT?.tipo_solcitud === 2
                      ? "Revocatoria"
                      : requestT?.tipo_solcitud === 3
                      ? "Cambio de nivel"
                      : requestT?.tipo_solcitud === 4 &&
                        " Aclaración de la declaratoria"}
                  </p>
                </div>
              </div>
              <div className="col-6">
                {" "}
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">Tipo de bien: </p>
                  <p className="ml-1">
                    {requestT?.tipo_bien === 1
                      ? "Mueble"
                      : requestT?.tipo_bien === 2
                      ? "Inmueble"
                      : requestT?.tipo_bien === 3
                      ? "Sector Urbano"
                      : requestT?.tipo_bien === 4 && "Espacio Público"}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">Calidad en la que actúa:</p>
                  <p className="ml-1">
                    {requestT?.calidad_actual === 1
                      ? "Propietario"
                      : requestT?.calidad_actual === 2
                      ? "Usufructuario o Poseedor"
                      : requestT?.calidad_actual === 3
                      ? "Arrendatario"
                      : requestT?.calidad_actual === 4 &&
                        "Tercero interesado en el bien"}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">Intéres en el que actúa:</p>
                  <p className="ml-1">
                    {requestT?.interes === 1
                      ? "Interés particular"
                      : requestT?.interes === 2 && "Interés general"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h5 className="mt-4">Identificación del bien</h5>
          <div className="container ">
            <div className="row">
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">
                    Nombre con el que conoce el bien:{" "}
                  </p>
                  <p className="ml-1">{identificationWell?.nombre_bien}</p>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">CHIP:</p>
                  <p className="ml-1">{identificationWell?.chip}</p>
                </div>
              </div>
            </div>
          </div>

          <h5 className="mt-4">Descripción del bien</h5>
          <div className="container ">
            <div className="row">
              <div className="col-6">
                {" "}
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">
                    Razón principal de la solicitud:{" "}
                  </p>
                  <p className="ml-1">
                    {descriptionGood?.razon_principal_solicitud}
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">Acto administrativo:</p>
                  <p className="ml-1">
                    {descriptionGood?.acto_adminitrativo_bien}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h5 className="mt-4">Adjuntar documentos</h5>
          <div className="container ">
            <div className="row">
              <div className="col-6">
                <div className="d-flex flex-row align-items-baseline ">
                  <p className="text-title">
                    Se realizó la subida de documentos
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="text-style">
            Antes de continuar, por favor asegúrese que toda la información sea
            precisa. Al enviar el trámite, este quedará registrado y no podrá
            ser modificado posteriormente. El trámite será enviado para su
            procesamiento, y en unos días podrá consultar el progreso o
            verificar cuando esté completado.
          </p>
          {finish && (
            <div className="mt-5 d-flex justify-content-end">
              <button
                className="btn btn-round btn-high boton-inicio"
                onClick={onSubmit}
              >
                Enviar tramite
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Resumen;
