import Swal from "sweetalert2";

const constructTwoButtonsAlert = (title, text, icon, confirmButtonText, denyButtonText) => {
    return Swal.fire({
        title: title,
        text: text,
        type: icon,
        icon: icon,
        confirmButtonColor: "#3366CC",
        cancelButtonColor: "#dc3741",
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: denyButtonText,
    });
}

export default constructTwoButtonsAlert;